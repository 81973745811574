import React, { useContext, useEffect, useRef, useState } from 'react';
import { toasts } from '../../../services/toast.service';
import { useReactToPrint } from 'react-to-print';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import Button from '../../bootstrap/Button';
import Card, { CardHeader, CardActions, CardBody, CardLabel, CardSubTitle, CardTitle } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Icon from '../../icon/Icon';
import { getAllStudent, getProfileForStudent, getStudentDetailsBySemesterId } from '../../../services/student.service';
import { convertDateToEpoch, getGraduationType, getSectionDetails, pictNotLoading, profilePic } from '../../../services/common.service';
import { useNavigate, useParams } from 'react-router-dom';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Input from '../../bootstrap/forms/Input';
import Timeline, { TimelineItem } from '../../extras/Timeline';
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from '../../../services/master.service';
import { getActivityLog } from '../../../services/staff.service';

const StudentActivityReport = () => {
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
    const { userAccountId, userTypeId } = useContext(AuthContext);
    const navigate = useNavigate();
    const { navigatedStudentDetailsId } = useParams();
    const [isNavigatedStudent, setIsNavigatedStudent] = useState(false);

    useEffect(() => {
        getDepartment();
        getGraduationList();
        getSectionList();
        getAllStudentDetails();
        getSchool();
        if (navigatedStudentDetailsId != undefined) {
            setIsNavigatedStudent(true);
            getStudentProfile(navigatedStudentDetailsId);
            setStudentDetailsId(navigatedStudentDetailsId);
        }
    }, []);

    const [studentDetailsDataByDropdown, setStudentDetailsDataByDropdown] = useState([])
    const componentRef = useRef(null);
    const [studentDetailsData, setStudentDetailsData] = useState<any>([]);
    const [allStudentData, setAllStudentData] = useState([]);
    const [schoolDetailsData, setSchoolDetailsData] = useState<any>([]);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')
    const [activityData, setActivityData] = useState<any>([]);

    const [departmentId, setDepartmentId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])

    const studentActivityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: '',
            toDate: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseData([])
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setCourseData([])
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setBatchMasterData([])
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setStudentDetailsId('')
        setStudentDetailsData([])
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        setStudentDetailsData([])
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        if (sectionDetailsId?.value != undefined) {
            getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
        }
    }

    const selectStudent = (e: any) => {
        setDataSuccess(false)
        studentActivityForm.resetForm()
        let studentDetailsId = e;
        setStudentDetailsId(studentDetailsId);
    };

    function viewParticularStudent() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = studentActivityForm.values.fromDate
        let toDate = studentActivityForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getActivityLogList(9, studentDetailsId?.value,
            fromDateInMilliSec,
            toDateInMilliSec,);
    }

    function viewStudentClassWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = studentActivityForm.values.fromDate
        let toDate = studentActivityForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getActivityLogList(9, studentDetailsId?.value,
            fromDateInMilliSec,
            toDateInMilliSec,);
    }

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStudentDetailsId('')
                    setStudentDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setStudentDetailsId('')
                    setStudentDetailsData([]);
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }
    

    function getStudentProfile(studentDetailsId: any) {
        setIsLoader(true);
        getProfileForStudent(
            studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    let studentProfile = response.data.data.studentProfile[0];
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        toasts('Undefined Data', 'Error');
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                    setIsLoader(false);
                    setDataSuccess(false);
                    setIsOpenListCard(true);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                    setDataSuccess(false);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
                setDataSuccess(false);
            },
        );
    }

    function getAllStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getSchool() {
        // getSchoolDetails(
        //     (response) => {
        //         if (response.data.success) {
        //             let data = response.data.data.schoolDetails;
        //             if (data != undefined) {
        //                 setSchoolDetailsData(data);
        //             } else {
        //                 toasts('Undefined Data', 'Error');
        //             }
        //         } else if (response.data.success === false) {
        //             toasts(response.data.message, 'Error');
        //         } else {
        //             let errorCode = response.data.error[0].error_code;
        //             let errorDescription = response.data.error[0].error_description;
        //             toasts(errorDescription, 'Error');
        //         }
        //     },
        //     (error) => {
        //         toasts(error, 'Error');
        //     },
        // );
    }

    function getActivityLogList(
        userTypeId: any,
        studentOrStaffDetailsId: any,
        fromDateInMilliSec: any,
        toDateInMilliSec: any,

    ) {
        setIsLoader(true);
        // getActivityLog(
        //     userTypeId,
        //     studentOrStaffDetailsId,
        //     fromDateInMilliSec,
        //     toDateInMilliSec,
        //     (response) => {
        //         if (response.data.success) {
        //             let data = response.data.data.activityLog;
        //             if (data != undefined) {
        //                 setIsLoader(false);
        //                 setIsOpenListCard(false)
        //                 setDataSuccess(true)
        //                 setActivityData(data);
        //                 console.log(data);
        //             } else {
        //                 setIsLoader(false);
        //                 setDataSuccess(true)
        //                 toasts('Undefined Data', 'Error');
        //             }
        //         } else if (response.data.success === false) {
        //             setIsLoader(false);
        //             setIsOpenListCard(false)
        //             setDataSuccess(true)
        //             setNoDataMsg(response.data.message)
        //             setActivityData([]);
        //             // toasts(response.data.message, 'Error');
        //         } else {
        //             setIsLoader(false);
        //             setDataSuccess(true)
        //             let errorCode = response.data.error[0].error_code;
        //             let errorDescription = response.data.error[0].error_description;
        //             toasts(errorDescription, 'Error');
        //         }
        //     },
        //     (error) => {
        //         setIsLoader(false);
        //         setDataSuccess(true)
        //         toasts(error, 'Error');
        //     },
        // );
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function selectStudentCard() {
        // if (isStudentOrClassWise) {
        //     getBatchList()
        // }
        setIsStudentOrClassWise(!isStudentOrClassWise);
        setDataSuccess(false);
        setSectionDetailsId('');
        setStudentDetailsId('');
        setGraduationTypeId('');
        setDepartmentId('');
        setSemesterDetailsId('');
        studentActivityForm.resetForm()
    }

    return (

        <PageWrapper title='StudentActivityReport'>
            {isNavigatedStudent ? (
                <SubHeader className='d-print-none'>
                    <SubHeaderLeft>
                        <Button
                            color='info'
                            isLink
                            icon='ArrowBack'
                            onClick={() => navigate(`../academic/studentList`)}>
                            Back to List
                        </Button>
                        <SubheaderSeparator />
                        {studentDetailsData.map((student: any) => (
                            <div key={student.studentDetailsId}>
                                <img
                                    style={{ borderRadius: '50%' }}
                                    src={
                                        student.profilePath != null
                                            ? student.profilePath
                                            : profilePic(student.genderId)
                                    }
                                    height='40'
                                    width='40'
                                    onError={(e: any) => pictNotLoading(e, student.genderId)}
                                />
                                <span>
                                    <strong className='ms-3'>{student.studentName} </strong>
                                </span>
                                <span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
                                    {student.admissionNo}
                                </span>
                            </div>
                        ))}
                    </SubHeaderLeft>
                    <SubHeaderRight>
                        <Button
                            icon='Print'
                            isLight
                            color='primary'
                            onClick={() => {
                                handlePrint();
                            }}>
                            Print
                        </Button>
                    </SubHeaderRight>
                </SubHeader>
            ) : (
                <></>
            )}
            <Page container='lg'>
                {!isNavigatedStudent ? (
                    <>
                        <Collapse isOpen={isOpenListCard}>
                            <Card>
                                <CardHeader borderSize={1}>
                                    <CardActions>
                                        <Button
                                            icon='PersonSearch'
                                            color='info'
                                            isLight={isStudentOrClassWise ? false : true}
                                            onClick={selectStudentCard}>
                                            Student
                                        </Button>

                                        <Button
                                            icon='PeopleAlt'
                                            color='info'
                                            isLight={isStudentOrClassWise ? true : false}
                                            onClick={selectStudentCard}>
                                            Class Wise
                                        </Button>
                                    </CardActions>
                                </CardHeader>
                                {isStudentOrClassWise ? (
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-4'>
                                                <FormGroup
                                                    id='studentDetailsId'
                                                    label='Student'
                                                    isFloating>
                                                    <SearchableSelect
                                                        isFloating
                                                        ariaLabel='Student'
                                                        onChange={selectStudent}
                                                        value={studentDetailsId}
                                                        list={allStudentData.map((data: any) => ({
                                                            value: data.studentDetailsId,
                                                            label: data.studentNameAndAdmissionNo,
                                                        }))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {studentDetailsId?.value != undefined ? (
                                                <div className="col-3">
                                                    <FormGroup id="fromDate" label="From Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='From Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.fromDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.fromDate}
                                                            invalidFeedback={studentActivityForm.errors.fromDate}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}
                                            {studentActivityForm.values.fromDate != '' ? (
                                                <div className="col-3">
                                                    <FormGroup id="toDate" label="To Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='To Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.toDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.toDate}
                                                            invalidFeedback={studentActivityForm.errors.toDate}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}
                                            {studentActivityForm.values.toDate != '' ? (
                                                <div className='col-2 mt-1'>
                                                    <Button
                                                        icon='ArrowDownward'
                                                        color='primary'
                                                        onClick={viewParticularStudent} >
                                                        View
                                                    </Button>
                                                </div>
                                            ) : null}
                                        </div>
                                    </CardBody>
                                ) : (
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <FormGroup id='departmentId' label='Department' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                        list={departmentData.map((data: any) => (
                                                            { value: data.departmentId, label: data.departmentName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {departmentId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                        value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                            { value: data.graduationTypeId, label: data.graduationType }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {graduationTypeId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='courseId' label='Course' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId}
                                                        list={courseData.map((data: any) => (
                                                            { value: data.courseId, label: data.courseName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {courseId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                        list={batchMasterData.map((data: any) => (
                                                            { value: data.batchMasterId, label: data.batchName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {batchMasterId?.value != undefined ? <div className='col-3 mt-3'>
                                                <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                        list={semesterDetailsData.map((data: any) => (
                                                            { value: data.semesterDetailsId, label: data.semesterNumber }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {semesterDetailsId?.value != undefined ? <div className='col-3 mt-3'>
                                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                        list={sectionDetailsData.map((data: any) => (
                                                            { value: data.sectionDetailsId, label: data.sectionName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {sectionDetailsId?.value != undefined ? <div className="col-3 mt-3">
                                                <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Student' onChange={selectStudent} value={studentDetailsId}
                                                        list={studentDetailsData.map((data: any) => (
                                                            { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {studentDetailsId?.value != undefined ? (
                                                <div className="col-3 mt-3">
                                                    <FormGroup id="fromDate" label="From Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='From Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.fromDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.fromDate}
                                                            invalidFeedback={studentActivityForm.errors.fromDate}
                                                        />
                                                    </FormGroup>
                                                </div>
                                            ) : null}
                                            {studentActivityForm.values.fromDate != '' ? (
                                                <div className="col-3 mt-3 ">
                                                    <FormGroup id="toDate" label="To Date" isFloating>
                                                        <Input
                                                            type='date'
                                                            ariaLabel='To Date'
                                                            onChange={studentActivityForm.handleChange}
                                                            onBlur={studentActivityForm.handleBlur}
                                                            value={studentActivityForm.values.toDate}
                                                            isValid={studentActivityForm.isValid}
                                                            isTouched={studentActivityForm.touched.toDate}
                                                            invalidFeedback={studentActivityForm.errors.toDate}
                                                        />
                                                    </FormGroup>
                                                </div>) : null
                                            }
                                            {studentActivityForm.values.toDate != '' ?
                                                <div className='col-2 mt-4'>
                                                    <Button
                                                        icon='ArrowDownward'
                                                        color='primary'
                                                        onClick={viewStudentClassWiseList}>
                                                        View
                                                    </Button>
                                                </div> : null
                                            }
                                        </div>
                                    </CardBody>
                                )}
                            </Card>
                        </Collapse>
                        <OpenCardComponent
                            isOpenListCard={isOpenListCard}
                            setIsOpenListCard={setIsOpenListCard}
                            isLoader={isLoader}
                        />
                    </>
                ) : null}
                {dataSuccess ? <>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel icon='NotificationsActive' iconColor='warning'>
                                <CardTitle tag='div' className='h5'>
                                    Recent Activities
                                </CardTitle>
                                <CardSubTitle tag='div' className='h6'>
                                    last 1 week
                                </CardSubTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody isScrollable>
                            {activityData != '' ?
                                <Timeline>
                                    {activityData.map((item: any) => (
                                        <>
                                            {item.activities.map((i: any) => (
                                                <TimelineItem
                                                    key={i.activityLogsId}
                                                    label={i.duration}
                                                    color='secondary'>
                                                    {i.activity}
                                                </TimelineItem>
                                            ))}

                                        </>
                                    ))}
                                </Timeline> :
                                <div className='row'>
                                    <div className="col-12" style={{ height: '5vh' }}></div>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <Icon icon='DoNotDisturb' size={'10x'} />
                                    </div>
                                    <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                        {noDataMsg}
                                    </div>
                                </div>
                            }
                        </CardBody>
                    </Card> </> : null
                }

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper>
    );
};

export default StudentActivityReport;
