import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../bootstrap/OffCanvas";
import AlertService from "../../../services/AlertService";
import DeleteComponents from "../../../common/components/DeleteComponents";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getAcademicPeriod } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { getColumnsForDataTable, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { addExamDetails, deleteExamDetails, getExamDetails, getExamType, updateExamDetails } from "../../../services/exam.service";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import Collapse from "../../bootstrap/Collapse";

function ExamDetails() {

    useEffect(() => {
        getAcademicPeriodList()
        getExamTypeList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [examDetailsData, setExamDetailsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [examTypeId, setExamTypeId] = useState<any>('');
    const [examTypeData, setExamTypeData] = useState<any>([]);

    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(examDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addExamDetailsOffCanvas, setAddExamDetailsOffCanvas] = useState(false)
    const [editExamDetailsOffCanvas, setEditExamDetailsOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const examDetailsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            examTypeId: '',
            fromDate: '',
            toDate: ''
        },
        validate: (values) => {
            const errors: {
                examTypeId?: String;
                fromDate?: String;
                toDate?: String;
            } = {};
            if (!examTypeId) {
                errors.examTypeId = 'Required';
            }
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { addExamDetailsSubmit() },
    });

    const editExamDetailsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            examDetailsId: '',
            examTypeId: '',
            fromDate: '',
            toDate: ''
        },
        validate: (values) => {
            const errors: {
                fromDate?: String;
                toDate?: String;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        validateOnChange: true,
        onSubmit: () => { updateExamDetailsSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectAcademicPeriod = (e: any) => {
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
    }

    const selectExamType = (e: any) => {
        let examTypeId = e
        setExamTypeId(examTypeId)
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setAcademicPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExamTypeList() {
        getExamType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examType;
                    if (data != undefined) {
                        setExamTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setExamTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getExamDetailsList(academicPeriodId: string) {
        setIsLoader(true);
        getExamDetails(
            academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examDetails;
                    if (data != undefined) {
                        setExamDetailsData(data);
                        getColumnsForTable('getExamDetails', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    getColumnsForTable('getExamDetails', 'get');
                    setExamDetailsData([]);
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedExam = i
            setEditExamDetailsOffCanvas(true)
            setExamTypeId({ value: selectedExam.examTypeId, label: selectedExam.examTypeName })
            editExamDetailsForm.setValues({
                examDetailsId: selectedExam.examDetailsId,
                examTypeId: examTypeId?.value,
                fromDate: selectedExam.fromDate,
                toDate: selectedExam.toDate
            })
        }
    }

    function viewExamList() {
        setIsLoader(true);
        setDataSuccess(false);
        setExamDetailsData([]);
        getExamDetailsList(academicPeriodId?.value)
    }

    function setExamDetails() {

        return ({
            examTypeId: examTypeId?.value,
            fromDate: examDetailsForm.values.fromDate,
            toDate: examDetailsForm.values.toDate,
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId
        })
    }

    function setEditExamDetails() {
        return ({
            examDetailsId: editExamDetailsForm.values.examDetailsId,
            fromDate: editExamDetailsForm.values.fromDate,
            toDate: editExamDetailsForm.values.toDate,
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId
        })
    }

    function setDeleteExamDetails() {
        return ({
            examDetailsId: editExamDetailsForm.values.examDetailsId,
            licenseKey: getLicenseKey,
            staffDetailsId: userAccountId
        })
    }

    function addExamDetailsSubmit() {
        showLoader(true)
        if (examDetailsForm.isValid) {
            let examDetailsPostData = setExamDetails()
            addExamDetails(examDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        viewExamList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (examDetailsForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateExamDetailsSubmit() {
        showLoader(true)
        if (editExamDetailsForm.isValid) {
            let examDetailsPostData = setEditExamDetails()
            updateExamDetails(examDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        viewExamList();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editExamDetailsForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteExamDetailsSubmit() {
        showLoader(true)
        let examDetailsPostData = setDeleteExamDetails()
        deleteExamDetails(examDetailsPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    closeAndReset();
                    viewExamList();
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        const allData = allColumnsData.map((item: any) => item.isDisplay);
                        const allColumns = allData.filter((item: any) => item == false);

                        if (type == 'get') {
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: allColumns == '' ? 0 : 1
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getExamDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddExamDetailsOffCanvas(false);
        setEditExamDetailsOffCanvas(false);
        setIsDeleteOpen(false);
        examDetailsForm.resetForm();
        editExamDetailsForm.resetForm();
        setExamTypeId('');
    }

    function addNewReset() {
        examDetailsForm.resetForm();
        editExamDetailsForm.resetForm();
        setExamTypeId('');
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Exam List`}>
                <Page container='fluid'>

                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Exam List
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row g-4">
                                    <div className='col-md-3'>
                                        <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Academic Period"
                                                value={academicPeriodId}
                                                onChange={selectAcademicPeriod}
                                                list={academicPeriodData.map((data: any) => (
                                                    { value: data.academicPeriodId, label: data.period }
                                                ))} required />
                                        </FormGroup>
                                    </div>

                                    {academicPeriodId ?
                                        <div className='col-md-3'>
                                            <Button className="mt-2" icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                        </div>
                                        : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />
                    {dataSuccess ? (
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Exam List</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getExamDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("ExamList", examDetailsData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(examDetailsData, columnVisibilityData, "ExamList")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("ExamList", examDetailsData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button color='primary' icon='Add' isLight onClick={() => { addNewReset(); setAddExamDetailsOffCanvas(true); }}>
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.examDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.casteDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className="d-print-none"
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                    ) : null}
                </Page>

                <OffCanvas
                    setOpen={setAddExamDetailsOffCanvas}
                    isOpen={addExamDetailsOffCanvas}
                    titleId='addExamDetailsOffCanvas'
                    isBodyScroll
                    placement='end' tag="form" noValidate onSubmit={examDetailsForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setAddExamDetailsOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="addExamDetailsOffCanvas">Add Exam Details</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className=" col-12">
                                <FormGroup id='examTypeId' label='Exam Type'>
                                    <SearchableSelect ariaLabel='Exam Type' placeholder="Exam Type"
                                        value={examTypeId}
                                        onChange={selectExamType}
                                        onBlur={examDetailsForm.handleBlur}
                                        isValid={examDetailsForm.isValid}
                                        isTouched={examDetailsForm.touched.examTypeId}
                                        invalidFeedback={examDetailsForm.errors.examTypeId}
                                        list={examTypeData.map((data: any) => (
                                            { value: data.examTypeId, label: data.examTypeName }
                                        ))} required />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='fromDate' label='From Date'>
                                    <Input type="date" onChange={examDetailsForm.handleChange} value={examDetailsForm.values.fromDate}
                                        isValid={examDetailsForm.isValid}
                                        onBlur={examDetailsForm.handleBlur}
                                        isTouched={examDetailsForm.touched.fromDate}
                                        invalidFeedback={examDetailsForm.errors.fromDate}
                                        placeholder="Enter From Date" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='toDate' label='To Date'>
                                    <Input type="date" onChange={examDetailsForm.handleChange} value={examDetailsForm.values.toDate}
                                        isValid={examDetailsForm.isValid}
                                        onBlur={examDetailsForm.handleBlur}
                                        isTouched={examDetailsForm.touched.toDate}
                                        invalidFeedback={examDetailsForm.errors.toDate}
                                        placeholder="Enter To Date" />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3'>
                            <Button
                                color='info' icon="Save"
                                className='w-100' type="submit" isDisable={!examDetailsForm.isValid && !!examDetailsForm.submitCount} >
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas
                    setOpen={setEditExamDetailsOffCanvas}
                    isOpen={editExamDetailsOffCanvas}
                    titleId='editExamDetailsOffCanvas'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={editExamDetailsForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setEditExamDetailsOffCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="editExamDetailsOffCanvas">Update Exam Details</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className=" col-12">
                                <FormGroup id='examTypeId' label='Exam Type' >
                                    <SearchableSelect ariaLabel='Exam Type' placeholder="Exam Type"
                                        value={examTypeId}
                                        onChange={selectExamType}
                                        onBlur={editExamDetailsForm.handleBlur}
                                        isValid={editExamDetailsForm.isValid}
                                        isTouched={editExamDetailsForm.touched.examTypeId}
                                        invalidFeedback={editExamDetailsForm.errors.examTypeId}
                                        list={examTypeData.map((data: any) => (
                                            { value: data.examTypeId, label: data.examTypeName }
                                        ))} disabled />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='fromDate' label='From Date'>
                                    <Input type="date" onChange={editExamDetailsForm.handleChange} value={editExamDetailsForm.values.fromDate}
                                        isValid={editExamDetailsForm.isValid}
                                        onBlur={editExamDetailsForm.handleBlur}
                                        isTouched={editExamDetailsForm.touched.fromDate}
                                        invalidFeedback={editExamDetailsForm.errors.fromDate}
                                        placeholder="Enter From Date" />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='toDate' label='To Date'>
                                    <Input type="date" onChange={editExamDetailsForm.handleChange} value={editExamDetailsForm.values.toDate}
                                        isValid={editExamDetailsForm.isValid}
                                        onBlur={editExamDetailsForm.handleBlur}
                                        isTouched={editExamDetailsForm.touched.toDate}
                                        invalidFeedback={editExamDetailsForm.errors.toDate}
                                        placeholder="Enter To Date" />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>

                    <div className='row'>
                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteExamDetailsSubmit} />
                        </div>
                        <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                            <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                            <Button color='info' icon="Save" type="submit" className='w-100' isDisable={!editExamDetailsForm.isValid && !!editExamDetailsForm.submitCount}> Update</Button>
                        </div>
                    </div>
                </OffCanvas>


                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default ExamDetails;