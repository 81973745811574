import React, { useContext, useEffect, useState } from 'react'
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import Page from '../../../layout/Page/Page'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Input from '../../bootstrap/forms/Input'
import Button from '../../bootstrap/Button'
import { useFormik } from 'formik'
import { toasts } from '../../../services/toast.service'
import useDarkMode from '../../../hooks/useDarkMode'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import { showLoader } from '../../../services/loader.services'
import { getLicenseKey } from '../../../services/application.settings'
import AuthContext from '../../../contexts/authContext'
import AlertService from '../../../services/AlertService'
import SearchableSelect from '../../../common/components/SearchableSelect'
import { getAcademicPeriod, getBatchByCourseId, getBatchMasterDetails, getCourse, getCourseByBatchMasterId, getDepartmentList, getSemester } from '../../../services/master.service'
import { addExamTimeTable, getExamDetails } from '../../../services/exam.service'
import Select from '../../bootstrap/forms/Select'
import { getSubjectPaperDetails, getSubjectPaperListByCourseId } from '../../../services/subject.service'
import { getGraduationType } from '../../../services/common.service'

const ExamTimeTable = () => {

    useEffect(() => {
        getAcademicPeriodList();
        selectSubjectCount(5);
        getGraduationList();
        getDepartment();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [subjectPaperDetailsData, setSubjectPaperDetailsData] = useState<any>([]);
    const [examDetailsId, setExamDetailsId] = useState<any>('')
    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [subjectCount, setSubjectCount] = useState<any>(5)
    const [examTypeData, setExamTypeData] = useState([])
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [examTimeTable, setExamTimeTable] = useState<any>([])

    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])


    const [noDataMsg, setNoDataMsg] = useState('')

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    // Table States
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [addTimeTableData, setAddTimeTableData] = useState<any>([]);

    const examTimeTableForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            session: '',
            startTime: '',
            endTime: '',
            academicPeriodId: '',
            examDetailsId: '',
            batchMasterId: '',
            courseId: '',
            semesterDetailsId: '',
            subjectCount: 5,
            departmentId: '',
            graduationTypeId: ''
        },
        validate: (values) => {
            const errors: {
                session?: string;
                startTime?: string;
                endTime?: string;
                academicPeriodId?: string;
                examDetailsId?: string;
                batchMasterId?: string;
                courseId?: string;
                semesterDetailsId?: string;
                subjectCount?: string;
                departmentId?: string;
                graduationTypeId?: string;
            } = {};
            if (!values.session) {
                errors.session = 'Required';
            }
            if (!values.startTime) {
                errors.startTime = 'Required';
            }
            if (!values.endTime) {
                errors.endTime = 'Required';
            }
            if (!subjectCount) {
                errors.subjectCount = 'Required';
            }
            if (!academicPeriodId) {
                errors.academicPeriodId = 'Required';
            }
            if (!examDetailsId) {
                errors.examDetailsId = 'Required';
            }
            if (!batchMasterId) {
                errors.batchMasterId = 'Required';
            }
            if (!courseId) {
                errors.courseId = 'Required';
            }
            if (!semesterDetailsId) {
                errors.semesterDetailsId = 'Required';
            }
            if (!departmentId) {
                errors.departmentId = 'Required';
            }
            if (!graduationTypeId) {
                errors.graduationTypeId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addExamTimeTableSubmit() },
    });

    const selectAcademicPeriod = (e: any) => {
        setExamDetailsId('')
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
        if (academicPeriodId?.value != undefined) {
            getExamDetailsList(academicPeriodId?.value);
        }
    }

    const selectExamType = (e: any) => {
        let examDetailsId = e
        setExamDetailsId(examDetailsId)
    }

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setSubjectPaperDetailsData([]);
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setSubjectPaperDetailsData([]);
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setSubjectPaperDetailsData([]);
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setSubjectPaperDetailsData([]);
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setSubjectPaperDetailsData([]);
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
        if (semesterDetailsId?.value != undefined) {
            getSubjectPaperList(courseId?.value, batchMasterId?.value, semesterDetailsId?.value)
        }
    }

    const selectSubjectCount = (subjectCount: any) => {

        setSubjectCount(subjectCount)

        let newData: any = []
        for (let i = 0; i < subjectCount; i++) {
            newData.push({ dateOfExam: '', subjectPaperDetailsId: '', subjectPaperDetailsIdArray: '', displayOrder: i })
        }
        setAddTimeTableData(newData)
        setExamTimeTable([])
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setAcademicPeriodData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExamDetailsList(academicPeriodId: any) {
        getExamDetails(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examDetails;
                    if (data != undefined) {
                        setExamTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setExamTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }


    function getSubjectPaperList(courseId: string, batchMasterId: string, semesterDetailsId: string) {
        getSubjectPaperListByCourseId(courseId, batchMasterId, semesterDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.subjectPaperListByCourseId;
                    if (data != undefined) {
                        setSubjectPaperDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setSubjectPaperDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [isDuplicateEntry, setIsDuplicateEntry] = useState<any>([])

    const updateExamTimeTable = (e: any, dateOfExam: any, displayOrder: any) => {

        let subjectPaperDetailsId = e?.value;

        let existingData = examTimeTable.filter((data: any) => data.subjectPaperDetailsId == subjectPaperDetailsId && data.displayOrder != displayOrder)
        setIsDuplicateEntry(existingData)

        const newData: any = [];

        addTimeTableData.forEach((item: any, index: any) => {
            if (item['displayOrder'] == displayOrder) {
                item['dateOfExam'] = dateOfExam
                item['subjectPaperDetailsId'] = subjectPaperDetailsId
                item['subjectPaperDetailsIdArray'] = e
                item['displayOrder'] = displayOrder
                item['existingRoll'] = existingData != '' ? true : false
            }
            newData.push(item)
        });
        setAddTimeTableData(newData)

        if (examTimeTable.length == 0 && subjectPaperDetailsId > 0 && dateOfExam != '') {
            const obj = {
                displayOrder: displayOrder,
                subjectPaperDetailsId: subjectPaperDetailsId,
                dateOfExam: dateOfExam,
                subjectPaperDetailsIdArray: e,
            };
            examTimeTable.push(obj);
        } else if (subjectPaperDetailsId > 0 && dateOfExam != '') {
            let selectedItem: any;
            examTimeTable.forEach((item: any, index: any) => {
                if (item['displayOrder'] == displayOrder) {
                    selectedItem = item
                    selectedItem['subjectPaperDetailsId'] = subjectPaperDetailsId,
                        selectedItem['dateOfExam'] = dateOfExam
                    selectedItem['subjectPaperDetailsIdArray'] = e
                }
            });
            if (selectedItem != undefined) {
                selectedItem['displayOrder'] = displayOrder,
                    selectedItem['subjectPaperDetailsId'] = subjectPaperDetailsId,
                    selectedItem['dateOfExam'] = dateOfExam
                selectedItem['subjectPaperDetailsIdArray'] = e
            } else {
                var obj
                obj = {
                    displayOrder: displayOrder,
                    subjectPaperDetailsId: subjectPaperDetailsId,
                    dateOfExam: dateOfExam,
                    subjectPaperDetailsIdArray: e,
                };
                examTimeTable.push(obj);
            }
        }
    }

    function setExamTimeTableDetails() {
        return ({
            academicPeriodId: academicPeriodId?.value,
            examDetailsId: examDetailsId?.value,
            batchMasterId: batchMasterId?.value,
            courseId: courseId?.value,
            semesterDetailsId: semesterDetailsId?.value,
            session: examTimeTableForm.values.session,
            startTime: examTimeTableForm.values.startTime,
            endTime: examTimeTableForm.values.endTime,
            examTimeTable: examTimeTable,
            staffDetailsId: userAccountId,
            licenseKey: getLicenseKey,
        })
    }

    function addExamTimeTableSubmit() {
        showLoader(true);
        if (subjectCount == examTimeTable.length) {
            let examTimeTablePostData = setExamTimeTableDetails();
            addExamTimeTable(examTimeTablePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../academic/examSchedule`)
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null);
                }
            )
        } else if (subjectCount != examTimeTable.length) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null);
        }
    }

    function closeAndReset() {
        examTimeTableForm.resetForm()
        setAcademicPeriodId('')
        setBatchMasterId('')
        setCourseId('')
        setSemesterDetailsId('')
        setExamDetailsId('')
        setExamTimeTable([])
        selectSubjectCount(5)
    }

    return (
        <PageWrapper title='Exam Schedule'>
            <Page container='fluid'>
                <form noValidate onSubmit={examTimeTableForm.handleSubmit}>
                    <div className='row px-4'>
                        <div className='col-xl-4 '>
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='list' iconColor='primary'>
                                        <CardTitle>
                                            Exam Details
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-12'>
                                            <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Select Academic Period"
                                                    onChange={selectAcademicPeriod}
                                                    value={academicPeriodId}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.academicPeriodId}
                                                    invalidFeedback={examTimeTableForm.errors.academicPeriodId}
                                                    list={academicPeriodData.map((data: any) => ({ value: data.academicPeriodId, label: data.period }))}
                                                    required
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Select Exam Type"
                                                    onChange={selectExamType}
                                                    value={examDetailsId}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.examDetailsId}
                                                    invalidFeedback={examTimeTableForm.errors.examDetailsId}
                                                    list={examTypeData.map((data: any) => ({ value: data.examDetailsId, label: data.examTypeName }))}
                                                    required
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='departmentId' label='Department' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                    list={departmentData.map((data: any) => (
                                                        { value: data.departmentId, label: data.departmentName }
                                                    ))}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.departmentId}
                                                    invalidFeedback={examTimeTableForm.errors.departmentId}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                    value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                        { value: data.graduationTypeId, label: data.graduationType }
                                                    ))}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.departmentId}
                                                    invalidFeedback={examTimeTableForm.errors.departmentId}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='courseId' label='Course' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Course'
                                                    onChange={selectCourse}
                                                    value={courseId}
                                                    list={courseData.map((data: any) => (
                                                        { value: data.courseId, label: data.courseName }
                                                    ))}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.courseId}
                                                    invalidFeedback={examTimeTableForm.errors.courseId}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Batch Type' placeholder="Select Batch Type" onChange={(e: any) => selectBatch(e)} value={batchMasterId} isValid={examTimeTableForm.isValid} isTouched={examTimeTableForm.touched.batchMasterId} onBlur={examTimeTableForm.handleBlur} invalidFeedback={examTimeTableForm.errors.batchMasterId} list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} />
                                            </FormGroup>
                                        </div>

                                        <div className='col-12'>
                                            <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                <SearchableSelect isFloating placeholder='Select Semester' ariaLabel='Semester' onChange={selectSemester}
                                                    value={semesterDetailsId}
                                                    list={semesterDetailsData.map((data: any) => (
                                                        { value: data.semesterDetailsId, label: data.semesterNumber }
                                                    ))}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.semesterDetailsId}
                                                    invalidFeedback={examTimeTableForm.errors.semesterDetailsId}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='session' label='Session' isFloating>
                                                <Select ariaLabel="" placeholder="Select Session"
                                                    onChange={examTimeTableForm.handleChange}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.session}
                                                    invalidFeedback={examTimeTableForm.errors.session}
                                                    value={examTimeTableForm.values.session}>
                                                    <option value={1}>Morning</option>
                                                    <option value={2}>Afternoon</option>
                                                </Select>
                                            </FormGroup>
                                        </div>
                                        <div className='col-6'>
                                            <FormGroup id='startTime' label='Start Time' isFloating>
                                                <Input
                                                    onChange={examTimeTableForm.handleChange}
                                                    value={examTimeTableForm.values.startTime}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.startTime}
                                                    invalidFeedback={examTimeTableForm.errors.startTime}
                                                    type='time'
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-6'>
                                            <FormGroup id='endTime' label='End Time' isFloating>
                                                <Input
                                                    onChange={examTimeTableForm.handleChange}
                                                    value={examTimeTableForm.values.endTime}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isValid={examTimeTableForm.isValid}
                                                    isTouched={examTimeTableForm.touched.endTime}
                                                    invalidFeedback={examTimeTableForm.errors.endTime}
                                                    type='time'
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-12'>
                                            <FormGroup id='subjectCount' label='Subject Count' isFloating>
                                                <Input
                                                    onChange={(e: any) => selectSubjectCount(e.target.value)}
                                                    value={subjectCount}
                                                    isValid={examTimeTableForm.isValid}
                                                    onBlur={examTimeTableForm.handleBlur}
                                                    isTouched={examTimeTableForm.touched.subjectCount}
                                                    invalidFeedback={examTimeTableForm.errors.subjectCount}
                                                    placeholder="Enter Section"
                                                    required
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-xl-8'>
                            <Card stretch>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='CalendarToday' iconColor='primary'>
                                        <CardTitle>
                                            Subjects & Date
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-lg-1"></div>
                                        <div className="col-lg-10">
                                            <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                                                <thead>
                                                    <tr className='text-center table-primary fs-6'>
                                                        <th>Subject</th>
                                                        <th>Exam Date</th>
                                                    </tr>
                                                </thead>
                                                {addTimeTableData.map((i: any) => (
                                                    <tbody key={i.displayOrder}>
                                                        <tr>
                                                            <td width={'50%'}>
                                                                <FormGroup id="subjectPaperDetailsId">
                                                                    <SearchableSelect
                                                                        ariaLabel=''
                                                                        placeholder='Select'
                                                                        onChange={(e: any) => updateExamTimeTable(e, i.dateOfExam, i.displayOrder)}
                                                                        value={i.subjectPaperDetailsIdArray}
                                                                        list={subjectPaperDetailsData.map((data: any) => (
                                                                            { value: data.subjectPaperDetailsId, label: data.paperName }
                                                                        ))}
                                                                    />
                                                                </FormGroup>
                                                            </td>
                                                            <td width={'50%'}>
                                                                <FormGroup id="dateOfExam">
                                                                    <Input type="date" placeholder="Select Exam Date"
                                                                        onChange={(e: any) => updateExamTimeTable(i.subjectPaperDetailsIdArray, e.target.value, i.displayOrder)}
                                                                        value={i.dateOfExam}
                                                                    />
                                                                </FormGroup>
                                                            </td>
                                                        </tr>
                                                        {i.existingRoll ?
                                                            <tr className='text-center'>
                                                                <td className='text-danger' colSpan={2}>Duplicate Entry</td>
                                                            </tr> : null
                                                        }

                                                    </tbody>
                                                ))}
                                                {subjectCount == 0 ?
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={2} className='text-center text-danger'>No Data Found</td>
                                                        </tr>
                                                    </tbody> : null
                                                }
                                            </table>
                                        </div>
                                    </div>
                                </CardBody>
                                <CardFooter>
                                    <CardFooterRight>
                                        <div className="col-lg-12 d-flex justify-content-center">
                                            <FormGroup>
                                                <Button icon="Save" color="info" type="submit" isDisable={(!examTimeTableForm.isValid && !!examTimeTableForm.submitCount) || examTimeTable.length == 0 || isDuplicateEntry != ""}>
                                                    Save
                                                </Button>
                                            </FormGroup>
                                        </div>
                                    </CardFooterRight>
                                </CardFooter>
                            </Card>
                        </div>
                    </div>
                </form>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

        </PageWrapper>
    );
}

export default ExamTimeTable