import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getExamMonth = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamMonth/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamMonth - " + error.message)
            console.log('error caught in service : getExamMonth')
        },
    );

export const addExamMonth = (examMonthPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addExamMonth`, examMonthPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addExamMonth')
        }
    );

export const updateExamMonth = (examMonthPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateExamMonth`, examMonthPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateExamMonth')
        }
    );

export const deleteExamMonth = (examMonthPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteExamMonth`, examMonthPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteExamMonth')
        }
    );

export const getExamType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamType - " + error.message)
            console.log('error caught in service : getExamType')
        },
    );

export const getExamDetails = (academicPeriodId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamDetails/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamDetails - " + error.message)
            console.log('error caught in service : getExamDetails')
        },
    );

export const addExamTimeTable = (examTimeTablePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addExamTimeTable`, examTimeTablePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addExamTimeTable')
        }
    );

export const addExamDetails = (examDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addExamDetails`, examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addExamDetails');
        },
    );

export const updateExamDetails = (examDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateExamDetails`, examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : updateExamDetails');
        },
    );

export const deleteExamDetails = (examDetailsPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteExamDetails`, examDetailsPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : deleteExamDetails');
        },
    );

export const getExamTimeTable = (academicPeriodId: any, examTypeId: any, courseId: any, batchMasterId: any, semesterDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamTimeTable/${getLicenseKey}/${academicPeriodId}/${examTypeId}/${courseId}/${batchMasterId}/${semesterDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamTimeTable - " + error.message)
            console.log('error caught in service : getExamTimeTable')
        },
    );

export const getExamHall = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamHall/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamHall - " + error.message)
            console.log('error caught in service : getExamHall')
        },
    );

export const getExamHallAllocation = (examDetailsId: any, examHallDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getExamHallAllocation/${getLicenseKey}/${examDetailsId}/${examHallDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getExamHallAllocation - " + error.message)
            console.log('error caught in service : getExamHallAllocation')
        },
    );

export const addExamHallAllocationDetails = (examHallAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `addExamHallAllocation`, examHallAllocationPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : addExamHallAllocation');
        },
    );

export const updateExamHallAllocation = (examHallAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `updateExamHallAllocation`, examHallAllocationPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : updateExamHallAllocation');
        },
    );

export const deleteExamHallAllocation = (examHallAllocationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.post(getAPIURL() + `deleteExamHallAllocation`, examHallAllocationPostData,
        (response) => {
            success(response);
        },
        (error) => {
            failure(error.message);
            console.log('error caught in service : deleteExamHallAllocation');
        },
    );

export const addExamHallName = (addPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addExamHallName`, addPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addExamHallName')
        }
    );
export const updateExamHallName = (updatePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateExamHallName`, updatePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateExamHallName')
        }
    );
export const deleteExamHallName = (deletePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteExamHallName`, deletePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteExamHallName')
        }
    );