import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import { toasts } from "../../../services/toast.service";
import Button from "../../bootstrap/Button";
import Page from "../../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getAcademicPeriod, getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import SearchableSelect from "../../../common/components/SearchableSelect";
import { getExamDetails, getExamTimeTable } from "../../../services/exam.service";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import Collapse from "../../bootstrap/Collapse";
import classNames from "classnames";
import { getProfileForStudent } from "../../../services/student.service";
import Icon from "../../icon/Icon";
import { getGraduationType } from "../../../services/common.service";

function ExamSchedule() {

    useEffect(() => {
        getAcademicPeriodList()
        getGraduationList();
        getDepartment();
        if (userTypeId == 9) {
            getStudentProfile(userAccountId)
        }
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [examTimeTableData, setExamTimeTableData] = useState<any>([]);
    const componentRef = useRef(null);

    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [examDetailsId, setExamDetailsId] = useState<any>('');
    const [examTypeData, setExamTypeData] = useState<any>([]);

    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)

    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [departmentData, setDepartmentData] = useState<any>([])

    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentAcademic, setStudentAcademic] = useState<any>('')
    const [studentStandard, setStudentStandard] = useState<any>('')

    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const selectAcademicPeriod = (e: any) => {
        setExamDetailsId('')
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setDataSuccess(false);
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
        if (academicPeriodId?.value != undefined && userTypeId != 9) {
            getExam(academicPeriodId?.value)
        }
    }

    const selectExamType = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setDataSuccess(false);
        let examDetailsId = e
        setExamDetailsId(examDetailsId)
    }

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setDataSuccess(false);
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setDataSuccess(false);
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setDataSuccess(false);
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setDataSuccess(false);
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setDataSuccess(false);
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setAcademicPeriodData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExam(academicPeriodId: any) {
        getExamDetails(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examDetails;
                    if (data != undefined) {
                        setExamTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setExamTypeData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentProfile(studentDetailsId: any) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                        setStudentAcademic(data[0].academicPeriodId)
                        setStudentStandard(data[0].standardDetailsId)
                        getExam(data[0].academicPeriodId)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    const [exam, setExam] = useState<any>('')

    function getExamTimeTableList(academicPeriodId: any, examDetailsId: any, courseId: any, batchMasterId: any, semesterDetailsId: any,) {
        setIsLoader(true);
        getExamTimeTable(
            academicPeriodId, examDetailsId, courseId, batchMasterId, semesterDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examTimeTable;
                    if (data != undefined) {
                        setIsLoader(false);
                        setExamTimeTableData(data);
                        setExam(data[0].examTypeName)
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setExamTimeTableData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function viewExamList() {
        setExam('');
        setIsLoader(true);
        setDataSuccess(false);
        setExamTimeTableData([]);
        if (userTypeId == 9) {
            //getExamTimeTableList(studentAcademic, examDetailsId?.value, studentStandard)
            getExamTimeTableList(academicPeriodId?.value, examDetailsId?.value, batchMasterId?.value, courseId?.value, semesterDetailsId?.value)
        }
        else {
            getExamTimeTableList(academicPeriodId?.value, examDetailsId?.value, courseId?.value, batchMasterId?.value, semesterDetailsId?.value)
        }
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Exam List`}>
                <Page container='fluid'>

                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Exam List
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                {userTypeId == 9 ?
                                    <div className="row">
                                        <div className=" col-md-3">
                                            <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Exam Type"
                                                    value={examDetailsId}
                                                    onChange={selectExamType}
                                                    list={examTypeData.map((data: any) => (
                                                        { value: data.examDetailsId, label: data.examTypeName }
                                                    ))} required />
                                            </FormGroup>
                                        </div>

                                        {examDetailsId ?
                                            <div className='col-md-3 align-self-center'>
                                                <Button icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                            </div>
                                            : null}
                                    </div>
                                    :
                                    <div className="row g-4">
                                        <div className='col-md-3'>
                                            <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Academic Period"
                                                    value={academicPeriodId}
                                                    onChange={selectAcademicPeriod}
                                                    list={academicPeriodData.map((data: any) => (
                                                        { value: data.academicPeriodId, label: data.period }
                                                    ))} required />
                                            </FormGroup>
                                        </div>
                                        {academicPeriodId ?
                                            <div className=" col-md-3">
                                                <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Exam Type"
                                                        value={examDetailsId}
                                                        onChange={selectExamType}
                                                        list={examTypeData.map((data: any) => (
                                                            { value: data.examDetailsId, label: data.examTypeName }
                                                        ))} required />
                                                </FormGroup>
                                            </div> : null}

                                        {examDetailsId?.value != undefined ?
                                            <div className='col-3'>
                                                <FormGroup id='departmentId' label='Department' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                        list={departmentData.map((data: any) => (
                                                            { value: data.departmentId, label: data.departmentName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}
                                        {departmentId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                    value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                        { value: data.graduationTypeId, label: data.graduationType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {graduationTypeId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='courseId' label='Course' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId}
                                                    list={courseData.map((data: any) => (
                                                        { value: data.courseId, label: data.courseName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {courseId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                    list={batchMasterData.map((data: any) => (
                                                        { value: data.batchMasterId, label: data.batchName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {batchMasterId?.value != undefined ? <div className='col-3 mt-3'>
                                            <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                    list={semesterDetailsData.map((data: any) => (
                                                        { value: data.semesterDetailsId, label: data.semesterNumber }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {semesterDetailsId ?
                                            <div className='col-md-3'>
                                                <Button icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                            </div>
                                            : null}
                                    </div>
                                }
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ? (
                        <>
                            {examTimeTableData != '' ? (
                                <>
                                    <table className="table table-modern table-hover text-center text-nowrap text-md-nowrap mg-b-0 mt-2">
                                        <thead>
                                            <tr style={{ textAlign: 'center' }} className='table-primary'>
                                                <td colSpan={13}
                                                    style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                                    <h5> {exam} Exam Time Table For {courseId?.label} - {batchMasterId?.label}</h5>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>

                                    <div className='col-12 mt-3' >
                                        <div className='row'>
                                            {examTimeTableData.map((exam: any) => (
                                                <div className='col-4' key={exam.examTimeTableId} >
                                                    <Card stretch shadow={'md'} className="border border-light">
                                                        <CardBody>
                                                            <div className='row g-3 align-items-center'>
                                                                <div className='col d-flex align-items-center'>
                                                                    <div className='flex-shrink-0'>
                                                                        <div
                                                                            className='ratio ratio-1x1'
                                                                            style={{ width: 65 }}>
                                                                            <div
                                                                                className={classNames(
                                                                                    'rounded-2',
                                                                                    'd-flex align-items-center justify-content-center',
                                                                                    {
                                                                                        'bg-l10-info':
                                                                                            !darkModeStatus,
                                                                                        'bg-lo25-info':
                                                                                            darkModeStatus,
                                                                                    },
                                                                                )}>
                                                                                <span className='fw-bold'>
                                                                                    <span className='ms-1 text-info fs-3'>
                                                                                        {(exam.dateOfExamForView).substring(0, 2)}
                                                                                    </span><br />
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                                                        <div>
                                                                            <small className='fw-bold fs-5'>
                                                                                {exam.paperName}
                                                                            </small>
                                                                            <div className=' text-info fs-6 mb-0'>
                                                                                {exam.dateOfExamForView} ({(exam.examSession)})
                                                                            </div>
                                                                            <div className='text-info'>
                                                                                <small>
                                                                                    <span
                                                                                        className={classNames(
                                                                                            'fw-bold',
                                                                                            {
                                                                                                'text-black-50':
                                                                                                    !darkModeStatus,
                                                                                                'text-white-50':
                                                                                                    darkModeStatus,
                                                                                            },
                                                                                        )}>
                                                                                        {exam.startTime} - {exam.endTime}
                                                                                    </span>
                                                                                </small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='row'>
                                    <div className='col-12' style={{ height: '16vh' }}></div>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <Icon icon='DoNotDisturb' size={'10x'} />
                                    </div>
                                    <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                        Time Table Not Created
                                    </div>
                                </div>
                            )}
                        </>
                    ) : null}
                </Page>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </PageWrapper>
        </>
    )
} export default ExamSchedule;