import React, { useContext, useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { toasts } from '../../../services/toast.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import useDarkMode from '../../../hooks/useDarkMode';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import classNames from 'classnames';
import Icon from '../../icon/Icon';
import { getLicenseKey } from '../../../services/application.settings';
import AuthContext from '../../../contexts/authContext';
import { useFormik } from 'formik';
import Input from '../../bootstrap/forms/Input';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import NoDataMsg from '../../../common/components/NoDataMsg';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Select from '../../bootstrap/forms/Select';
import Checks from '../../bootstrap/forms/Checks';
import { showLoader } from '../../../services/loader.services';
import AlertService from '../../../services/AlertService';
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from '../../../services/master.service';
import { getGraduationType, getSectionDetails } from '../../../services/common.service';
import { getStudentDetailsBySemesterId } from '../../../services/student.service';

const AddStudentMark = () => {
    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        getGraduationList();
        getDepartment();
        getSectionList();
    }, []);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number);
    const [dataSuccess, setDataSuccess] = useState(false);

    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])


    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [isLoader, setIsLoader] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('');

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [columnVisibilityData, setColumnVisibilityData] = useState([]);

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [addMarkOffCanvas, setAddMarkOffCanvas] = useState(false)

    const markDetailsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentExamMarkTotalId: '',
            attendanceStatus: 1,
            overAllScored: '',
            grade: '',
            rank: '',
            result: '',
        },
        validate: (values) => {
            const errors: {
                studentExamMarkTotalId?: string;
                overAllScored?: string;
                grade?: string;
                rank?: string;
                result?: string;
            } = {};
            if (!values.grade) {
                errors.grade = 'Required';
            }
            if (!values.rank) {
                errors.rank = 'Required';
            }
            if (!values.result) {
                errors.result = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addStudentMarkDetailsSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData: any = onCurrentPageData.filter(
        (i: any) =>
            i.sno !== null && i.sno.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            // i.examTypeName !== null && i.examTypeName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.admissionNo !== null && i.admissionNo.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            i.studentName !== null && i.studentName.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) 
            // ||
            // i.studentRollNo !== null && i.studentRollNo.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()) ||
            // i.result !== null && i.result.toString().toLowerCase().includes(columnVisibilityForm.values.searchInput.toLowerCase()),
    );

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setIsLoader(false);
                        setStudentDetailsData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setStudentDetailsData([]);
                    // setAlertStatus({ message: response.data.message, type: "error" });
                    // setIsOpen(true);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }


    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    function viewStudentList() {
        setDataSuccess(false);
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }


    function getExamDetailsList(academicPeriodId: any) {
        // getExamDetails(
        //     academicPeriodId,
        //     (response) => {
        //         if (response.data.success) {
        //             let data = response.data.data.examDetails;
        //             if (data != undefined) {
        //                 setExamTypeData(data);
        //             } else {
        //                 toasts('Undefined Data', 'Error');
        //             }
        //         } else if (response.data.success === false) {
        //             // toasts(response.data.message, "Error")
        //             setExamTypeData([]);
        //         } else {
        //             let errorCode = response.data.error[0].error_code;
        //             let errorDescription = response.data.error[0].error_description;
        //             toasts(errorDescription, 'Error');
        //         }
        //     },
        //     (error) => {
        //         toasts(error, 'Error');
        //     },
        // );
    }

    const [subjectPaperData, setSubjectPaperData] = useState<any>([])

    function getSubjectPaperList(studentDetailsId: any, type: any) {
        // getStudentStudyingSubjectList(studentDetailsId,
        //     (response) => {
        //         if (response.data.success) {
        //             let data = response.data.data.studentStudyingSubjectList;
        //             if (data != undefined) {
        //                 setSubjectPaperData(data);

        //                 if (type == 'isCreate') {

        //                     let newData: any = []

        //                     for (let i = 0; i < data.length; i++) {
        //                         newData.push({ paperName: data[i].paperName, subjectPaperDetailsId: data[i].subjectPaperDetailsId, totalTheoryMark: '', scoredTheoryMark: '', totalPracticalMark: '', scoredPracticalMark: '', totalMark: '', totalScoredMark: '', isMajorSubject: false, attendanceStatus: 1, studentExamMarkDetailsId: 0, displayOrder: i })
        //                     }
        //                     setAddMarkDetails(newData)
        //                 }

        //             } else {
        //                 toasts('Undefined Data', 'Error');
        //             }
        //         } else if (response.data.success === false) {
        //             // toasts(response.data.message, "Error")
        //             setSubjectPaperData([]);
        //         } else {
        //             let errorCode = response.data.error[0].error_code;
        //             let errorDescription = response.data.error[0].error_description;
        //             toasts(errorDescription, 'Error');
        //         }
        //     },
        //     (error) => {
        //         toasts(error, 'Error');
        //     },
        // );
    }

    const [addMarkDetails, setAddMarkDetails] = useState<any>([])
    const [markDetails, setMarkDetails] = useState<any>([])
    const [overAllMark, setOverAllMark] = useState<any>('')

    function openMarkInfo(i: any) {
        markDetailsForm.resetForm()
        setMarkDetails([])
        setPercentage('')
        setOverAllMark('')
        setOverAllScored('')
        setAddMarkOffCanvas(true)

        if (i != undefined) {
            getSubjectPaperList(i.studentDetailsId, 'isCreate')
            setStudentDetailsId(i.studentDetailsId)
        }

    }

    const addStudentMark = (subjectPaperDetailsId: any, totalTheoryMark: any, scoredTheoryMark: any, totalPracticalMark: any, scoredPracticalMark: any, isMajorSubject: any, attendanceStatus: any, studentExamMarkDetailsId: any, displayOrder: any) => {

        let totalMark = totalTheoryMark > 0 || totalPracticalMark > 0 ? Number(totalTheoryMark) + Number(totalPracticalMark) : ''
        let totalScoredMark = scoredTheoryMark > 0 || scoredPracticalMark > 0 ? Number(scoredTheoryMark) + Number(scoredPracticalMark) : ''

        const newData: any = [];

        addMarkDetails.forEach((item: any, index: any) => {
            if (item['displayOrder'] == displayOrder) {
                item['studentExamMarkDetailsId'] = studentExamMarkDetailsId
                item['totalTheoryMark'] = totalTheoryMark
                item['scoredTheoryMark'] = scoredTheoryMark
                item['totalPracticalMark'] = totalPracticalMark
                item['scoredPracticalMark'] = scoredPracticalMark
                item['totalMark'] = totalMark
                item['totalScoredMark'] = totalScoredMark
                item['subjectPaperDetailsId'] = subjectPaperDetailsId
                item['displayOrder'] = displayOrder
                item['isMajorSubject'] = isMajorSubject
                item['attendanceStatus'] = attendanceStatus
            }
            newData.push(item)
        });

        setAddMarkDetails(newData)

        if (markDetails.length == 0) {
            const obj = {
                displayOrder: displayOrder,
                studentExamMarkDetailsId: studentExamMarkDetailsId,
                subjectPaperDetailsId: subjectPaperDetailsId,
                totalTheoryMark: attendanceStatus == 1 ? totalTheoryMark : 'A',
                scoredTheoryMark: attendanceStatus == 1 ? scoredTheoryMark : 'A',
                totalPracticalMark: attendanceStatus == 1 ? totalPracticalMark : 'A',
                scoredPracticalMark: attendanceStatus == 1 ? scoredPracticalMark : 'A',
                totalMark: attendanceStatus == 1 ? totalMark : 'A',
                totalScoredMark: attendanceStatus == 1 ? totalScoredMark : 'A',
                attendanceStatus: attendanceStatus,
                isMajorSubject: isMajorSubject == true ? 1 : 0
            };
            markDetails.push(obj);
        } else {
            let selectedItem: any;
            markDetails.forEach((item: any, index: any) => {
                if (item['displayOrder'] == displayOrder) {
                    selectedItem = item
                    selectedItem['studentExamMarkDetailsId'] = studentExamMarkDetailsId,
                        selectedItem['subjectPaperDetailsId'] = subjectPaperDetailsId,
                        selectedItem['totalTheoryMark'] = attendanceStatus == 1 ? totalTheoryMark : 'A',
                        selectedItem['scoredTheoryMark'] = attendanceStatus == 1 ? scoredTheoryMark : 'A',
                        selectedItem['totalPracticalMark'] = attendanceStatus == 1 ? totalPracticalMark : 'A',
                        selectedItem['scoredPracticalMark'] = attendanceStatus == 1 ? scoredPracticalMark : 'A',
                        selectedItem['totalMark'] = attendanceStatus == 1 ? totalMark : 'A',
                        selectedItem['totalScoredMark'] = attendanceStatus == 1 ? totalScoredMark : 'A',
                        selectedItem['attendanceStatus'] = attendanceStatus,
                        selectedItem['isMajorSubject'] = isMajorSubject == true ? 1 : 0
                }
            });
            if (selectedItem != undefined) {
                selectedItem['displayOrder'] = displayOrder,
                    selectedItem['studentExamMarkDetailsId'] = studentExamMarkDetailsId,
                    selectedItem['subjectPaperDetailsId'] = subjectPaperDetailsId,
                    selectedItem['totalTheoryMark'] = attendanceStatus == 1 ? totalTheoryMark : 'A',
                    selectedItem['scoredTheoryMark'] = attendanceStatus == 1 ? scoredTheoryMark : 'A',
                    selectedItem['totalPracticalMark'] = attendanceStatus == 1 ? totalPracticalMark : 'A',
                    selectedItem['scoredPracticalMark'] = attendanceStatus == 1 ? scoredPracticalMark : 'A',
                    selectedItem['totalMark'] = attendanceStatus == 1 ? totalMark : 'A',
                    selectedItem['totalScoredMark'] = attendanceStatus == 1 ? totalScoredMark : 'A',
                    selectedItem['attendanceStatus'] = attendanceStatus,
                    selectedItem['isMajorSubject'] = isMajorSubject == true ? 1 : 0
            }
            else {
                var obj
                obj = {
                    displayOrder: displayOrder,
                    studentExamMarkDetailsId: studentExamMarkDetailsId,
                    subjectPaperDetailsId: subjectPaperDetailsId,
                    totalTheoryMark: attendanceStatus == 1 ? totalTheoryMark : 'A',
                    scoredTheoryMark: attendanceStatus == 1 ? scoredTheoryMark : 'A',
                    totalPracticalMark: attendanceStatus == 1 ? totalPracticalMark : 'A',
                    scoredPracticalMark: attendanceStatus == 1 ? scoredPracticalMark : 'A',
                    totalMark: attendanceStatus == 1 ? totalMark : 'A',
                    totalScoredMark: attendanceStatus == 1 ? totalScoredMark : 'A',
                    attendanceStatus: attendanceStatus,
                    isMajorSubject: isMajorSubject == true ? 1 : 0
                };
                markDetails.push(obj);
            }
        }

        let majorSubjectData = markDetails.filter((item: any) => item.isMajorSubject == 1)

        const overAllMarks = majorSubjectData.reduce((acc: any, item: any) => acc + parseInt(item.totalMark, 10), 0);
        setOverAllMark(overAllMarks > 0 ? overAllMarks : '')

        const overAllScoredMarks = majorSubjectData.reduce((acc: any, item: any) => acc + parseInt(item.totalScoredMark, 10), 0);
        setOverAllScored(overAllScoredMarks > 0 ? overAllScoredMarks : '')

        let percentage: any = (overAllScoredMarks / subjectPaperData.length);
        setPercentage(overAllScoredMarks > 0 ? Number(percentage) + '%' : '')
    }

    const [overAllScored, setOverAllScored] = useState<any>('')
    const [percentage, setPercentage] = useState<any>('')

    function selectOverAllScored(e: any) {
        setOverAllScored(e.target.value)
        let percentage = (e.target.value / subjectPaperData.length);
        setPercentage(Number(percentage) + '%')
    }

    function setStudentMarkDetails() {
        return ({
            licenseKey: getLicenseKey,
            batchMasterId: batchMasterId?.value,
            // standardDetailsId: standardDetailsId?.value,
            sectionDetailsId: sectionDetailsId?.value,
            // examDetailsId: examDetailsId?.value,
            staffDetailsId: userAccountId,
            studentDetailsId: studentDetailsId,
            markDetails: markDetails,
            markTotalDetails: [
                {
                    studentExamMarkTotalId: markDetailsForm.values.studentExamMarkTotalId != '' ? markDetailsForm.values.studentExamMarkTotalId : 0,
                    attendanceStatus: markDetailsForm.values.attendanceStatus,
                    total: overAllMark,
                    totalScored: overAllScored,
                    grade: markDetailsForm.values.grade,
                    rank: markDetailsForm.values.rank,
                    result: markDetailsForm.values.result,
                    percentage: percentage,
                }
            ]
        })
    }

    function addStudentMarkDetailsSubmit() {
        showLoader(true);
        if (markDetailsForm.isValid) {
            let studentMarkDetailsPostData = setStudentMarkDetails();
            // addStudentMarkDetails(studentMarkDetailsPostData,
            //     (response) => {
            //         const data = response.data;
            //         if (data.success == true) {
            //             showLoader(false);
            //             setAlertStatus({ message: data.message, type: "success" });
            //             setIsOpen(true);
            //             setDataSuccess(false);
            //             setIsOpenListCard(true);
            //             closeAndReset()
            //             getClassWiseMarkDetails(batchMasterId?.value, standardDetailsId?.value, sectionDetailsId?.value, examDetailsId?.value)
            //             setAddMarkOffCanvas(false)
            //         }
            //         else if (data.success == false) {
            //             showLoader(false);
            //             setAlertStatus({ message: data.message, type: "error" });
            //             setIsOpen(true);
            //         }
            //         else {
            //             let errorCode = response.data.error[0].error_code;
            //             let errorDescription = response.data.error[0].error_description;
            //             showLoader(false);
            //             setAlertStatus({ message: errorDescription, type: "error" });
            //             setIsOpen(true);
            //         }
            //     }
            //     , (error) => {
            //         showLoader(false);
            //         setAlertStatus({ message: error, type: "error" });
            //         setIsOpen(true);
            //     }
            // )
        } else if (markDetailsForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function onActivate(i: any) {
        setAddMarkOffCanvas(true)
        let newData: any = []

        // if (i != undefined) {

        //     let subjectMarkDetails = i.subjectMarkDetails

        //     if (standardDetailsId?.value > 8) {
        //         for (let i = 0; i < subjectMarkDetails.length; i++) {
        //             newData.push({ paperName: subjectMarkDetails[i].paperName, subjectPaperDetailsId: subjectMarkDetails[i].subjectPaperDetailsId, studentExamMarkDetailsId: subjectMarkDetails[i].studentExamMarkDetailsId, totalTheoryMark: subjectMarkDetails[i].totalTheoryMark, scoredTheoryMark: subjectMarkDetails[i].scoredTheoryMark, totalPracticalMark: subjectMarkDetails[i].totalPracticalMark, scoredPracticalMark: subjectMarkDetails[i].scoredPracticalMark, totalMark: subjectMarkDetails[i].totalMark, totalScoredMark: subjectMarkDetails[i].totalScoredMark, isMajorSubject: subjectMarkDetails[i].isMajorSubject == true ? 1 : 0, attendanceStatus: subjectMarkDetails[i].attendanceStatus, displayOrder: i })
        //         }
        //     }
        //     else{
        //         for (let i = 0; i < subjectMarkDetails.length; i++) {
        //             newData.push({ paperName: subjectMarkDetails[i].paperName, subjectPaperDetailsId: subjectMarkDetails[i].subjectPaperDetailsId, studentExamMarkDetailsId: subjectMarkDetails[i].studentExamMarkDetailsId, totalTheoryMark: subjectMarkDetails[i].faTotal, scoredTheoryMark: subjectMarkDetails[i].faScored, totalPracticalMark: subjectMarkDetails[i].saTotal, scoredPracticalMark: subjectMarkDetails[i].saScored, totalMark: subjectMarkDetails[i].totalMark, totalScoredMark: subjectMarkDetails[i].totalScoredMark, isMajorSubject: subjectMarkDetails[i].isMajorSubject == true ? 1 : 0, attendanceStatus: subjectMarkDetails[i].attendanceStatus, displayOrder: i })
        //         }
        //     }
        //     setAddMarkDetails(newData)
        //     setMarkDetails(newData)
        //     setOverAllMark(i.total)
        //     setOverAllScored(i.totalScored)
        //     setPercentage(i.percentage)

        //     markDetailsForm.setValues({
        //         studentExamMarkTotalId: i.studentExamMarkTotalId,
        //         attendanceStatus: i.overAllAttendanceStatus,
        //         overAllScored: i.totalScored,
        //         grade: i.grade,
        //         rank: i.rank,
        //         result: i.result
        //     })

        //     getSubjectPaperList(i.studentDetailsId, 'isEdit')
        //     setStudentDetailsId(i.studentDetailsId)
        // }
    }

    function closeAndReset() {
        setAddMarkDetails([])
        setMarkDetails([])
        markDetailsForm.resetForm()
        setMarkDetails([])
        setPercentage('')
        setOverAllMark('')
        setOverAllScored('')
    }

    return (
        <PageWrapper title='StudentMarkDetails'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' tag='form' noValidate>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Student Mark Creation
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row g-4">
                                <div className='col-md-3'>
                                    <FormGroup id='departmentId' label='Department' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Department'
                                            onChange={selectDepartment}
                                            value={departmentId}
                                            list={departmentData.map((data: any) => (
                                                { value: data.departmentId, label: data.departmentName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>

                                {departmentId?.value != undefined ? <div className='col-md-3'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Graduation Type'
                                            onChange={selectGraduationType}
                                            value={graduationTypeId}
                                            list={graduationTypeData.map((data: any) => (
                                                { value: data.graduationTypeId, label: data.graduationType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {graduationTypeId?.value != undefined ? <div className='col-md-3'>
                                    <FormGroup id='courseId' label='Course' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Course'
                                            onChange={selectCourse}
                                            value={courseId}
                                            list={courseData.map((data: any) => (
                                                { value: data.courseId, label: data.courseName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {courseId?.value != undefined ? <div className='col-md-3'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Batch'
                                            onChange={selectBatch}
                                            value={batchMasterId}
                                            list={batchMasterData.map((data: any) => (
                                                { value: data.batchMasterId, label: data.batchName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {batchMasterId?.value != undefined ? <div className='col-md-3'>
                                    <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Semester'
                                            onChange={selectSemester}
                                            value={semesterDetailsId}
                                            list={semesterDetailsData.map((data: any) => (
                                                { value: data.semesterDetailsId, label: data.semesterNumber }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {semesterDetailsId?.value != undefined ? <div className='col-md-3'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Section'
                                            onChange={selectSection}
                                            value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => (
                                                { value: data.sectionDetailsId, label: data.sectionName }
                                            ))}
                                        />
                                    </FormGroup>
                                </div> : null}

                                {sectionDetailsId?.value != undefined ? <div className='col-3 mt-4'>
                                    <Button icon='ArrowDownward' color='primary' onClick={viewStudentList}>View</Button>
                                </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ?
                    <>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Student List</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('examTypeName')}
                                                className='cursor-pointer text-decoration-underline'>Exam Type
                                                <Icon size='lg' className={getClassNamesFor('examTypeName')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('admissionNo')}
                                                className='cursor-pointer text-decoration-underline'>Admission No
                                                <Icon size='lg' className={getClassNamesFor('admissionNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('studentRollNo')}
                                                className='cursor-pointer text-decoration-underline'>Roll No
                                                <Icon size='lg' className={getClassNamesFor('studentRollNo')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('studentName')}
                                                className='cursor-pointer text-decoration-underline'>Student Name
                                                <Icon size='lg' className={getClassNamesFor('studentName')} icon='FilterList' />
                                            </th>
                                            <th scope='col' onClick={() => requestSort('result')}
                                                className='cursor-pointer text-decoration-underline'><span className='ms-4'>Result</span>
                                                <Icon size='lg' className={getClassNamesFor('result')} icon='FilterList' />
                                            </th>
                                            <th className='d-print-none'><div className='ms-4'>Action</div></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.studentDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        <td>{rowData.examTypeName}</td>
                                                        <td>{rowData.admissionNo}</td>
                                                        <td>{rowData.studentRollNo}</td>
                                                        <td>{rowData.studentName}</td>
                                                        <td scope='col'>
                                                            {rowData.result == 'Pass' ?
                                                                <Button isLink color='success' icon='Circle' className='text-nowrap text-start'>
                                                                    {rowData.result}
                                                                </Button> : rowData.result == 'Fail' ?
                                                                    <Button isLink color='danger' icon='Circle' className='text-nowrap'>
                                                                        {rowData.result}
                                                                    </Button> : <span className='ms-5'><Icon icon='Remove' className='mb-0 text-dark fw-bold h3' /></span>
                                                            }
                                                        </td>
                                                        <td className="d-print-none" >
                                                            {rowData.studentExamMarkTotalId > 0 ?
                                                                <Button color='success' isLight icon='RemoveRedEye' aria-label='View' onClick={() => onActivate(rowData)}>View Mark</Button>
                                                                : <Button color='primary' isLight icon='PlaylistAdd' aria-label='Add' onClick={() => openMarkInfo(rowData)}>Add Mark</Button>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={7} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className="d-print-none"
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                    </>
                    : null
                }

                <Modal
                    setIsOpen={setAddMarkOffCanvas}
                    isOpen={addMarkOffCanvas}
                    titleId='edit'
                    isStaticBackdrop
                    size='xl'>
                    <ModalHeader className="'modal-header'"
                        setIsOpen={() => { setAddMarkOffCanvas(false); closeAndReset() }}>
                        <ModalTitle id='edit'>Student Mark Details</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        {addMarkDetails != '' ? <>

                            {/* {standardDetailsId?.value > 8 ? */}
                                <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                                    <thead>
                                        <tr className='text-center table-primary fs-6'>
                                            <th>Subject</th>
                                            <th>Major</th>
                                            <th>Present / Absent</th>
                                            <th>Theory <br />(Total)</th>
                                            <th>Theory <br />(Score)</th>
                                            <th>Practical <br />(Total)</th>
                                            <th>Practical <br />(Score)</th>
                                            <th>Total Mark</th>
                                            <th>Total Scored</th>
                                        </tr>
                                    </thead>
                                    {addMarkDetails.map((i: any, index: any) => (
                                        <tbody key={index}>
                                            <tr>
                                                <td className='text-center fw-bold text-primary' width={'18%'}>{i.paperName}</td>
                                                <td>
                                                    <Checks className='border border-primary' id={String(i.subjectPaperDetailsId)} name='selectedList' value={i.subjectPaperDetailsId} checked={i.isMajorSubject} onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, e.target.checked, i.attendanceStatus, i.studentExamMarkDetailsId, index)} />
                                                </td>
                                                <td>
                                                    <FormGroup id="attendanceStatus">
                                                        <Select
                                                            ariaLabel=""
                                                            placeholder="P / A"
                                                            onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, e.target.value, i.studentExamMarkDetailsId, index)}
                                                            value={i.attendanceStatus}
                                                        >
                                                            <option value={1} className='text-success fw-bold'>Present</option>
                                                            <option value={2} className='text-danger fw-bold'>Absent</option>
                                                        </Select>
                                                    </FormGroup>
                                                </td>
                                                <td className='text-center'>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="totalTheoryMark">
                                                            <Input
                                                                placeholder="Total"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, e.target.value, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.totalTheoryMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                                <td>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="scoredTheoryMark">
                                                            <Input
                                                                placeholder="Score"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, e.target.value, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.scoredTheoryMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="totalPracticalMark">
                                                            <Input
                                                                placeholder="Total"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, e.target.value, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.totalPracticalMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                                <td>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="scoredPracticalMark">
                                                            <Input
                                                                placeholder="Score"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, e.target.value, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.scoredPracticalMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="totalMark">
                                                            <Input
                                                                disabled
                                                                placeholder="Total Mark"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.totalMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                                <td>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="totalScoredMark">
                                                            <Input
                                                                disabled
                                                                placeholder="Score Mark"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.totalScoredMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table> :

                                <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                                    <thead>
                                        <tr className='text-center table-primary fs-6'>
                                            <th>Subject</th>
                                            <th>Major</th>
                                            <th>Present / Absent</th>
                                            <th style={{ width: '20%' }}>Formative Assessment (FA)<br />
                                                <th style={{ width: '5%' }}>Total</th>
                                                <th style={{ width: '5%' }}>Scored</th>
                                            </th>
                                            <th style={{ width: '20%' }}>Summative Assessment (SA)<br />
                                                <th style={{ width: '5%' }}>Total</th>
                                                <th style={{ width: '5%' }}>Scored</th>
                                            </th>
                                            <th>Total Mark</th>
                                            <th>Total Scored</th>
                                        </tr>
                                    </thead>
                                    {addMarkDetails.map((i: any, index: any) => (
                                        <tbody key={index}>
                                            <tr>
                                                <td className='text-center fw-bold text-primary' width={'18%'}>{i.paperName}</td>
                                                <td>
                                                    <Checks className='border border-primary' id={String(i.subjectPaperDetailsId)} name='selectedList' value={i.subjectPaperDetailsId} checked={i.isMajorSubject} onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, e.target.checked, i.attendanceStatus, i.studentExamMarkDetailsId, index)} />
                                                </td>
                                                <td>
                                                    <FormGroup id="attendanceStatus">
                                                        <Select
                                                            ariaLabel=""
                                                            placeholder="P / A"
                                                            onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, e.target.value, i.studentExamMarkDetailsId, index)}
                                                            value={i.attendanceStatus}
                                                        >
                                                            <option value={1} className='text-success fw-bold'>Present</option>
                                                            <option value={2} className='text-danger fw-bold'>Absent</option>
                                                        </Select>
                                                    </FormGroup>
                                                </td>
                                                <td>
                                                    <td className='text-center'>
                                                        {i.attendanceStatus == 1 ?
                                                            <FormGroup id="totalTheoryMark">
                                                                <Input
                                                                    placeholder="Total"
                                                                    onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, e.target.value, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                    value={i.totalTheoryMark}
                                                                />
                                                            </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {i.attendanceStatus == 1 ?
                                                            <FormGroup id="scoredTheoryMark">
                                                                <Input
                                                                    placeholder="Score"
                                                                    onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, e.target.value, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                    value={i.scoredTheoryMark}
                                                                />
                                                            </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                        }
                                                    </td>
                                                </td>
                                                <td>
                                                    <td className='text-center'>
                                                        {i.attendanceStatus == 1 ?
                                                            <FormGroup id="totalPracticalMark">
                                                                <Input
                                                                    placeholder="Total"
                                                                    onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, e.target.value, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                    value={i.totalPracticalMark}
                                                                />
                                                            </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                        }
                                                    </td>
                                                    <td>
                                                        {i.attendanceStatus == 1 ?
                                                            <FormGroup id="scoredPracticalMark">
                                                                <Input
                                                                    placeholder="Score"
                                                                    onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, e.target.value, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                    value={i.scoredPracticalMark}
                                                                />
                                                            </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                        }
                                                    </td>
                                                </td>
                                                <td className='text-center'>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="totalMark">
                                                            <Input
                                                                disabled
                                                                placeholder="Total Mark"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.totalMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                                <td>
                                                    {i.attendanceStatus == 1 ?
                                                        <FormGroup id="totalScoredMark">
                                                            <Input
                                                                disabled
                                                                placeholder="Score Mark"
                                                                onChange={(e: any) => addStudentMark(i.subjectPaperDetailsId, i.totalTheoryMark, i.scoredTheoryMark, i.totalPracticalMark, i.scoredPracticalMark, i.isMajorSubject, i.attendanceStatus, i.studentExamMarkDetailsId, index)}
                                                                value={i.totalScoredMark}
                                                            />
                                                        </FormGroup> : <div className="fw-bold text-center text-danger">A</div>
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    ))}
                                </table>
                            {/* } */}
                        </>
                            :
                            <div className='row'>
                                <div className='col-12' ></div>
                                <div className='col-12 d-flex justify-content-center'>
                                    <Icon icon='DoNotDisturb' size={'10x'} />
                                </div>
                                <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                    Subject not allocated
                                </div>
                            </div>
                        }

                        {subjectPaperData != '' ? <br /> : null}

                        {subjectPaperData != '' ?
                            <div className="row">
                                <div className="col-3">
                                    <FormGroup id="attendanceStatus" label='Attendance Status' isFloating>
                                        <Select
                                            ariaLabel=""
                                            onChange={markDetailsForm.handleChange}
                                            value={markDetailsForm.values.attendanceStatus}
                                            onBlur={markDetailsForm.handleBlur}
                                            isValid={markDetailsForm.isValid}
                                            isTouched={markDetailsForm.touched.attendanceStatus}
                                            invalidFeedback={markDetailsForm.errors.attendanceStatus}
                                            placeholder="Total"
                                        >
                                            <option value={1} className='text-success fw-bold'>Present</option>
                                            <option value={2} className='text-danger fw-bold'>Absent</option>
                                        </Select>
                                    </FormGroup>
                                </div>

                                <div className="col-3">
                                    <FormGroup id="dateOfExam" label='Over All Mark' isFloating>
                                        <Input
                                            disabled
                                            value={overAllMark}
                                            placeholder="Total"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-3">
                                    <FormGroup id="overAllScored" label='Over All Scored' isFloating>
                                        <Input
                                            disabled
                                            onChange={selectOverAllScored}
                                            value={overAllScored}
                                            onBlur={markDetailsForm.handleBlur}
                                            isValid={markDetailsForm.isValid}
                                            isTouched={markDetailsForm.touched.overAllScored}
                                            invalidFeedback={markDetailsForm.errors.overAllScored}
                                            placeholder="Total"
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-3">
                                    <FormGroup id="grade" label='Percentage' isFloating>
                                        <Input placeholder="Percentage" value={percentage} disabled />
                                    </FormGroup>
                                </div>
                                <div className="col-3 mt-3">
                                    <FormGroup id="grade" label='Grade' isFloating>
                                        <Input
                                            placeholder="Grade"
                                            onChange={markDetailsForm.handleChange}
                                            value={markDetailsForm.values.grade}
                                            onBlur={markDetailsForm.handleBlur}
                                            isValid={markDetailsForm.isValid}
                                            isTouched={markDetailsForm.touched.grade}
                                            invalidFeedback={markDetailsForm.errors.grade}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-3 mt-3">
                                    <FormGroup id="rank" label='Rank' isFloating>
                                        <Input
                                            placeholder="Rank"
                                            onChange={markDetailsForm.handleChange}
                                            value={markDetailsForm.values.rank}
                                            onBlur={markDetailsForm.handleBlur}
                                            isValid={markDetailsForm.isValid}
                                            isTouched={markDetailsForm.touched.rank}
                                            invalidFeedback={markDetailsForm.errors.rank}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-3 mt-3">
                                    <FormGroup id="result" label='Result' isFloating>
                                        <Select
                                            ariaLabel=""
                                            placeholder="Select Result"
                                            onChange={markDetailsForm.handleChange}
                                            value={markDetailsForm.values.result}
                                            onBlur={markDetailsForm.handleBlur}
                                            isValid={markDetailsForm.isValid}
                                            isTouched={markDetailsForm.touched.result}
                                            invalidFeedback={markDetailsForm.errors.result}
                                        >
                                            <option value={'Pass'} className='text-success fw-bold'>Pass</option>
                                            <option value={'Fail'} className='text-danger fw-bold'>Fail</option>
                                        </Select>
                                    </FormGroup>
                                </div>
                            </div> : null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <>
                            <Button color='info' isOutline className='border-0' onClick={() => setAddMarkOffCanvas(false)}>Close</Button>
                            <Button icon="Save" color="info" type="submit" onClick={markDetailsForm.handleSubmit} isDisable={markDetails.length == 0 || subjectPaperData == '' || (!markDetailsForm.isValid && !!markDetailsForm.submitCount) ? true : false}>
                                Submit
                            </Button>
                        </>
                    </ModalFooter>
                </Modal>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    );
};

export default AddStudentMark;
