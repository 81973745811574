import classNames from 'classnames';
import { useFormik } from 'formik';
import { useContext, useEffect, useRef, useState } from 'react';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import AlertService from '../../../services/AlertService';
import { getLicenseKey } from '../../../services/application.settings';
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, getUserType, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import Input from '../../bootstrap/forms/Input';
import Icon from '../../icon/Icon';
import SearchableSelect from '../../../common/components/SearchableSelect';
import NoDataMsg from '../../../common/components/NoDataMsg';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import AuthContext from '../../../contexts/authContext';
import { showLoader } from '../../../services/loader.services';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import { useReactToPrint } from 'react-to-print';
import { addStaffAttendance, checkIsWorkingDay, getStaffAttendanceReportList } from '../../../services/attendance.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { getFirstLetter } from '../../../helpers/helpers';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../../services/ExportService';

const StaffAttendance = () => {

	useEffect(() => {
		getUserTypeList();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);

	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' });
	const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
	const [staffDetailsArray, setStaffDetailsArray] = useState<any>([]);
	const [staffDetailsReportData, setStaffDetailsReportData] = useState<any>([]);

	// For Table
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isReport, setIsReport] = useState('');
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(1000);
	const [isLoader, setIsLoader] = useState(false);
	const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([]);
	const [attendanceConfirmationArray, setAttendanceConfirmationArray] = useState<any>([]);
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [isWorkingDay, setIsWorkingDay] = useState(false);

	const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(staffDetailsReportData);
	const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(staffDetailsData);

	const currentPageData1 = dataPagination(items1, currentPage, perPage);
	const currentPageData = dataPagination(items, currentPage, perPage);

	const staffAttendanceForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			dateOfAttendance: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				dateOfAttendance?: string;
			} = {};

			if (!values.dateOfAttendance) {
				errors.dateOfAttendance = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			selectAttendanceDate();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },

		//validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData: any = currentPageData.filter(
		(i) =>
			(i.userType !== null &&
				i.userType
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
			(i.staffName !== null &&
				i.staffName
					.toString()
					.toLowerCase()
					.includes(columnVisibilityForm.values.searchInput.toLowerCase())),
	);

	// Filter
	const filteredData1 = updateFilter(currentPageData1, columnVisibilityData, columnVisibilityForm);


	function selectAttendanceDate() {
		setIsLoader(false);
		setDataSuccess(false);
		setIsOpenListCard(true);
		// check is working day
		// let dateInMilliSeconds = convertDateToEpoch(staffAttendanceForm.values.dateOfAttendance);
		// let currentDateMilliSeconds = convertDateToEpoch(getCurrentDateAndTime('date'));

		// if (dateInMilliSeconds == currentDateMilliSeconds) {
		// 	setIsWorkingDay(true);
		// } else {
		// 	setAlertStatus({ message: 'Please select today date !', type: 'error' });
		// 	setIsOpen(true);
		// }

		// check is working day
		// if (staffAttendanceForm.values.dateOfAttendance != '') {
		// 	let dateInMilliSec = convertDateToEpoch(staffAttendanceForm.values.dateOfAttendance)
		// 	checkIsWorkingDayAttendance(dateInMilliSec);
		// }
	}

	function viewStaffAttendanceReport() {
		setStaffDetailsArray([]);
		setDataSuccess(false);
		setIsLoader(true);
		let dateInMilliSeconds = convertDateToEpoch(staffAttendanceForm.values.dateOfAttendance);
		let currentDateMilliSeconds = convertDateToEpoch(getCurrentDateAndTime('date'));

		if (dateInMilliSeconds <= currentDateMilliSeconds) {
			getStaffAttendanceReportOnlyForReportButton(dateInMilliSeconds, 0, 1);
		} else {
			setAlertStatus({ message: 'Please select today date or previous date!', type: 'error' });
			setIsOpen(true);
			setIsLoader(false);
		}
	}

	function viewStaffAttendance() {
		setDataSuccess(false);
		setIsLoader(true);
		let currentDateMilliSeconds = convertDateToEpoch(getCurrentDateAndTime('date'));
		let dateInMilliSeconds = convertDateToEpoch(staffAttendanceForm.values.dateOfAttendance);
		//getStaffListByUserType(dateInMilliSeconds, 0, 0);
		// getStaffAttendanceReport(dateInMilliSeconds, 0, 1);
		//checkIsWorkingDayAttendance(dateInMilliSeconds);
		if (dateInMilliSeconds == currentDateMilliSeconds) {
			setIsWorkingDay(true);
			checkIsWorkingDayAttendance(dateInMilliSeconds);
		} else {
			setAlertStatus({ message: 'Please select today date !', type: 'error' });
			setIsOpen(true);
			setIsLoader(false);
		}
	}

	function checkIsWorkingDayAttendance(dateInMilliSec: number) {
		checkIsWorkingDay(dateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.checkIsWorkingDay;
					if (data != undefined) {
						// setWorkingDayData(data);
						setIsWorkingDay(true);
						getStaffAttendanceReport(dateInMilliSec, 0, 1);
					} else {
						setAlertStatus({ message: "Undefined Data", type: "error" })
						setIsOpen(true)
						setIsWorkingDay(false);
					}
				}
				else if (response.data.success === false) {
					setAlertStatus({ message: 'Please select today date !', type: 'error' });
			setIsOpen(true);
					// setAlertStatus({ message: response.data.message, type: "error" })
					// setIsOpen(true)
					// setIsWorkingDay(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: "error" })
					setIsOpen(true)
					setIsWorkingDay(false);
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStaffAttendanceReportOnlyForReportButton(dateOfAttendance: any, userTypeId: any, isReport: any) {
		getStaffAttendanceReportList(
			dateOfAttendance, userTypeId, isReport,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceReportList;
					if (data != undefined) {
						getColumnsForTable('getStaffAttendanceReportList', 'get');
						setIsReport('Report');
						setStaffDetailsReportData(data);
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataSuccess(false);
						setIsOpenListCard(true);
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsReport('Report');
					getColumnsForTable('getStaffAttendanceReportList', 'get');
					setDataSuccess(true);
					setStaffDetailsReportData([]);
					setIsOpenListCard(false);
					setIsLoader(false);
					setNoDataMsg(response.data.message);
				} else {
					setDataSuccess(false);
					setIsOpenListCard(true);
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getStaffAttendanceReport(dateOfAttendance: any, userTypeId: any, isReport: any) {
		getStaffAttendanceReportList(
			dateOfAttendance, userTypeId, isReport,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceReportList;
					if (data != undefined) {
						getColumnsForTable('getStaffAttendanceReportList', 'get');
						setIsReport('Report');
						setStaffDetailsReportData(data);
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataSuccess(false);
						setIsOpenListCard(true);
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setIsReport('')
					getStaffListByUserType(dateOfAttendance, 0, 0);
					//setIsReport('Report');
					//getColumnsForTable('getStaffAttendanceReportList', 'get');
					//setDataSuccess(true);
					//setStaffDetailsReportData([]);
					//setIsOpenListCard(false);
					//setIsLoader(false);
					//setNoDataMsg(response.data.message);
				} else {
					setDataSuccess(false);
					setIsOpenListCard(true);
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						const userTypeData = [{ userTypeId: 0, userType: 'Select All' }];
						data.forEach((obj: { userTypeId: number; userType: string }) => {
							let userType = userTypeData.push(obj);
						});
						setUserTypeData(userTypeData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [isCheckAll, setIsCheckAll] = useState(false);
	const [isCheckedSelectAll, setIsCheckedSelectAll] = useState(false)
	const [isPresentAll, setIsPresentAll] = useState(false)
	const [checkLength, setCheckLength] = useState(0);

	function getStaffListByUserType(dateOfAttendance: any, staffTypeId: any, isReport: any) {
		getStaffAttendanceReportList(
			dateOfAttendance, staffTypeId, isReport,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffAttendanceReportList;
					if (data != undefined) {
						let pendingData = data.filter((item: any) => item.attendanceStatusId == 3)
						let pendingData1 = data.filter((item: any) => item.attendanceStatusId == 1)
						setCheckLength(pendingData.length);
						setIsPresentAll(pendingData1 != '' ? true : false)
						setIsCheckedSelectAll(pendingData == '' ? true : false)
						//setIsReport(pendingData1.length != 0 ? 'Report' : '');
						// if (pendingData1.length != 0) {
						// 	getStaffAttendanceReport(dateOfAttendance, 0, 1);
						// }
						setStaffDetailsData(data);
						setFilteredDataToRefresh(data);
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].isEditable = data[i].isEditable = true;
							data[i].isSelect = data[i].isSelect = false;
						}
					} else {
						setDataSuccess(false);
						setIsOpenListCard(true);
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataSuccess(true);
					//setIsReport('');
					setStaffDetailsData([]);
					setIsOpenListCard(false);
					setIsLoader(false);
					setNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
				} else {
					setDataSuccess(false);
					setIsOpenListCard(true);
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	const updateStaffDetailsIdValue = (e: any, cell: number, staffName: any) => {

		const newData: any = [];

		if (e.target.checked == true) {
			filteredDataToRefresh.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					item['isSelect'] = true;
					item['isEditable'] = false;
				}
				newData.push(item);
			});
			setFilteredDataToRefresh(newData);
		} else if (e.target.checked == false) {
			setIsCheckAll(false)
			filteredDataToRefresh.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					item['isSelect'] = false;
					item['isEditable'] = true;
					item['remarks'] = '';
				}
				newData.push(item);
			});

			setFilteredDataToRefresh(newData);
		}

		if (attendanceConfirmationArray.length == 0) {
			const obj = {
				staffDetailsId: cell,
				staffName: staffName,
			};
			attendanceConfirmationArray.push(obj);
		} else {
			let selectedItem1: any;
			attendanceConfirmationArray.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					selectedItem1 = item;
					selectedItem1['staffDetailsId'] = cell;
				}
			});
			if (selectedItem1 != undefined) {
				attendanceConfirmationArray.splice(
					attendanceConfirmationArray.indexOf(selectedItem1),
					1,
				);
				selectedItem1['staffDetailsId'] = cell;
			} else {
				var obj;
				obj = {
					staffDetailsId: cell,
					staffName: staffName,
				};

				attendanceConfirmationArray.push(obj);
			}
		}

		if (staffDetailsArray.length == 0) {
			const obj = {
				staffDetailsId: cell,
				absentOrPermission: 1,
				remarks: '',
			};
			staffDetailsArray.push(obj);
		} else {
			let selectedItem: any;
			staffDetailsArray.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					selectedItem = item;
					selectedItem['staffDetailsId'] = cell;
				}
			});
			if (selectedItem != undefined) {
				staffDetailsArray.splice(staffDetailsArray.indexOf(selectedItem), 1);
				selectedItem['staffDetailsId'] = cell;
			} else {
				var obj;
				obj = {
					staffDetailsId: cell,
					absentOrPermission: 1,
					remarks: '',
				};

				staffDetailsArray.push(obj);
				if (staffDetailsArray.length != 0) {
					if (staffDetailsArray.length == checkLength) {
						setIsCheckAll(true)
					}
				}
			}
		}
	};

	const updateRemarksValue = (remarks: any, cell: number) => {
		const newData: any = [];

		filteredData.forEach((item: any, index: any) => {
			if (item['staffDetailsId'] == cell) {
				item['remarks'] = remarks;
			}
			newData.push(item);
		});

		setFilteredDataToRefresh(newData);

		if (staffDetailsArray.length == 0) {
			const obj = {
				staffDetailsId: cell,
				absentOrPermission: '',
				remarks: remarks,
			};
			staffDetailsArray.push(obj);
		} else {
			let selectedItem: any;
			staffDetailsArray.forEach((item: any, index: any) => {
				if (item['staffDetailsId'] == cell) {
					selectedItem = item;
					selectedItem['remarks'] = remarks;
				}
			});
			if (selectedItem != undefined) {
				selectedItem['remarks'] = remarks;
			} else {
				var obj;
				obj = {
					staffDetailsId: cell,
					absentOrPermission: '',
					remarks: remarks,
				};

				staffDetailsArray.push(obj);
			}
		}
	};

	const selectAllStaffs = (e: any) => {

		const newData: any = [];
		setIsCheckAll(e)
		if (e == true) {

			staffDetailsArray.splice(0, staffDetailsArray.length)

			filteredDataToRefresh.forEach((item: any) => {

				if (item.attendanceStatusId == 3) {
					const objForConfirmation = {
						staffDetailsId: item.staffDetailsId,
						staffName: item.staffName,
					};
					attendanceConfirmationArray.push(objForConfirmation);


					item.isSelect = true
					item.isEditable = false
					newData.push(item.studentDetailsId)
					var obj
					obj = {
						staffDetailsId: item.staffDetailsId,
						absentOrPermission: 1,
						remarks: '',
					};
					staffDetailsArray.push(obj);
				}
			});
		} else {
			if (e == false) {
				filteredData.forEach((item: any) => {
					item.isSelect = false
					item.isEditable = true
					item['remarks'] = '';
					setStaffDetailsArray([])
					setAttendanceConfirmationArray([])
				});
			}
		}
	}

	function setStaffAttendance() {
		return {
			dateOfAttendance: staffAttendanceForm.values.dateOfAttendance,
			userTypeId: userAccountId,
			staffDetails: staffDetailsArray,
			submittedBy: userAccountId,
			licenseKey: getLicenseKey,
		};
	}

	function staffAttendanceSubmit() {
		showLoader(true);
		if (staffAttendanceForm.isValid) {
			let attendancePostData = setStaffAttendance();
			addStaffAttendance(
				attendancePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						setStaffDetailsArray([]);
						setDataSuccess(false);
						setIsWorkingDay(false);
						setIsOpenListCard(true);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						showLoader(false);
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (staffAttendanceForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						const allData = allColumnsData.map((item: any) => item.isDisplay);
						const allColumns = allData.filter((item: any) => item == false);

						if (type == 'get') {
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: allColumns == '' ? 0 : 1,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'warning');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStaffAttendanceReportList', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'warning');
		}
	}

	function closeAndReset() {
		setConfirmationOpen(false);
		setStaffTypeId('')
		setAttendanceConfirmationArray([])
		setCheckLength(0);
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<PageWrapper title='Attendance'>
				<Page container='fluid'>
					<Collapse isOpen={isOpenListCard}>
						<Card
							stretch
							data-tour='list'
							tag='form'
							noValidate
							onSubmit={staffAttendanceForm.handleSubmit}>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Staff Attendance
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='col-3'>
										<FormGroup id='dateOfAttendance' label='Date' isFloating>
											<Input
												onChange={staffAttendanceForm.handleChange}
												value={
													staffAttendanceForm.values.dateOfAttendance
												}
												onBlur={staffAttendanceForm.handleBlur}
												isValid={staffAttendanceForm.isValid}
												isTouched={
													staffAttendanceForm.touched.dateOfAttendance
												}
												invalidFeedback={
													staffAttendanceForm.errors.dateOfAttendance
												}
												type='date'
											/>
										</FormGroup>
									</div>
									<div className='col-3'>
										<Button
											className='mt-2 ms-2'
											icon='ArrowDownward'
											color='primary'
											onClick={viewStaffAttendance}
										>
											View
										</Button>
										<Button
											className='mt-2 ms-2'
											icon='Report'
											color='danger'
											isDisable={
												staffAttendanceForm.values
													.dateOfAttendance != ''
													? false
													: true
											}
											onClick={viewStaffAttendanceReport}>
											Report
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</Collapse>

					<OpenCardComponent
						isOpenListCard={isOpenListCard}
						setIsOpenListCard={setIsOpenListCard}
						isLoader={isLoader}
					/>

					{dataSuccess ? (
						<>
							{isReport == '' ? (
								<>
									<Card stretch data-tour='list'>
										<CardHeader borderSize={1}>
											<div className='d-flex col-5 justify-content-start'>
												<CardLabel icon='List' iconColor='info'>
													<CardTitle tag='div' className='h5'>
														Staff Attendance List
													</CardTitle>
												</CardLabel>
											</div>
											<div className='d-flex col-7 justify-content-center g-4'>
												<div className='col-2 justify-content-center'></div>
												<div
													className='d-flex col-5 justify-content-start'
													data-tour='search'>
													<label
														className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
														htmlFor='searchInput'>
														<Icon
															icon='Search'
															size='2x'
															color='primary'
														/>
													</label>
													<Input
														id='searchInput'
														type='search'
														placeholder='Search...'
														onChange={columnVisibilityForm.handleChange}
														value={
															columnVisibilityForm.values.searchInput
														}
													/>
												</div>
												&nbsp;&nbsp;
												<div className='col-2 justify-content-center'>
													<Button
														icon='Save'
														color='primary'
														isDisable={
															staffDetailsArray.length > 0
																? false
																: true
														}
														onClick={() => {
															setConfirmationOpen(true);
														}}>
														Submit
													</Button>
												</div>
												<div className='col-3 justify-content-end'>
													<Button
														icon='PresentToAll'
														color='success'
														isDisable={
															staffDetailsArray.length > 0 || isPresentAll == true
																? true
																: false
														}
														onClick={staffAttendanceSubmit}>
														All Present
													</Button>
												</div>
											</div>
										</CardHeader>
										<CardBody className='table-responsive'>
											<table className='table table-modern table-hover text-nowrap'>
												<thead>
													<tr>
														<th scope='col'>
															<Checks
																disabled={isCheckedSelectAll}
																onChange={(e: any) => { selectAllStaffs(e.target.checked) }}
																checked={isCheckAll}
															/>
														</th>
														<th
															scope='col'
															onClick={() => requestSort('sno')}
															className='cursor-pointer text-decoration-underline text-nowrap'>
															S.No{' '}
															<Icon
																size='lg'
																className={getClassNamesFor('sno')}
																icon='FilterList'
															/>
														</th>
														<th
															scope='col'
															onClick={() => requestSort('staffName')}
															className='cursor-pointer text-decoration-underline text-nowrap'>
															Staff Name{' '}
															<Icon
																size='lg'
																className={getClassNamesFor(
																	'staffName',
																)}
																icon='FilterList'
															/>
														</th>
														<th
															scope='col'
															onClick={() => requestSort('userType')}
															className='cursor-pointer text-decoration-underline text-nowrap'>
															User Type{' '}
															<Icon
																size='lg'
																className={getClassNamesFor(
																	'userType',
																)}
																icon='FilterList'
															/>
														</th>
														<th>Period</th>
														<th>Remarks</th>
													</tr>
												</thead>
												<tbody>
													{filteredData != '' ? (
														<>
															{filteredData.map((rowData: any) => (
																<tr key={rowData.staffDetailsId}>
																	<td>
																		{rowData.attendanceStatusId == 3 || rowData.attendanceStatusId == 2 ?
																			<Checks
																				value={
																					rowData.staffDetailsId
																				}
																				disabled={rowData.absentOrPermission != ''}
																				checked={
																					rowData.isSelect
																				}
																				onChange={(e: any) =>
																					updateStaffDetailsIdValue(
																						e,
																						rowData.staffDetailsId,
																						rowData.staffName,
																					)
																				}
																			/> : <Icon
																				size='lg'
																				color='success'
																				icon='CheckBox'
																			/>}
																	</td>
																	<td>{rowData.sno}</td>
																	<td>{rowData.staffName}</td>
																	<td>{rowData.userType}</td>
																	{rowData.attendanceStatusId == 3 || rowData.attendanceStatusId == 2 ?
																		<>
																			{rowData.isSelect == true ? (
																				<td scope='col'>
																					<Button
																						isLink
																						color='danger'
																						icon='Circle'
																						className='text-nowrap'>
																						Absent
																					</Button>
																				</td>
																			) : (
																				<td scope='col'>
																					<Button
																						isLink
																						color='success'
																						icon='Circle'
																						className='text-nowrap'>
																						Present
																					</Button>
																				</td>
																			)}
																		</> : <td scope='col'>
																			<Button
																				isLink
																				color='danger'
																				icon='Circle'
																				className='text-nowrap'>
																				Absent
																			</Button>
																		</td>}
																	{rowData.attendanceStatusId == 3 || rowData.attendanceStatusId == 2 ?
																		<td scope='col'>
																			<FormGroup id='remarks'>
																				<Input
																					className='w-80'
																					placeholder=''
																					value={
																						rowData.remarks
																					}
																					disabled={
																						rowData.isEditable
																					}
																					onInput={(e: any) =>
																						updateRemarksValue(
																							e.target
																								.value,
																							rowData.staffDetailsId,
																						)
																					}
																				/>
																			</FormGroup>
																		</td> : <td scope='col'>
																			<FormGroup id='remarks'>
																				<Input
																					className='w-80'
																					placeholder=''
																					value={
																						rowData.remarks
																					}
																					disabled
																				/>
																			</FormGroup>
																		</td>}
																</tr>
															))}
														</>
													) : (
														<NoDataMsg
															columnsCount={6}
															msg={noDataMsg}
														/>
													)}
												</tbody>
											</table>
										</CardBody>
									</Card>
								</>
							) : (
								<>
									<Card stretch data-tour='list' ref={componentRef}>
										<CardHeader borderSize={1}>
											<CardLabel
												icon='List'
												iconColor='info'
												className='col-lg-6'>
												<CardTitle tag='div' className='h5'>
													Staff Attendance List
												</CardTitle>
											</CardLabel>
											&nbsp;&nbsp;
											<ButtonGroup
												className='col-lg-3 d-print-none'
												color='primary'>
												<Icon
													className='mt-1'
													icon='Search'
													size='2x'
													color='primary'
												/>
												<Input
													id='searchInput'
													type='search'
													placeholder='Search...'
													onChange={columnVisibilityForm.handleChange}
													value={columnVisibilityForm.values.searchInput}
												/>
											</ButtonGroup>
											<CardActions className='d-print-none'>
												<Dropdown
													isOpen={columnVisibilityMenu}
													setIsOpen={setColumnVisibilityMenu}
													isButtonGroup>
													<DropdownToggle>
														<Button
															icon='FilterAlt'
															color='primary'
															isLight>
															Filter
														</Button>
													</DropdownToggle>
													<DropdownMenu
														isAlignmentEnd
														size='lg'
														isCloseAfterLeave={false}>
														<DropdownItem>
															<div className='container py-2'>
																<form className='row g-3'>
																	<div className='col-12'>
																		<FormGroup>
																			<h6>Select All</h6>
																			<Checks
																				id='available'
																				type='switch'
																				label='Select All Columns'
																				onChange={() =>
																					getColumnsForTable(
																						'getStaffAttendanceReportList',
																						'post',
																					)
																				}
																				checked={isChecked}
																				ariaLabel='Available status'
																			/>
																		</FormGroup>
																	</div>
																	<div
																		className='col-12'
																		style={{
																			maxHeight: '200px',
																			overflowY: 'scroll',
																		}}>
																		<FormGroup>
																			<h6>Columns</h6>
																			<ChecksGroup>
																				{allColumnsData.map(
																					(i: any) => (
																						<Checks
																							key={
																								i.columnVisibilityId
																							}
																							label={
																								i.columnName
																							}
																							onChange={() =>
																								updateColumnsSubmit(
																									i.columnVisibilityId,
																									i.isDisplay,
																									'',
																								)
																							}
																							checked={
																								i.isDisplay
																							}
																							disabled={
																								i.isDefault ==
																								true
																							}
																						/>
																					),
																				)}
																			</ChecksGroup>
																		</FormGroup>
																	</div>
																</form>
															</div>
														</DropdownItem>
													</DropdownMenu>
												</Dropdown>
												<Dropdown isButtonGroup>
													<DropdownToggle>
														<Button
															color='primary'
															isLight
															icon='CloudDownload'>
															{' '}
															Export{' '}
														</Button>
													</DropdownToggle>
													<DropdownMenu isAlignmentEnd>
														<DropdownItem>
															<Button
																color='primary'
																isLight
																icon='CloudDownload'
																onClick={() =>
																	downloadExcel(
																		'StaffAttendanceList',
																		staffDetailsReportData,
																		columnVisibilityData,
																	)
																}>
																{' '}
																Excel{' '}
															</Button>
														</DropdownItem>
														<DropdownItem>
															<Button
																color='primary'
																isLight
																icon='PictureAsPdf'
																onClick={() =>
																	convertJsonToPdf(
																		staffDetailsReportData,
																		columnVisibilityData,
																		'StaffAttendanceList',
																	)
																}>
																{' '}
																PDF
															</Button>
														</DropdownItem>
														<DropdownItem>
															<Button
																color='primary'
																isLight
																icon='CloudDownload'
																onClick={() =>
																	downloadFile(
																		'StaffAttendanceList',
																		staffDetailsReportData,
																		columnVisibilityData,
																	)
																}>
																{' '}
																CSV{' '}
															</Button>
														</DropdownItem>
														<DropdownItem>
															<Button
																color='primary'
																isLight
																icon='Print'
																onClick={handlePrint}>
																{' '}
																Print{' '}
															</Button>
														</DropdownItem>
													</DropdownMenu>
												</Dropdown>
											</CardActions>
										</CardHeader>

										<CardBody className='table-responsive' isScrollable>
											<table className='table table-modern table-hover text-nowrap'>
												<thead>
													<tr>
														<th
															scope='col'
															onClick={() => requestSort('sno')}
															className='cursor-pointer text-decoration-underline'>
															S.No{''}
															<Icon
																size='lg'
																className={getClassNamesFor('sno')}
																icon='FilterList'
															/>
														</th>
														{columnVisibilityData.map((column: any) => (
															<th
																key={column.keyName}
																scope='col'
																onClick={() =>
																	requestSort(column.keyName)
																}
																className='cursor-pointer text-decoration-underline'>
																{column.columnName}
																<Icon
																	size='lg'
																	className={getClassNamesFor(
																		column.keyName,
																	)}
																	icon='FilterList'
																/>
															</th>
														))}
													</tr>
												</thead>
												<tbody>
													{filteredData1 != '' ? (
														<>
															{filteredData1.map((rowData: any) => (
																<tr key={rowData.staffDetailsId}>
																	<td>{rowData.sno}</td>
																	{columnVisibilityData.map(
																		(column: any) =>
																			column.isDisplay &&
																				rowData[
																				column.keyName
																				] ? (
																				<td
																					className='text-nowrap'
																					key={`${rowData.staffDetailsId}-${column.keyName}`}>
																					{
																						rowData[
																						column
																							.keyName
																						]
																					}
																				</td>
																			) : (
																				<td
																					key={`empty-${column.keyName}`}
																				/>
																			),
																	)}
																</tr>
															))}
														</>
													) : (
														<NoDataMsg
															columnsCount={
																columnVisibilityData.length + 1
															}
															msg={noDataMsg}
														/>
													)}
												</tbody>
											</table>
										</CardBody>

										<PaginationButtons
											className='d-print-none'
											data={items}
											label='items'
											setCurrentPage={setCurrentPage}
											currentPage={currentPage}
											perPage={perPage}
											setPerPage={setPerPage}
										/>
									</Card>
								</>
							)}
						</>
					) : null}
				</Page>

				<Modal
					setIsOpen={setConfirmationOpen}
					isOpen={confirmationOpen}
					titleId='absentsStudent'
					isStaticBackdrop
					size='sm'
					isScrollable>
					<ModalHeader setIsOpen={setConfirmationOpen}>
						<ModalTitle id='absentsStudent'>Absent Staff</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{attendanceConfirmationArray.map((attendanceConfirmationArray: any) => (
							<div
								className='col d-flex align-items-center'
								key={attendanceConfirmationArray.staffDetailsId}>
								<div className='flex-shrink-0 mt-2'>
									<div className='ratio ratio-1x1 me-3' style={{ width: 48 }}>
										<div
											className={classNames(
												'rounded-2',
												'd-flex align-items-center justify-content-center',
												{
													'bg-l10-dark': !darkModeStatus,
													'bg-l90-dark': darkModeStatus,
												},
											)}>
											<span className='fw-bold'>
												{getFirstLetter(
													attendanceConfirmationArray.staffName,
												)}
											</span>
										</div>
									</div>
								</div>
								<div className='flex-grow-1'>
									<div className='fs-6'>
										{attendanceConfirmationArray.staffName}
									</div>
									{/* <div className='text-muted'>
                                        {attendanceConfirmationArray.uniRegNo != null ? <small>{attendanceConfirmationArray.admissionNo} / {attendanceConfirmationArray.uniRegNo}</small> : <small>{attendanceConfirmationArray.admissionNo}</small>}
                                    </div> */}
								</div>
							</div>
						))}
					</ModalBody>
					<ModalFooter>
						<Button color='info' isOutline className='border-0' onClick={closeAndReset}>
							Close
						</Button>
						<Button
							color='info'
							icon='Save'
							type='submit'
							onClick={staffAttendanceSubmit}>
							Submit
						</Button>
					</ModalFooter>
				</Modal>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
};
export default StaffAttendance;
