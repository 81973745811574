import { useFormik } from "formik";
import { useEffect, useContext, useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import DeleteComponents from "../../../../common/components/DeleteComponents";
import NoDataMsg from "../../../../common/components/NoDataMsg";
import AuthContext from "../../../../contexts/authContext";
import useDarkMode from "../../../../hooks/useDarkMode";
import useSortableData from "../../../../hooks/useSortableData";
import Page from "../../../../layout/Page/Page";
import PageWrapper from "../../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../../services/AlertService";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../../../services/ExportService";
import { getLicenseKey } from "../../../../services/application.settings";
import { getLateAttendanceReason, addStudentLateAttendanceReason, updateLateAttendanceReason, deleteLateAttendanceReason } from "../../../../services/attendance.service";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable } from "../../../../services/common.service";
import { showLoader, TableLoader } from "../../../../services/loader.services";
import { toasts } from "../../../../services/toast.service";
import PaginationButtons, { dataPagination } from "../../../PaginationButtons";
import Button, { ButtonGroup } from "../../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../../bootstrap/Dropdown";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../../bootstrap/OffCanvas";
import Checks, { ChecksGroup } from "../../../bootstrap/forms/Checks";
import FormGroup from "../../../bootstrap/forms/FormGroup";
import Icon from "../../../icon/Icon";
import Input from "../../../bootstrap/forms/Input";

const AddLateAttendanceReason = () => {

    useEffect(() => {
        getLateAttendanceReasonList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [lateAttendanceReasonData, setLateAttendanceReasonData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // For Table and offCanvas
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(lateAttendanceReasonData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addLateAttendanceReasonOffCanvas, setAddLateAttendanceReasonOffCanvas] = useState(false)
    const [editLateAttendanceReasonOffCanvas, setEditLateAttendanceReasonOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const addLateAttendanceReason = useFormik({
        enableReinitialize: true,
        initialValues: {
            reasonName: '',
        },
        validate: (values) => {
            const errors: {
                reasonName?: string;
            } = {};
            if (!values.reasonName) {
                errors.reasonName = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addLateAttendanceReasonSubmit() },
    });

    const editLateAttendanceReasonForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            reasonName: '',
            lateAttendanceReasonId: '',
        },
        validate: (values) => {
            const errors: {
                reasonName?: string;
            } = {};
            if (!values.reasonName) {
                errors.reasonName = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateLateAttendanceReasonSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getLateAttendanceReasonList() {
        setDataStatus(false)
        getLateAttendanceReason(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.lateAttendanceReason;
                    if (data != undefined) {
                        getColumnsForTable('getLateAttendanceReason', 'get');
                        setLateAttendanceReasonData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDataStatus(true)
                    getColumnsForTable('getLateAttendanceReason', 'get');
                    setLateAttendanceReasonData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function onActivate(i: any) {
        if (i != undefined) {

            let selectedDepartment = i
            setEditLateAttendanceReasonOffCanvas(true)

            editLateAttendanceReasonForm.setValues({
                lateAttendanceReasonId: selectedDepartment.lateAttendanceReasonId,
                reasonName: selectedDepartment.reasonName,
            });
        }
    }

    function setAddLateAttendanceReasonDetails() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            reasonName: addLateAttendanceReason.values.reasonName,
        })
    }

    function setEditLateAttendanceReasonDetails() {
        return ({
            lateAttendanceReasonId: editLateAttendanceReasonForm.values.lateAttendanceReasonId,
            reasonName: editLateAttendanceReasonForm.values.reasonName,
            licenseKey: getLicenseKey,
            isActive: 1,
            userAccountId: userAccountId
        })
    }

    function addLateAttendanceReasonSubmit() {
        showLoader(true);
        if (addLateAttendanceReason.isValid) {
            let attendanceReasonPostData = setAddLateAttendanceReasonDetails()
            addStudentLateAttendanceReason(attendanceReasonPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getLateAttendanceReasonList();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addLateAttendanceReason.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateLateAttendanceReasonSubmit() {
        showLoader(true);
        if (editLateAttendanceReasonForm.isValid) {
            let attendanceReasonPostData = setEditLateAttendanceReasonDetails()
            updateLateAttendanceReason(attendanceReasonPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getLateAttendanceReasonList();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editLateAttendanceReasonForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteDepartmentSubmit() {
        showLoader(true);
        // if (editLateAttendanceReasonForm.isValid) {
        let attendanceReasonPostData = setEditLateAttendanceReasonDetails()
        deleteLateAttendanceReason(attendanceReasonPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getLateAttendanceReasonList();
                    closeAndFormReset();
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (editLateAttendanceReasonForm.isValid == false) {
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getLateAttendanceReason', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndFormReset() {
        setAddLateAttendanceReasonOffCanvas(false);
        setEditLateAttendanceReasonOffCanvas(false);
        setIsDeleteOpen(false);
        addLateAttendanceReason.resetForm();
        editLateAttendanceReasonForm.resetForm();
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {/* <PageWrapper title={`Late Attendance Reason List`}>
                <Page container='fluid'> */}
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'> Late Attendance Reason List</CardTitle>
                            </CardLabel>

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getLateAttendanceReason', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("LateAttendanceReasonList", lateAttendanceReasonData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(lateAttendanceReasonData, columnVisibilityData, "LateAttendanceReasonList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("LateAttendanceReasonList", lateAttendanceReasonData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddLateAttendanceReasonOffCanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>

                            {!dataStatus ?
                                <TableLoader /> :

                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort(`sno`)}
                                                className='cursor-pointer text-decoration-underline'>S.No
                                                <Icon size='lg' className={getClassNamesFor(`sno`)} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.lateAttendanceReasonId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.lateAttendanceReasonId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none">
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
                                                        </td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                {/* </Page> */}

                <OffCanvas setOpen={setAddLateAttendanceReasonOffCanvas} isOpen={addLateAttendanceReasonOffCanvas} titleId='addLateAttendanceReasonOffCanvas' placement='end'>
                    <OffCanvasHeader setOpen={setAddLateAttendanceReasonOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="addLateAttendanceReasonOffCanvas">Late Attendance Reason Creation</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={addLateAttendanceReason.handleSubmit}>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='reasonName' label='Reason'>
                                        <Input onChange={addLateAttendanceReason.handleChange} value={addLateAttendanceReason.values.reasonName} isValid={addLateAttendanceReason.isValid} onBlur={addLateAttendanceReason.handleBlur} isTouched={addLateAttendanceReason.touched.reasonName} invalidFeedback={addLateAttendanceReason.errors.reasonName} placeholder="Enter Reason" required />
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!addLateAttendanceReason.isValid && !!addLateAttendanceReason.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </OffCanvas>

                <OffCanvas setOpen={setEditLateAttendanceReasonOffCanvas} isOpen={editLateAttendanceReasonOffCanvas} titleId='editLateAttendanceReasonOffCanvas' placement='end'>
                    <OffCanvasHeader setOpen={setEditLateAttendanceReasonOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="editLateAttendanceReasonOffCanvas">Update Late Attendance Reason</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={editLateAttendanceReasonForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='reasonName' label='Reason'>
                                        <Input onChange={editLateAttendanceReasonForm.handleChange} value={editLateAttendanceReasonForm.values.reasonName} isValid={editLateAttendanceReasonForm.isValid} onBlur={editLateAttendanceReasonForm.handleBlur} isTouched={editLateAttendanceReasonForm.touched.reasonName} invalidFeedback={editLateAttendanceReasonForm.errors.reasonName} placeholder="Enter Reason" />
                                    </FormGroup>
                                </div>
                                <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                    <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteDepartmentSubmit} />
                                </div>
                            </div>
                            <div className='row top-90 m-0'>
                                <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                    <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        Delete
                                    </Button>
                                </div>
                                <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                                    <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editLateAttendanceReasonForm.isValid && !!editLateAttendanceReasonForm.submitCount}>Update</Button>
                                </div>
                            </div>
                        </OffCanvasBody>
                    </form>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            {/* </PageWrapper> */}
        </>
    );
}
export default AddLateAttendanceReason;