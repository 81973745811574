import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getDepartmentWiseRouteList = (departmentId: any, graduationTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDepartmentWiseRouteList/${getLicenseKey}/${departmentId}/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getDepartmentWiseRouteList - " + error.message)
            console.log('error caught in service : getDepartmentWiseRouteList')
        },
    );

export const getStudentListByTransportAreaMasterIdForReport = (departmentId: any, graduationTypeId: any, transportAreaMasterId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentListByTransportAreaMasterIdForReport/${getLicenseKey}/${departmentId}/${graduationTypeId}/${transportAreaMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentListByTransportAreaMasterIdForReport - " + error.message)
            console.log('error caught in service : getStudentListByTransportAreaMasterIdForReport')
        },
    );

export const getStaffReferralReport = (academicPeriodId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffReferralReport/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffReferralReport - " + error.message)
            console.log('error caught in service : getStaffReferralReport')
        },
    );

export const getStaffReferralReportByStaffDetailsId = (academicPeriodId: any, referredId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffReferralReportByStaffDetailsId/${getLicenseKey}/${academicPeriodId}/${referredId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffReferralReportByStaffDetailsId - " + error.message)
            console.log('error caught in service : getStaffReferralReportByStaffDetailsId')
        },
    );

export const getStudentReferralReport = (academicPeriodId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentReferralReport/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentReferralReport - " + error.message)
            console.log('error caught in service : getStudentReferralReport')
        },
    );

export const getStudentReferralReportByStudentDetailsId = (academicPeriodId: any, referredId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentReferralReportByStudentDetailsId/${getLicenseKey}/${academicPeriodId}/${referredId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffReferralReportByStaffDetailsId - " + error.message)
            console.log('error caught in service : getStudentReferralReportByStudentDetailsId')
        },
    );

export const getXIIInstitutionReport = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getXIIInstitutionReport/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getXIIInstitutionReport - " + error.message)
            console.log('error caught in service : getXIIInstitutionReport')
        },
    );

export const getXIIInstitutionReportBySchoolId = (XIISchoolNameId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getXIIInstitutionReportBySchoolId/${getLicenseKey}/${XIISchoolNameId}`,
        response => {
            success(response)
        },
        error => {
            failure("getXIIInstitutionReportBySchoolId - " + error.message)
            console.log('error caught in service : getXIIInstitutionReportBySchoolId')
        },
    );

export const getAreaWiseStudentCount = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAreaWiseStudentCount/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getAreaWiseStudentCount - " + error.message)
            console.log('error caught in service : getAreaWiseStudentCount')
        },
    );

export const getStudentListByTransportAreaMasterId = (transportAreaMasterId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentListByTransportAreaMasterId/${getLicenseKey}/${transportAreaMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentListByTransportAreaMasterId - " + error.message)
            console.log('error caught in service : getStudentListByTransportAreaMasterId')
        },
    );

export const getTempStudentReport = (academicPeriodId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTempStudentReport/${getLicenseKey}/${academicPeriodId}`,
        response => {
            success(response)
        },
        error => {
            failure("getTempStudentReport - " + error.message)
            console.log('error caught in service : getTempStudentReport')
        },
    );

export const getRouteWiseTravellerList = (routeMasterId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getRouteWiseTravellerList/${getLicenseKey}/${routeMasterId}`,
        response => {
            success(response)
        },
        error => {
            failure("getRouteWiseTravellerList - " + error.message)
            console.log('error caught in service : getRouteWiseTravellerList')
        },
    );

export const getCategoryWiseStudentList = (courseId: any, batchMasterId: any, semesterDetailsId: any, category: any, religion: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCategoryWiseStudentList/${getLicenseKey}/${courseId}/${batchMasterId}/${semesterDetailsId}/${category}/${religion}`,
        response => {
            success(response)
        },
        error => {
            failure("getCategoryWiseStudentList - " + error.message)
            console.log('error caught in service : getCategoryWiseStudentList')
        },
    );

export const getOverAllStrengthReport = (academicPeriodId: any, graduationTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getOverAllStrengthReport/${getLicenseKey}/${academicPeriodId}/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getOverAllStrengthReport - " + error.message)
            console.log('error caught in service : getOverAllStrengthReport')
        },
    );

export const getStudentFeeDetailsByFeeTypeId = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentFeeDetailsByFeeTypeId/${getLicenseKey}/5`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentFeeDetailsByFeeTypeId - " + error.message)
            console.log('error caught in service : getStudentFeeDetailsByFeeTypeId')
        },
    );

export const getStudentScholarshipReport = (batchMasterId: any, scholarshipTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentScholarshipReport/${getLicenseKey}/${batchMasterId}/${scholarshipTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentScholarshipReport - " + error.message)
            console.log('error caught in service : getStudentScholarshipReport')
        },
    );

export const getStudentOriginalCertificateReport = (courseId: any, semesterDetailsId: any, sectionDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentOriginalCertificateReport/${getLicenseKey}/${courseId}/${semesterDetailsId}/${sectionDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentOriginalCertificateReport - " + error.message)
            console.log('error caught in service : getStudentOriginalCertificateReport')
        },
    );

export const getFeesCollectionListByDate = (fromDate: any, toDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getFeesCollectionListByDate/${getLicenseKey}/${fromDate}/${toDate}`,
        response => {
            success(response)
        },
        error => {
            failure("getFeesCollectionListByDate - " + error.message)
            console.log('error caught in service : getFeesCollectionListByDate')
        },
    );

export const getFeeCollectionReportByPaymentType = (userAccountId: number, fromDate: any, toDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getFeeCollectionReportByPaymentType/${getLicenseKey}/${userAccountId}/${fromDate}/${toDate}`,
        response => {
            success(response)
        },
        error => {
            failure("getFeeCollectionReportByPaymentType - " + error.message)
            console.log('error caught in service : getFeeCollectionReportByPaymentType')
        },
    );

export const getDailyFeeCollectionReport = (fromDate: any, toDate: any, feeTypeId: any, isDeleted: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDailyFeeCollectionReport/${getLicenseKey}/${fromDate}/${toDate}/${feeTypeId}/${isDeleted}`,
        response => {
            success(response)
        },
        error => {
            failure("getDailyFeeCollectionReport - " + error.message)
            console.log('error caught in service : getDailyFeeCollectionReport')
        },
    );

export const getDailyFeeCollectionDeletedReport = (fromDate: any, toDate: any, feeTypeId: any, isDeleted: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getDailyFeeCollectionReport/${getLicenseKey}/${fromDate}/${toDate}/${feeTypeId}/${isDeleted}`,
        response => {
            success(response)
        },
        error => {
            failure("getDailyFeeCollectionReport - " + error.message)
            console.log('error caught in service : getDailyFeeCollectionReport')
        },
    );

export const getStudentPendingFeeReport = (courseId: number, batchMasterId: number, semesterDetailsId: number, feeTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentPendingFeeReport/${getLicenseKey}/${courseId}/${batchMasterId}/${semesterDetailsId}/${feeTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentPendingFeeReport - " + error.message)
            console.log('error caught in service : getStudentPendingFeeReport')
        },
    );

export const getOverAllPendingFees = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getOverAllPendingFees/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getOverAllPendingFees - " + error.message)
            console.log('error caught in service : getOverAllPendingFees')
        },
    );

export const getNotSubmittedStudentAttendance = (dateInMilliSec: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getNotSubmittedStudentAttendanceReport/${getLicenseKey}/${dateInMilliSec}`,
        response => {
            success(response)
        },
        error => {
            failure("getNotSubmittedStudentAttendanceReport - " + error.message)
            console.log('error caught in service : getNotSubmittedStudentAttendanceReport')
        },
    );

export const getTodayAbsenteesListReport = (dateInMilliSec: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getTodayAbsenteesList/${getLicenseKey}/${dateInMilliSec}`,
        response => {
            success(response)
        },
        error => {
            failure("getTodayAbsenteesList - " + error.message)
            console.log('error caught in service : getTodayAbsenteesList')
        },
    );

export const getAttendanceReport = (fromDate: number, toDate: number, departmentId: number, monthId: number, reportType: string, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAttendanceReport/${getLicenseKey}/${fromDate}/${toDate}/${departmentId}/${monthId}/${reportType}`,
        response => {
            success(response)
        },
        error => {
            failure("getAttendanceReport - " + error.message)
            console.log('error caught in service : getAttendanceReport')
        },
    );

export const getDepartmentWiseLateAttendanceReport = (fromDate: number, toDate: number, departmentId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLateAttendanceReport/${getLicenseKey}/${fromDate}/${toDate}/${departmentId}`,
        response => {
            success(response)
        },
        error => {
            failure("getLateAttendanceReport - " + error.message)
            console.log('error caught in service : getLateAttendanceReport')
        },
    );


export const getMonthWiseLateAttendanceReport = (monthId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLateAttendanceReport/${getLicenseKey}/${0}/${0}/${0}/${monthId}`,
        response => {
            success(response)
        },
        error => {
            failure("getLateAttendanceReport - " + error.message)
            console.log('error caught in service : getLateAttendanceReport')
        },
    );


export const getYearWiseLateAttendanceReport = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLateAttendanceReport/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLateAttendanceReport - " + error.message)
            console.log('error caught in service : getLateAttendanceReport')
        },
    );
export const getCcAvenueReport = (userAccountId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCcAvenueReport/${getLicenseKey}/${userAccountId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCcAvenueReport - " + error.message)
            console.log('error caught in service : getCcAvenueReport')
        },
    );

export const getCourseCapacityReport = (academicPeriodId: any, graduationTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCourseCapacityReport/${getLicenseKey}/${academicPeriodId}/${graduationTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getCourseCapacityReport - " + error.message)
            console.log('error caught in service : getCourseCapacityReport')
        },
    );

export const getStudentSchoolWiseReportByYear = (graduationTypeId: any, year: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentSchoolWiseReportByYear/${getLicenseKey}/${graduationTypeId}/${year}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentSchoolWiseReportByYear - " + error.message)
            console.log('error caught in service : getStudentSchoolWiseReportByYear')
        },
    );

    export const getRecentTransactionSummaryReport = (feeCollectionDate: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getRecentTransactionSummaryReport/${getLicenseKey}/${feeCollectionDate}`,
            response => {
                success(response)
            },
            error => {
                failure("getRecentTransactionSummaryReport - " + error.message)
                console.log('error caught in service : getRecentTransactionSummaryReport')
            },
        );



