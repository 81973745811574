import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import { toasts } from "../../../services/toast.service";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardFooter, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import AlertService from "../../../services/AlertService";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { getAcademicPeriod, getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../services/master.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { getColumnsForDataTable, getGraduationType, getSectionDetails, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import { showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";
import {  addExamHallAllocationDetails, deleteExamHallAllocation, getExamDetails, getExamHall, getExamHallAllocation, updateExamHallAllocation } from "../../../services/exam.service";
import OpenCardComponent from "../../../common/components/OpenCardComponent";
import Collapse from "../../bootstrap/Collapse";
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from "../../bootstrap/Modal";
import { getStaffListByUserTypeId } from "../../../services/staff.service";
import Select from "../../bootstrap/forms/Select";
import { getStudentDetails } from "../../../services/student.service";

function ExamHallAllocation() {

    useEffect(() => {
        getAcademicPeriodList()
        getDepartment();
        getGraduationList();
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [examHallAllocationData, setExamHallAllocationData] = useState<any>([])
    const [examDetailsData, setExamDetailsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)

    const [academicPeriodId, setAcademicPeriodId] = useState<any>('')
    const [academicPeriodData, setAcademicPeriodData] = useState<any>([])
    const [examTypeId, setExamTypeId] = useState<any>('');
    const [examTypeData, setExamTypeData] = useState<any>([]);

    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(examHallAllocationData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addExamDetailsOffCanvas, setAddExamDetailsOffCanvas] = useState(false)
    const [viewExamDetailsOffCanvas, setViewExamDetailsOffCanvas] = useState(false)
    const [editExamDetailsOffCanvas, setEditExamDetailsOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const [examHallId, setExamHallId] = useState<any>('')
    const [examDetailsId, setExamDetailsId] = useState<any>('')

    const [examHallData, setExamHallData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [sectionData, setSectionData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])

    const [addExamHallAllocation, setAddExamHallAllocation] = useState<any>([]);
    const [examHallAllocation, setExamHallAllocation] = useState<any>([])

    const [courseId, setCourseId] = useState<any>('')
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>('')
    const [courseByBatchMasterData, setCourseByBatchMasterData] = useState<any>([]);
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([]);

    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>('')
    const [fromRollNo, setFromRollNo] = useState<any>('')
    const [toRollNo, setToRollNo] = useState<any>('')
    const [supervisor, setSupervisor] = useState<any>('')
    const [departmentId, setDepartmentId] = useState<any>('')
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')
    const [departmentData, setDepartmentData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const examHallAllocationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            session: '',
            dateOfExam: '',
        },
        validate: (values) => {
            const errors: {
                session?: string;
                dateOfExam?: string;
            } = {};
            if (!values.session) {
                errors.session = 'Required';
            }
            if (!values.dateOfExam) {
                errors.dateOfExam = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addExamHallAllocationSubmit() },
    });

    const examHallAllocationEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            startTime: '',
            endTime: '',
            totalNoOfStudents: '',
            batchMasterId: '',
            courseId: '',
            semesterDetailsId: '',
            //sectionDetailsId: '',
            fromRollNo: '',
            toRollNo: '',
            supervisor: '',
        },
        validate: (values) => {
            const errors: {
                startTime?: string;
                endTime?: string;
                totalNoOfStudents?: string;
                batchMasterId?: string;
                courseId?: string;
                semesterDetailsId?: string;
                //sectionDetailsId?: string;
                fromRollNo?: string;
                toRollNo?: string;
                supervisor?: string;
            } = {};
            if (!values.startTime) {
                errors.startTime = 'Required';
            }
            if (!values.endTime) {
                errors.endTime = 'Required';
            }
            if (!values.totalNoOfStudents) {
                errors.totalNoOfStudents = 'Required';
            }
            if (!batchMasterId?.value) {
                errors.batchMasterId = 'Required';
            }
            if (!courseId) {
                errors.courseId = 'Required';
            }
            if (!semesterDetailsId) {
                errors.semesterDetailsId = 'Required';
            }
            // if (!sectionDetailsId?.value) {
            //     errors.sectionDetailsId = 'Required';
            // }
            if (!fromRollNo?.value) {
                errors.fromRollNo = 'Required';
            }
            if (!toRollNo?.value) {
                errors.toRollNo = 'Required';
            }
            if (!supervisor?.value) {
                errors.supervisor = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateExamHallAllocationSubmit() },
    });

    const selectAcademicPeriod = (e: any) => {
        setExamHallAllocationData([])
        setDataSuccess(false)
        setExamDetailsId('')
        setExamHallId('')
        let academicPeriodId = e
        setAcademicPeriodId(academicPeriodId)
        if (academicPeriodId?.value != undefined) {
            getExamDetailsList(academicPeriodId?.value);
        }
    }

    const selectExamType = (e: any) => {
        setExamHallAllocationData([])
        setDataSuccess(false)
        setExamHallId('')
        let examDetailsId = e
        setExamDetailsId(examDetailsId)
        getExamHallList()
    }

    const selectExamHall = (e: any) => {
        setExamHallAllocationData([])
        setDataSuccess(false)
        let examHallId = e
        setExamHallId(examHallId)
    }

    const selectDepartment = (e: any) => {
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            console.log(departmentId);

            getCourseList(departmentId?.value, graduationTypeId?.value, [])
        }
    }

    const selectCourse = (e: any) => {
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value, [])
        }
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
        // if (semesterDetailsId?.value != undefined) {
        //     getSectionList([])
        // }
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectFromRollNo = (e: any) => {
        let fromRollNo = e
        setFromRollNo(fromRollNo)
    }

    const selectToRollNo = (e: any) => {
        let toRollNo = e
        setToRollNo(toRollNo)
        getStaffDetails([])
    }

    const selectSupervisor = (e: any) => {
        let supervisor = e
        setSupervisor(supervisor)
    }

    function getAcademicPeriodList() {
        getAcademicPeriod(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.academicPeriod;
                    if (data != undefined) {
                        setAcademicPeriodData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setAcademicPeriodData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExamDetailsList(academicPeriodId: any) {
        getExamDetails(academicPeriodId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examDetails;
                    if (data != undefined) {
                        setExamTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setExamTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getExamHallList() {
        getExamHall(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examHall;
                    if (data != undefined) {
                        setExamHallData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setExamHallData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any, item: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseByBatchMasterData(data);
                        item['courseData'] = data
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseByBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: any, item: any) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                        item['semesterData'] = data
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList(item: any) {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionData(data);
                        item['sectionData'] = data
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentList(departmentId: any, courseId: any, batchMasterId: any, semesterDetailsId: any, sectionDetailsId: any, item: any) {
        getStudentDetails(departmentId, courseId, batchMasterId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setStudentDetailsData(data);
                        item['studentDetailsData'] = data
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                    setStudentDetailsData([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const [staffDetailsData, setStaffDetailsData] = useState<any>([])

    function getStaffDetails(item: any) {
        getStaffListByUserTypeId( 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                        item['staffDetailsData'] = data
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStaffDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getExamHallAllocationList(examDetailsId: any, examHallId: any) {
        setIsLoader(true);
        getExamHallAllocation(examDetailsId, examHallId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.examHallAllocation;
                    if (data != undefined) {
                        setIsLoader(false);
                        setExamHallAllocationData(data);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        getColumnsForTable('getExamHallAllocation', 'get');
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getExamHallAllocation', 'get');
                    setExamHallAllocationData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function viewExamList() {
        setIsLoader(true);
        setDataSuccess(false);
        setExamDetailsData([]);
        getExamHallAllocationList(examDetailsId?.value, examHallId?.value)
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getExamHallAllocation', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function addExamHallAllocationModal() {

        setAddExamDetailsOffCanvas(true)

        setAddExamHallAllocation([{departmentId:'',graduationTypeId:'', batchMasterId: '', courseId: '', semesterDetailsId: '', 
            //sectionDetailsId: '', 
            fromRollNo: '', toRollNo: '', totalNoOfStudents: '', dateOfExam: '', startTime: '', endTime: '', supervisor: '', displayOrder: 1 }])
    }

    const [count, setCount] = useState(1)

    function add() {
        for (let i = 0; i < count; i++) {
            setCount(count + 1)
        }
        setAddExamHallAllocation([...addExamHallAllocation, { departmentId: '', graduationTypeId: '', batchMasterId: '', courseId: '', semesterDetailsId: '', 
            //sectionDetailsId: '', 
            fromRollNo: '', toRollNo: '', totalNoOfStudents: '', dateOfExam: '', startTime: '', endTime: '', supervisor: '', displayOrder: count + 1 }])
    }

    const [isDuplicateEntry, setIsDuplicateEntry] = useState<any>([])
    const [isCheck, setIsCheck] = useState<any>([])

    const addExamHallAllocationData = (department: any, graduation: any, batch: any, course: any, semester: any, 
        //section: any, 
        fRollNo: any, tRollNo: any, startTime: any, endTime: any, staff: any, dateOfExam: any, totalNoOfStudents: any, displayOrder: any) => {

        let departmentId = department?.value;
        let graduationTypeId = graduation?.value;
        let batchMasterId = batch?.value;
        let courseId = course?.value;
        let semesterDetailsId = semester?.value;
        //let sectionDetailsId = section?.value;
        let fromRollNo = fRollNo?.value;
        let toRollNo = tRollNo?.value;
        let supervisor = staff?.value;

        let existingData = examHallAllocation.filter((data: any) => ((data.fromRollNo == fromRollNo || data.toRollNo == toRollNo) || (data.fromRollNo == toRollNo || data.toRollNo == fromRollNo)) && data.displayOrder != displayOrder && data.fromRollNo != undefined && data.toRollNo != undefined)
        setIsDuplicateEntry(existingData)

        const newData: any = [];

        addExamHallAllocation.forEach((item: any, index: any) => {
            if (item['displayOrder'] == displayOrder) {
                if (graduationTypeId != undefined) {
                    getCourseList(departmentId, graduationTypeId, item)
                }
                if (courseId != undefined) {
                    getBatchList(courseId, graduationTypeId)
                }
                if (batchMasterId != undefined) {
                    getSemesterList(batchMasterId, item)
                }
                // if (semesterDetailsId != undefined) {
                //     getSectionList(item)
                // }
                if (departmentId != undefined&&courseId != undefined&&batchMasterId != undefined&&semesterDetailsId != undefined) {
                    //getStudentListBySectionId(batchMasterId, sectionDetailsId, item)
                    getStudentList(departmentId, courseId, batchMasterId, semesterDetailsId, 0, item)
                }
                if (toRollNo != undefined) {
                    getStaffDetails(item)
                }
                item['displayOrder'] = displayOrder
                item['departmentId'] = departmentId
                item['departmentIdArray'] = department
                item['graduationTypeId'] = graduationTypeId
                item['graduationTypeIdArray'] = graduation
                item['batchMasterId'] = batchMasterId
                item['batchMasterIdArray'] = batch
                item['courseId'] = courseId
                item['courseIdArray'] = course
                item['semesterDetailsId'] = semesterDetailsId
                item['semesterDetailsIdArray'] = semester
               // item['sectionDetailsId'] = sectionDetailsId
               // item['sectionDetailsIdArray'] = section
                item['fromRollNo'] = fromRollNo
                item['fromRollNoArray'] = fRollNo
                item['toRollNo'] = toRollNo
                item['toRollNoArray'] = tRollNo
                item['dateOfExam'] = dateOfExam
                item['startTime'] = startTime
                item['endTime'] = endTime
                item['supervisor'] = supervisor
                item['supervisorArray'] = staff
                item['totalNoOfStudents'] = totalNoOfStudents
                item['existingData'] = existingData != '' ? true : false
            }
            newData.push(item)
        });
        setAddExamHallAllocation(newData)

        if (examHallAllocation.length == 0 && departmentId != undefined && graduationTypeId != undefined && batchMasterId != undefined && courseId != undefined && semesterDetailsId != undefined 
            //&& sectionDetailsId != undefined 
            && fromRollNo != undefined && toRollNo != undefined && supervisor != undefined && totalNoOfStudents != '' && startTime != '' && endTime != '') {
            const obj = {
                //displayOrder: displayOrder,
                //departmentId: departmentId,
                //departmentIdArray: department,
                //graduationTypeId: graduationTypeId,
                //graduationTypeIdArray: graduation,
                batchMasterId: batchMasterId,
                //batchMasterIdArray: batch,
                courseId: courseId,
                //courseIdArray: course,
                semesterDetailsId: semesterDetailsId,
                //semesterDetailsIdArray: semester,
               // sectionDetailsId: sectionDetailsId,
                //sectionDetailsIdArray: section,
                fromRollNo: fromRollNo,
                //fromRollNoArray: fRollNo,
                toRollNo: toRollNo,
                //toRollNoArray: tRollNo,
                dateOfExam: dateOfExam,
                startTime: startTime,
                endTime: endTime,
                supervisor: supervisor,
                //supervisorArray: staff,
                totalNoOfStudents: totalNoOfStudents
            };
            examHallAllocation.push(obj);
        } else {
            let selectedItem: any;
            examHallAllocation.forEach((item: any, index: any) => {
                if (item['displayOrder'] == displayOrder) {
                    selectedItem = item
                    selectedItem['departmentId'] = departmentId
                    selectedItem['departmentIdArray'] = department
                    selectedItem['graduationTypeId'] = graduationTypeId
                    selectedItem['graduationTypeIdArray'] = graduation
                    selectedItem['batchMasterId'] = batchMasterId,
                        selectedItem['batchMasterIdArray'] = batch,
                        selectedItem['courseId'] = courseId,
                        selectedItem['courseIdArray'] = course,
                        selectedItem['semesterDetailsId'] = semesterDetailsId,
                        selectedItem['semesterDetailsIdArray'] = semester,
                        //selectedItem['sectionDetailsId'] = sectionDetailsId,
                        //selectedItem['sectionDetailsIdArray'] = section,
                        selectedItem['fromRollNo'] = fromRollNo,
                        selectedItem['fromRollNoArray'] = fRollNo,
                        selectedItem['toRollNo'] = toRollNo,
                        selectedItem['toRollNoArray'] = tRollNo,
                        selectedItem['dateOfExam'] = dateOfExam,
                        selectedItem['startTime'] = startTime,
                        selectedItem['endTime'] = endTime,
                        selectedItem['supervisor'] = supervisor,
                        selectedItem['supervisorArray'] = staff,
                        selectedItem['totalNoOfStudents'] = totalNoOfStudents
                }
            });
            if (selectedItem != undefined) {
                selectedItem['displayOrder'] = displayOrder,
                    selectedItem['departmentId'] = departmentId
                selectedItem['departmentIdArray'] = department
                selectedItem['graduationTypeId'] = graduationTypeId
                selectedItem['graduationTypeIdArray'] = graduation
                selectedItem['batchMasterId'] = batchMasterId,
                    selectedItem['batchMasterIdArray'] = batch,
                    selectedItem['courseId'] = courseId,
                    selectedItem['courseIdArray'] = course,
                    selectedItem['semesterDetailsId'] = semesterDetailsId,
                    selectedItem['semesterDetailsIdArray'] = semester,
                    //selectedItem['sectionDetailsId'] = sectionDetailsId,
                    //selectedItem['sectionDetailsIdArray'] = section,
                    selectedItem['fromRollNo'] = fromRollNo,
                    selectedItem['fromRollNoArray'] = fRollNo,
                    selectedItem['toRollNo'] = toRollNo,
                    selectedItem['toRollNoArray'] = tRollNo,
                    selectedItem['dateOfExam'] = dateOfExam,
                    selectedItem['startTime'] = startTime,
                    selectedItem['endTime'] = endTime,
                    selectedItem['supervisor'] = supervisor,
                    selectedItem['supervisorArray'] = staff,
                    selectedItem['totalNoOfStudents'] = totalNoOfStudents
            }
            else if (departmentId != undefined && graduationTypeId != undefined && batchMasterId != undefined && courseId != undefined && semesterDetailsId != undefined && 
                //sectionDetailsId != undefined && 
                fromRollNo != undefined && toRollNo != undefined && supervisor != undefined && totalNoOfStudents != '' && startTime != '' && endTime != '') {
                var obj
                obj = {
                    //displayOrder: displayOrder,
                    //departmentId: departmentId,
                    //departmentIdArray: department,
                    //graduationTypeId: graduationTypeId,
                    //graduationTypeIdArray: graduation,
                    batchMasterId: batchMasterId,
                    //batchMasterIdArray: batch,
                    courseId: courseId,
                    //courseIdArray: course,
                    semesterDetailsId: semesterDetailsId,
                    //semesterDetailsIdArray: semester,
                    //sectionDetailsId: sectionDetailsId,
                    //sectionDetailsIdArray: section,
                    fromRollNo: fromRollNo,
                    //fromRollNoArray: fRollNo,
                    toRollNo: toRollNo,
                    //toRollNoArray: tRollNo,
                    dateOfExam: dateOfExam,
                    startTime: startTime,
                    endTime: endTime,
                    supervisor: supervisor,
                    //supervisorArray: staff,
                    totalNoOfStudents: totalNoOfStudents
                };
                examHallAllocation.push(obj);
            }
        }

        let emptyData = examHallAllocation.filter((item: any) => item.departmentId == undefined || item.graduationTypeId == undefined || item.batchMasterId == undefined || item.courseId == undefined || item.semesterDetailsId == undefined || 
        //item.sectionDetailsId == undefined || 
        item.fromRollNo == undefined || item.toRollNo == undefined || item.startTime == '' || item.endTime == '' && item.supervisor == undefined || item.totalNoOfStudents == '')

        console.log(examHallAllocation);
        console.log(emptyData);


        setIsCheck(emptyData)

    }

    function handleRemove(displayOrder: any, index: any) {

        const list = [...addExamHallAllocation];
        list.splice(index, 1)
        setAddExamHallAllocation(list)

        let selectedItem
        examHallAllocation.forEach((item: any) => {
            if (item['displayOrder'] == displayOrder) {
                selectedItem = item
                examHallAllocation.splice(examHallAllocation.indexOf(selectedItem), 1);
            }
        });
    }

    function setExamHallAllocationDetails() {
        return ({
            examDetailsId: examDetailsId?.value,
            examHallDetailsId: examHallId?.value,
            session: examHallAllocationForm.values.session,
            dateOfExam: examHallAllocationForm.values.dateOfExam,
            hallAllocationDetails: examHallAllocation,
            staffDetailsId: userAccountId,
            licenseKey: getLicenseKey,
        })
    }

    function addExamHallAllocationSubmit() {
        showLoader(true);
        if (examHallAllocation.length != 0) {
            let examHallAllocationPostData = setExamHallAllocationDetails();
            addExamHallAllocationDetails(examHallAllocationPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getExamHallAllocationList(examDetailsId?.value, examHallId?.value)
                        closeAndReset()
                        setAddExamDetailsOffCanvas(false)
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if(examHallAllocation.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    const [hallAllocationDetails, setHallAllocationDetails] = useState<any>([])
    const [examHallAllocationId, setExamHallAllocationId] = useState<any>('')
    const [hallAllocationData, setHallAllocationData] = useState<any>([])

    function onView(i: any) {
        setViewExamDetailsOffCanvas(true)
        setHallAllocationData(i)
        setHallAllocationDetails([i])
    }

    function onActivate(i: any) {
        setEditExamDetailsOffCanvas(true)
        if (i != undefined) {

            // getCourseByBatchMaster(i.batchMasterId, [])
            // getSemesterByBatchMaster(i.batchMasterId, [])
            getCourseList(i.departmentId, i.graduationTypeId, [])
            getBatchList(i.courseId, i.graduationTypeId);
            getSemesterList(i.batchMasterId, [])

            //getSectionList([])
            //getStudentListBySectionId(i.batchMasterId, i.sectionDetailsId, [])
            getStudentList(i.departmentId, i.courseId, i.batchMasterId, i.semesterDetailsId, 0, [])
            getStaffDetails([])

            setDepartmentId({ value: i.departmentId, label: i.departmentName })
            setGraduationTypeId({ value: i.graduationTypeId, label: i.graduationType })
            setBatchMasterId({ value: i.batchMasterId, label: i.batchName })
            setCourseId({ value: i.courseId, label: i.courseName })
            setSemesterDetailsId({ value: i.semesterDetailsId, label: i.semesterNumber })
            //setSectionDetailsId({ value: i.sectionDetailsId, label: i.sectionName })
            setFromRollNo({ value: i.fromRollNo, label: i.fromRollNo })
            setToRollNo({ value: i.toRollNo, label: i.toRollNo })
            setSupervisor({ value: i.supervisorId, label: i.supervisorName })
            setExamHallAllocationId(i.examHallAllocationId)
            examHallAllocationEditForm.setValues({
                startTime: i.startTime,
                endTime: i.endTime,
                totalNoOfStudents: i.totalNoOfStudents,
                batchMasterId: i.batchMasterId,
                courseId: i.courseId,
                semesterDetailsId: i.semesterDetailsId,
               // sectionDetailsId: i.sectionDetailsId,
                fromRollNo: i.fromRollNo,
                toRollNo: i.toRollNo,
                supervisor: i.supervisorId,
            })
        }
    }

    function setUpdateExamHallAllocationDetails() {
        return ({
            staffDetailsId: userAccountId,
            licenseKey: getLicenseKey,
            examHallAllocationId: examHallAllocationId,
            examDetailsId: examDetailsId?.value,
            examHallDetailsId: examHallId?.value,
            batchMasterId: batchMasterId?.value,
            courseId:courseId?.value,
            semesterDetailsId: semesterDetailsId?.value,
            //sectionDetailsId: sectionDetailsId?.value,
            fromRollNo: fromRollNo?.value,
            toRollNo: toRollNo?.value,
            totalNoOfStudents: examHallAllocationEditForm.values.totalNoOfStudents,
            startTime: examHallAllocationEditForm.values.startTime,
            endTime: examHallAllocationEditForm.values.endTime,
            supervisor: supervisor?.value
        })
    }

    function updateExamHallAllocationSubmit() {
        showLoader(true);
        if (addExamHallAllocation.length == examHallAllocation.length) {
            let examHallAllocationPostData = setUpdateExamHallAllocationDetails();
            updateExamHallAllocation(examHallAllocationPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        //getExamHallAllocationList(examDetailsId?.value, examHallId?.value)
                        viewExamList();
                        closeAndReset()
                        setViewExamDetailsOffCanvas(false)
                        setEditExamDetailsOffCanvas(false)
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addExamHallAllocation.length != examHallAllocation.length) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteExamHallAllocationSubmit() {
        showLoader(true);
        if (addExamHallAllocation.length == examHallAllocation.length) {
            let examHallAllocationPostData = setUpdateExamHallAllocationDetails();
            deleteExamHallAllocation(examHallAllocationPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getExamHallAllocationList(examDetailsId?.value, examHallId?.value)
                        closeAndReset()
                        setViewExamDetailsOffCanvas(false)
                        setEditExamDetailsOffCanvas(false)
                        setIsDeleteOpen(false)
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addExamHallAllocation.length != examHallAllocation.length) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteModal(i: any) {
        setIsDeleteOpen(true)
        setExamHallAllocationId(i.examHallAllocationId)
    }

    function closeAndReset() {
        examHallAllocationForm.resetForm()
        examHallAllocationEditForm.resetForm()
        setExamTypeId('');
        setExamHallAllocation([])
        setAddExamHallAllocation([])
        setGraduationTypeId('')
        setDepartmentId('')
        setBatchMasterId('')
        setCourseId('')
        setSemesterDetailsId('')
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Exam List`}>
                <Page container='fluid'>
                    <Collapse isOpen={isOpenListCard}>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>
                                        Exam Hall Allocation
                                    </CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <div className="row g-4">
                                    <div className='col-md-3'>
                                        <FormGroup id='academicPeriodId' label='Academic Period' isFloating>
                                            <SearchableSelect isFloating ariaLabel='Academic Period' placeholder="Academic Period"
                                                value={academicPeriodId}
                                                onChange={selectAcademicPeriod}
                                                list={academicPeriodData.map((data: any) => (
                                                    { value: data.academicPeriodId, label: data.period }
                                                ))} required />
                                        </FormGroup>
                                    </div>

                                    {academicPeriodId?.value != undefined ?
                                        <div className='col-3'>
                                            <FormGroup id='examDetailsId' label='Exam Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Exam Type' placeholder="Select Exam Type"
                                                    onChange={selectExamType}
                                                    value={examDetailsId}
                                                    list={examTypeData.map((data: any) => ({ value: data.examDetailsId, label: data.examTypeName }))}
                                                    required
                                                />
                                            </FormGroup>
                                        </div> : null
                                    }

                                    {examDetailsId?.value != undefined ?
                                        <div className='col-3'>
                                            <FormGroup id='examHallId' label='Exam Hall' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Exam Hall' placeholder="Select Exam Hall"
                                                    onChange={selectExamHall}
                                                    value={examHallId}
                                                    list={examHallData.map((data: any) => ({ value: data.examHallId, label: data.hallName }))}
                                                    required
                                                />
                                            </FormGroup>
                                        </div> : null
                                    }

                                    {examHallId?.value != undefined ?
                                        <div className='col-md-2'>
                                            <Button className="mt-2" icon='ArrowDownward' color='primary' onClick={viewExamList}>View</Button>
                                        </div>
                                        : null}
                                </div>
                            </CardBody>
                        </Card>
                    </Collapse>

                    <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                    {dataSuccess ?
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                    <CardTitle tag='div' className='h5'>Exam Hall Allocation List</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getExamHallAllocation', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("ExamHallAllocationList", examHallAllocationData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(examHallAllocationData, columnVisibilityData, "ExamHallAllocationList")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("ExamHallAllocationList", examHallAllocationData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button color='primary' icon='Add' isLight onClick={addExamHallAllocationModal}>
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>

                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.examHallDetailsId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.examHallDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none" >
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => onView(rowData)} />
                                                        </td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons
                                className="d-print-none"
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            />
                        </Card>
                        : null
                    }
                </Page>

                <Modal
                    setIsOpen={() => { setAddExamDetailsOffCanvas(false); closeAndReset }}
                    isOpen={addExamDetailsOffCanvas}
                    titleId='addExamDetails'
                    isStaticBackdrop
                    size='xl'>
                    <ModalHeader className="'modal-header'"
                        setIsOpen={() => { setAddExamDetailsOffCanvas(false); closeAndReset }}>
                        <ModalTitle id='addExamDetails'>Exam Hall Allocation</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row px-4 g-4'>
                            <div className='col-xl-12'>
                                <div className='row px-4'>
                                    <div className='col-2'></div>
                                    <div className='col-4'>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon icon='MenuBook' size='3x' color='info' />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-5 mb-0'>{examDetailsId?.label}</div>
                                                        <div className='text-muted'>Exam Type</div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-4'>
                                        <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-info bg-l${darkModeStatus ? 'o50' : '10'
                                            }-info-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <CardBody>
                                                <div className='d-flex align-items-center'>
                                                    <div className='flex-shrink-0'>
                                                        <Icon icon='HolidayVillage' size='3x' color='info' />
                                                    </div>
                                                    <div className='flex-grow-1 ms-3'>
                                                        <div className='fw-bold fs-5 mb-0'>{examHallId?.label}</div>
                                                        <div className='text-muted'>Hall Name</div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                </div>
                            </div>
                            <div className='col-3 ms-4'>
                                <FormGroup id='dateOfExam' label='Exam Date' isFloating>
                                    <Input
                                        type='date'
                                        onChange={examHallAllocationForm.handleChange}
                                        onBlur={examHallAllocationForm.handleBlur}
                                        isValid={examHallAllocationForm.isValid}
                                        isTouched={examHallAllocationForm.touched.dateOfExam}
                                        invalidFeedback={examHallAllocationForm.errors.dateOfExam}
                                        value={examHallAllocationForm.values.dateOfExam}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='session' label='Session' isFloating>
                                    <Select ariaLabel=""
                                        placeholder="Select Session"
                                        onChange={examHallAllocationForm.handleChange}
                                        onBlur={examHallAllocationForm.handleBlur}
                                        isValid={examHallAllocationForm.isValid}
                                        isTouched={examHallAllocationForm.touched.session}
                                        invalidFeedback={examHallAllocationForm.errors.session}
                                        value={examHallAllocationForm.values.session}>
                                        <option value={1}>Morning</option>
                                        <option value={2}>Afternoon</option>
                                    </Select>
                                </FormGroup>
                            </div>

                            {addExamHallAllocation.map((i: any, index: any) => (
                                <div className='col-xl-12' key={index}>
                                    <Card stretch shadow={'md'} className="border border-light">
                                        <CardBody>
                                            <div className='row g-4'>
                                                <div className='col-3'>
                                                    <FormGroup id='departmentId' label='Department' isFloating>
                                                        <SearchableSelect isFloating
                                                            className={examHallAllocation.length > index && i.departmentIdArray == undefined ? "border border-danger" : ''} ariaLabel='Department'
                                                            onChange={(e: any) => addExamHallAllocationData(e, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            value={i.departmentIdArray}
                                                            list={departmentData.map((data: any) => (
                                                                { value: data.departmentId, label: data.departmentName }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-3'>
                                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                        <SearchableSelect isFloating className={examHallAllocation.length > index && i.graduationTypeIdArray == undefined ? "border border-danger" : ''} ariaLabel='Graduation Type'
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, e, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            value={i.graduationTypeIdArray} list={graduationTypeData.map((data: any) => (
                                                                { value: data.graduationTypeId, label: data.graduationType }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-3'>
                                                    <FormGroup id='courseId' label='Course' isFloating>
                                                        <SearchableSelect isFloating className={examHallAllocation.length > index && i.courseIdArray == undefined ? "border border-danger" : ''}
                                                            ariaLabel='Course'
                                                            value={i.courseIdArray}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, e, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            list={courseByBatchMasterData.map((data: any) => (
                                                                { value: data.courseId, label: data.courseName }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-3'>
                                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Batch' placeholder="Select Batch"
                                                            className={examHallAllocation.length > index && i.batchMasterIdArray == undefined ? "border border-danger" : ''}
                                                            value={i.batchMasterIdArray}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, e, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            list={batchMasterData.map((data: any) => (
                                                                { value: data.batchMasterId, label: data.batchName }
                                                            ))} required />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-3'>
                                                    <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                        <SearchableSelect isFloating placeholder='Select Semester' ariaLabel='Semester'
                                                            className={examHallAllocation.length > index && i.semesterDetailsIdArray == undefined ? "border border-danger" : ''}
                                                            value={i.semesterDetailsIdArray}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, e, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            list={semesterDetailsData.map((data: any) => (
                                                                { value: data.semesterDetailsId, label: data.semesterNumber }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                {/* <div className='col-3'>
                                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Section' placeholder="Select Section"
                                                            className={examHallAllocation.length > index && i.sectionDetailsIdArray == undefined ? "border border-danger" : ''}
                                                            value={i.sectionDetailsIdArray}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, e, i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            list={i.sectionData != undefined ? i.sectionData.map((data: any) => (
                                                                { value: data.sectionDetailsId, label: data.sectionName }
                                                            )) : []} required />
                                                    </FormGroup>
                                                </div> */}

                                                <div className='col-3'>
                                                    <FormGroup id='fromRollNo' label='From Roll No' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='From Roll No' placeholder="Select From Roll No"
                                                            className={examHallAllocation.length > index && i.fromRollNoArray == undefined ? "border border-danger" : ''}
                                                            value={i.fromRollNoArray}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                e, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            list={i.studentDetailsData != undefined ? i.studentDetailsData.map((data: any) => (
                                                                { value: data.admissionNo, label: data.admissionNo }
                                                            )) : []} required />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-3'>
                                                    <FormGroup id='toRollNo' label='To Roll No' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='To Roll No' placeholder="Select To Roll No"
                                                            className={examHallAllocation.length > index && i.toRollNoArray == undefined ? "border border-danger" : ''}
                                                            value={i.toRollNoArray}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, e, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            list={i.studentDetailsData != undefined ? i.studentDetailsData.map((data: any) => (
                                                                { value: data.admissionNo, label: data.admissionNo }
                                                            )) : []} required />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-3'>
                                                    <FormGroup id='totalNoOfStudents' label='No Of Student' isFloating>
                                                        <Input
                                                            className={examHallAllocation.length > index && i.totalNoOfStudents == '' ? "border border-danger" : ''}
                                                            value={i.totalNoOfStudents}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, i.supervisorArray, i.dateOfExam, e.target.value, i.displayOrder)}
                                                            type="number"
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-3'>
                                                    <FormGroup id='startTime' label='Start Time' isFloating>
                                                        <Input
                                                            className={examHallAllocation.length > index && i.startTime == '' ? "border border-danger" : ''}
                                                            value={i.startTime}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, e.target.value, i.endTime, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            type='time' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-3'>
                                                    <FormGroup id='endTime' label='End Time' isFloating>
                                                        <Input
                                                            className={examHallAllocation.length > index && i.endTime == '' ? "border border-danger" : ''}
                                                            value={i.endTime}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray,
                                                                 i.fromRollNoArray, i.toRollNoArray, i.startTime, e.target.value, i.supervisorArray, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            type='time' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-3'>
                                                    <FormGroup id='staffDetailsId' label='Supervisor' isFloating>
                                                        <SearchableSelect isFloating ariaLabel='Supervisor' placeholder="Select Supervisor"
                                                            className={examHallAllocation.length > index && i.supervisorArray == undefined ? "border border-danger" : ''}
                                                            value={i.supervisorArray}
                                                            onChange={(e: any) => addExamHallAllocationData(i.departmentIdArray, i.graduationTypeIdArray, i.batchMasterIdArray, i.courseIdArray, i.semesterDetailsIdArray, 
                                                                //i.sectionDetailsIdArray, 
                                                                i.fromRollNoArray, i.toRollNoArray, i.startTime, i.endTime, e, i.dateOfExam, i.totalNoOfStudents, i.displayOrder)}
                                                            list={i.staffDetailsData != undefined ? i.staffDetailsData.map((data: any) => (
                                                                { value: data.staffDetailsId, label: data.empAndStaffName }
                                                            )) : []} required />
                                                    </FormGroup>
                                                </div>

                                                {i.existingData ?
                                                    <div className='col-2'><div className='text-danger fw-bold text-uppercase mt-3'>Roll No Already Exist</div></div> : null
                                                }

                                                {addExamHallAllocation.length == 1 ? <>
                                                    <div className={i.existingData ? 'col-md-2' : 'col-md-4'}></div>
                                                    <div className='col-md-2'>
                                                        <Button className="mt-3" style={{ float: 'right' }} icon='Add' color='primary' onClick={add}
                                                            isDisable={i.departmentIdArray != undefined && i.graduationTypeIdArray != undefined && i.batchMasterIdArray != undefined && i.courseIdArray != undefined && i.semesterDetailsIdArray != undefined 
                                                               // && i.sectionDetailsIdArray != undefined 
                                                                && i.fromRollNo != undefined && i.toRollNoArray != undefined && i.startTime != '' && i.endTime != '' && i.supervisorArray != undefined && i.totalNoOfStudents != '' ? false : true}
                                                        >Add</Button>
                                                    </div> </> : null
                                                }

                                                {addExamHallAllocation.length > 1 ? <>
                                                    {index === addExamHallAllocation.length - 1 ? <>
                                                        <div className={i.existingData ? 'col-md-1' : 'col-md-3'}></div>
                                                        <div className='col-md-3'>
                                                            <Button className="mt-3 ms-5" icon='Delete' color='danger' onClick={() => handleRemove(i.displayOrder, index)}>Remove</Button>&nbsp;&nbsp;
                                                            <Button className="mt-3" icon='Add' color='primary' onClick={add}
                                                                isDisable={i.departmentIdArray != undefined && i.graduationTypeIdArray != undefined && i.batchMasterIdArray != undefined && i.courseIdArray != undefined && i.semesterDetailsIdArray != undefined 
                                                                    //&& i.sectionDetailsIdArray != undefined 
                                                                    && i.fromRollNo != undefined && i.toRollNoArray != undefined && i.startTime != '' && i.endTime != '' && i.supervisorArray != undefined && i.totalNoOfStudents != '' ? false : true}
                                                            >Add</Button>
                                                        </div> </> : <>
                                                        <div className={i.existingData ? 'col-md-2' : 'col-md-4'}></div>
                                                        <div className='col-md-2'>
                                                            <Button className="mt-3 ms-5" icon='Delete' color='danger' onClick={() => handleRemove(i.displayOrder, index)}>Remove</Button>
                                                        </div>
                                                    </>
                                                    } </> : null
                                                }
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <>
                            <Button color='info' isOutline className='border-0' onClick={() => { closeAndReset(); setAddExamDetailsOffCanvas(false) }}>Close</Button>
                            <Button icon="Save" color="info" type="submit" isDisable={isDuplicateEntry != '' ? true : false} onClick={examHallAllocationForm.handleSubmit}>
                                Save
                            </Button>
                        </>
                    </ModalFooter>
                </Modal>

                <Modal
                    setIsOpen={setViewExamDetailsOffCanvas}
                    isOpen={viewExamDetailsOffCanvas}
                    titleId='edit'
                    isStaticBackdrop
                    size='xl'>
                    <ModalHeader className="'modal-header'"
                        setIsOpen={() => { setViewExamDetailsOffCanvas(false) }} onClick={closeAndReset}>
                        <ModalTitle id='edit'>Hall Allocation Details</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">

                            {hallAllocationDetails != '' ? <>

                                {hallAllocationDetails.map((item: any) => (
                                    <div key={item.examDetailsId}>
                                        <div className='col-xl-12'>
                                            <div className='row px-4'>
                                                <div className='col-4'>
                                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-info-hover transition-base rounded-2 mb-4`}
                                                        shadow='sm'>
                                                        <CardBody>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='MenuBook' size='3x' color='info' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-5 mb-0'>{item.examTypeName}</div>
                                                                    <div className='text-muted'>Exam Type</div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>

                                                <div className='col-4'>
                                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-info-hover transition-base rounded-2 mb-4`}
                                                        shadow='sm'>
                                                        <CardBody>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='HolidayVillage' size='3x' color='info' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-5 mb-0'>{item.hallName}</div>
                                                                    <div className='text-muted'>Hall Name</div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                                <div className='col-4'>
                                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                                        }-info-hover transition-base rounded-2 mb-4`}
                                                        shadow='sm'>
                                                        <CardBody>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon icon='DateRange' size='3x' color='info' />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-5 mb-0'>{item.dateOfExamForView}</div>
                                                                    <div className='text-muted'>Exam Date</div>
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>

                                        {item.session.map((sessionData: any) => (<>
                                            <div className='flex-grow-1 ms-3'>
                                                <div className='fw-bold fs-5 mb-0'><u>{sessionData?.session}</u></div>
                                            </div>

                                            <div className="col-12">
                                                <div className="row">
                                                    {sessionData.classes.map((i: any) => (
                                                        <div className='col-4 mt-4' key={i.examHallAllocationId}>
                                                            <Card shadow='md' stretch className="border border-light">
                                                                <CardBody>
                                                                    <div className='col-12'>
                                                                        <div className='row'>
                                                                            <div className='col d-flex align-items-center'>
                                                                                <div className='flex-grow-1 col-4'>
                                                                                    <strong>Batch</strong>
                                                                                </div>
                                                                                <div className='col-2 text-center'>
                                                                                    <strong> : </strong>
                                                                                </div>
                                                                                <div className='col-6 text-start text-muted'>{i.batchName}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col d-flex align-items-center'>
                                                                                <div className='flex-grow-1 col-4'>
                                                                                    <strong>Course / Semester</strong>
                                                                                </div>
                                                                                <div className='col-2 text-center'>
                                                                                    <strong> : </strong>
                                                                                </div>
                                                                                <div className='col-6 text-start text-muted'>{i.courseName} / {i.semesterNumber}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col d-flex align-items-center'>
                                                                                <div className='flex-grow-1 col-4'>
                                                                                    <strong>Roll No</strong>
                                                                                </div>
                                                                                <div className='col-2 text-center'>
                                                                                    <strong> : </strong>
                                                                                </div>
                                                                                <div className='col-6 text-start text-muted'>{i.fromRollNo} - {i.toRollNo}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col d-flex align-items-center'>
                                                                                <div className='flex-grow-1 col-4'>
                                                                                    <strong>Time</strong>
                                                                                </div>
                                                                                <div className='col-2 text-center'>
                                                                                    <strong> : </strong>
                                                                                </div>
                                                                                <div className='col-6 text-start text-muted'>{i.startTimeForView} - {i.endTimeForView}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col d-flex align-items-center'>
                                                                                <div className='flex-grow-1 col-4'>
                                                                                    <strong>No Of Student</strong>
                                                                                </div>
                                                                                <div className='col-2 text-center'>
                                                                                    <strong> : </strong>
                                                                                </div>
                                                                                <div className='col-6 text-start text-muted'>{i.totalNoOfStudents}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className='row mt-3'>
                                                                            <div className='col d-flex align-items-center'>
                                                                                <div className='flex-grow-1 col-4'>
                                                                                    <strong>Supervisor</strong>
                                                                                </div>
                                                                                <div className='col-2 text-center'>
                                                                                    <strong> : </strong>
                                                                                </div>
                                                                                <div className='col-6 text-start text-muted'>{i.supervisorName}</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </CardBody>
                                                                <CardFooter borderSize={1} style={{ height: '20px' }}>
                                                                    <Button isOutline icon="Delete" size={'sm'} color='danger' onClick={() => { deleteModal(i); setViewExamDetailsOffCanvas(false) }}>Delete</Button>
                                                                    <Button isOutline className="ms-2" color='info' size={'sm'} icon="Edit" onClick={() => { onActivate(i); setViewExamDetailsOffCanvas(false) }}>Edit</Button>
                                                                </CardFooter>
                                                            </Card>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </>
                                        ))} </div>
                                ))}
                            </> : null
                            }
                        </div>
                    </ModalBody>
                </Modal>

                <Modal
                    setIsOpen={setEditExamDetailsOffCanvas}
                    isOpen={editExamDetailsOffCanvas}
                    titleId='edit'
                    isStaticBackdrop
                    size='xl'>
                    <ModalHeader className="'modal-header'"
                        setIsOpen={() => { setEditExamDetailsOffCanvas(false); setViewExamDetailsOffCanvas(true) }} onClick={closeAndReset}>
                        <ModalTitle id='edit'>Update Exam Hall Allocation</ModalTitle>
                    </ModalHeader>
                    <ModalBody>
                        <div className='row g-4'>
                            <div className='col-3'>
                                <FormGroup id='departmentId' label='Department' isFloating>
                                    <SearchableSelect isFloating ariaLabel='Department'
                                        onChange={selectDepartment}
                                        value={departmentId}
                                        list={departmentData.map((data: any) => (
                                            { value: data.departmentId, label: data.departmentName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                    <SearchableSelect isFloating ariaLabel='Graduation Type'
                                        onChange={selectGraduationType}
                                        value={graduationTypeId}
                                        list={graduationTypeData.map((data: any) => (
                                            { value: data.graduationTypeId, label: data.graduationType }
                                        ))}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                    <SearchableSelect isFloating ariaLabel='Batch' placeholder="Select Batch"
                                        onChange={selectBatch}
                                        value={batchMasterId}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.batchMasterId}
                                        invalidFeedback={examHallAllocationEditForm.errors.batchMasterId}
                                        list={batchMasterData.map((data: any) => (
                                            { value: data.batchMasterId, label: data.batchName }
                                        ))} required />
                                </FormGroup>
                            </div>

                            <div className='col-3'>
                                <FormGroup id='courseId' label='Course' isFloating>
                                    <SearchableSelect isFloating
                                        ariaLabel='Course'
                                        onChange={selectCourse}
                                        value={courseId}
                                        list={courseByBatchMasterData.map((data: any) => (
                                            { value: data.courseId, label: data.courseName }
                                        ))}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.courseId}
                                        invalidFeedback={examHallAllocationEditForm.errors.courseId}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                    <SearchableSelect isFloating placeholder='Select Semester' ariaLabel='Semester' onChange={selectSemester}
                                        value={semesterDetailsId}
                                        list={semesterDetailsData.map((data: any) => (
                                            { value: data.semesterDetailsId, label: data.semesterNumber }
                                        ))}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.semesterDetailsId}
                                        invalidFeedback={examHallAllocationEditForm.errors.semesterDetailsId}
                                    />
                                </FormGroup>
                            </div>

                            {/* <div className='col-3'>
                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                    <SearchableSelect isFloating ariaLabel='Section' placeholder="Select Section"
                                        onChange={selectSection}
                                        value={sectionDetailsId}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.sectionDetailsId}
                                        invalidFeedback={examHallAllocationEditForm.errors.sectionDetailsId}
                                        list={sectionData.map((data: any) => (
                                            { value: data.sectionDetailsId, label: data.sectionName }
                                        ))} required />
                                </FormGroup>
                            </div> */}

                            <div className='col-3'>
                                <FormGroup id='fromRollNo' label='From Roll No' isFloating>
                                    <SearchableSelect isFloating ariaLabel='From Roll No' placeholder="Select From Roll No"
                                        onChange={selectFromRollNo}
                                        value={fromRollNo}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.fromRollNo}
                                        invalidFeedback={examHallAllocationEditForm.errors.fromRollNo}
                                        list={studentDetailsData.map((data: any) => (
                                            { value: data.admissionNo, label: data.admissionNo }
                                        ))} required />
                                </FormGroup>
                            </div>

                            <div className='col-3'>
                                <FormGroup id='toRollNo' label='To Roll No' isFloating>
                                    <SearchableSelect isFloating ariaLabel='To Roll No' placeholder="Select To Roll No"
                                        onChange={selectToRollNo}
                                        value={toRollNo}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.toRollNo}
                                        invalidFeedback={examHallAllocationEditForm.errors.toRollNo}
                                        list={studentDetailsData.map((data: any) => (
                                            { value: data.admissionNo, label: data.admissionNo }
                                        ))} required />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='totalNoOfStudents' label='No Of Student' isFloating>
                                    <Input
                                        onChange={examHallAllocationEditForm.handleChange}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.totalNoOfStudents}
                                        invalidFeedback={examHallAllocationEditForm.errors.totalNoOfStudents}
                                        value={examHallAllocationEditForm.values.totalNoOfStudents}
                                        type="number"
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='startTime' label='Start Time' isFloating>
                                    <Input
                                        onChange={examHallAllocationEditForm.handleChange}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.startTime}
                                        invalidFeedback={examHallAllocationEditForm.errors.startTime}
                                        value={examHallAllocationEditForm.values.startTime}
                                        type='time' />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='endTime' label='End Time' isFloating>
                                    <Input
                                        onChange={examHallAllocationEditForm.handleChange}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.endTime}
                                        invalidFeedback={examHallAllocationEditForm.errors.endTime}
                                        value={examHallAllocationEditForm.values.endTime}
                                        type='time' />
                                </FormGroup>
                            </div>
                            <div className='col-3'>
                                <FormGroup id='supervisor' label='Supervisor' isFloating>
                                    <SearchableSelect isFloating ariaLabel='Supervisor' placeholder="Select Supervisor"
                                        onChange={selectSupervisor}
                                        value={supervisor}
                                        onBlur={examHallAllocationEditForm.handleBlur}
                                        isValid={examHallAllocationEditForm.isValid}
                                        isTouched={examHallAllocationEditForm.touched.supervisor}
                                        invalidFeedback={examHallAllocationEditForm.errors.supervisor}
                                        list={staffDetailsData.map((data: any) => (
                                            { value: data.staffDetailsId, label: data.empAndStaffName }
                                        ))} required />
                                </FormGroup>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <>
                            <Button color='info' isOutline className='border-0' onClick={() => { closeAndReset(); setEditExamDetailsOffCanvas(false); setViewExamDetailsOffCanvas(true) }}>Close</Button>
                            <Button icon="Save" color="info" type="submit" onClick={examHallAllocationEditForm.handleSubmit}>
                                Save
                            </Button>
                        </>
                    </ModalFooter>
                </Modal>

                <Modal
                    setIsOpen={setIsDeleteOpen}
                    isOpen={isDeleteOpen}
                    titleId='deleteBatchMaster'
                    isStaticBackdrop
                    size='sm'>
                    <ModalHeader><></></ModalHeader>
                    <ModalBody>
                        <div className="row g-2">
                            <div className="col-12 d-flex justify-content-center">
                                <h5><strong>Are you sure want to delete ?</strong></h5>
                            </div>
                            <div className='col-10'>
                                <div className='d-flex gap-2 justify-content-end'>
                                    <Button color='danger' size={'sm'} onClick={deleteExamHallAllocationSubmit}>Yes</Button>
                                    <Button color='info' size={'sm'} onClick={() => { setIsDeleteOpen(!isDeleteOpen); setViewExamDetailsOffCanvas(true) }}>No</Button>
                                </div>
                            </div>
                            <div className='col-10'></div>
                        </div>
                    </ModalBody>
                </Modal>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </PageWrapper >
        </>
    )
} export default ExamHallAllocation;