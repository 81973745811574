import { useFormik } from "formik";
import { useEffect, useState, useRef, useContext } from "react";
import { useReactToPrint } from "react-to-print";
import Select from "../../bootstrap/forms/Select";
import useDarkMode from "../../../hooks/useDarkMode";
import useSortableData from "../../../hooks/useSortableData";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import { toasts } from "../../../services/toast.service";
import Page from "../../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../../bootstrap/Card";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../bootstrap/Dropdown";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../services/ExportService";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../../bootstrap/OffCanvas";
import AlertService from "../../../services/AlertService";
import DeleteComponents from "../../../common/components/DeleteComponents";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { addStaffDesignation, addStaffDesignationForPayroll, deleteStaffDesignation, deleteStaffDesignationForPayroll, getStaffDesignation, updateStaffDesignation, updateStaffDesignationForPayroll } from "../../../services/administration.service";
import { getColumnsForDataTable, getUserType, updateColumnsForDataTable, updateFilter } from "../../../services/common.service";
import AuthContext from "../../../contexts/authContext";
import { getLicenseKey } from "../../../services/application.settings";
import { TableLoader, showLoader } from "../../../services/loader.services";
import NoDataMsg from "../../../common/components/NoDataMsg";
import SearchableSelect from "../../../common/components/SearchableSelect";

function StaffDesignation() {

    useEffect(() => {
        getStaffDesignationList();
        getUserTypeList()
    }, [])

    const { userAccountId, userTypeId, isPayrollConnected } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [staffDesignationData, setStaffDesignationData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [userTypeData, setUserTypeData] = useState<any>([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    const [designationTypeId, setDesignationTypeId] = useState<any>('')

    // ForModal
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(staffDesignationData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addStaffDesignationOffCanvas, setAddStaffDesignationOffCanvas] = useState(false)
    const [editStaffDesignationOffCanvas, setEditStaffDesignationOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const staffDesignationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            designationTypeId: '',
            designationName: '',
            description: '',
        },
        validate: (values) => {
            const errors: {
                designationTypeId?: string;
                designationName?: string;
                description?: string;
            } = {};
            if (!designationTypeId?.value) {
                errors.designationTypeId = 'Required';
            }
            if (!values.designationName) {
                errors.designationName = 'Required';
            }
            if (!values.description) {
                errors.description = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { addStaffDesignationSubmit() },
    });

    const staffDesignationEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            designationTypeId: '',
            designationName: '',
            description: '',
            staffDesignationId: '',
        },
        validate: (values) => {
            const errors: {
                designationTypeId?: string;
                designationName?: string;
                description?: string;
                staffDesignationId?: string;
            } = {};
            if (!designationTypeId?.value) {
                errors.designationTypeId = 'Required';
            }
            if (!values.designationName) {
                errors.designationName = 'Required';
            }
            if (!values.description) {
                errors.description = 'Required';
            }
            if (!values.staffDesignationId) {
                errors.staffDesignationId = 'Required'
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { updateStaffDesignationSubmit() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectDesignation = (e: any) => {
        let designationTypeId = e
        setDesignationTypeId(designationTypeId)
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedStaffDesignation = i
            setEditStaffDesignationOffCanvas(true)
            setDesignationTypeId({ value: i.userTypeId, label: i.userType })
            staffDesignationEditForm.setValues({
                designationTypeId: designationTypeId?.value,
                designationName: selectedStaffDesignation.designationName,
                description: selectedStaffDesignation.description,
                staffDesignationId: selectedStaffDesignation.staffDesignationId,
            })
        }
    }

    function getStaffDesignationList() {
        setDataStatus(false)
        getStaffDesignation(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffDesignation;
                    if (data != undefined) {
                        getColumnsForTable('getStaffDesignation', 'get');
                        setStaffDesignationData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    //toasts(response.data.message, "Error")
                    getColumnsForTable('getStaffDesignation', 'get');
                    setStaffDesignationData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        setUserTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setStaffDesignation() {

        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            userTypeId: designationTypeId?.value,
            designationName: staffDesignationForm.values.designationName,
            description: staffDesignationForm.values.description,
        })
    }

    function setEditStaffDesignation() {
        return ({
            staffDesignationId: staffDesignationEditForm.values.staffDesignationId,
            userTypeId: designationTypeId?.value,
            designationName: staffDesignationEditForm.values.designationName,
            description: staffDesignationEditForm.values.description,
            userAccountId: userAccountId,
            isActive: 1,
            licenseKey: getLicenseKey,
        })
    }

    function addStaffDesignationSubmit() {
        showLoader(true)
        if (staffDesignationForm.isValid) {
            let staffDesignationPostData = setStaffDesignation();
            addStaffDesignation(staffDesignationPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {

                        if (isPayrollConnected) {
                            addStaffDesignationForPayroll(staffDesignationPostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false)
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getStaffDesignationList();
                                        closeAndReset();
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "error" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    showLoader(false)
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )
                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getStaffDesignationList();
                            closeAndReset();
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (staffDesignationForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateStaffDesignationSubmit() {
        showLoader(true)
        if (staffDesignationEditForm.isValid) {
            let staffDesignationPostData = setEditStaffDesignation();
            updateStaffDesignation(staffDesignationPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            updateStaffDesignationForPayroll(staffDesignationPostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false)
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getStaffDesignationList();
                                        closeAndReset();
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "error" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    showLoader(false)
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )
                        } else {
                            showLoader(false)
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getStaffDesignationList();
                            closeAndReset();
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (staffDesignationEditForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteStaffDesignationSubmit() {
        showLoader(true)
        let staffDesignationPostData = setEditStaffDesignation();
        deleteStaffDesignation(staffDesignationPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    if (isPayrollConnected) {
                        deleteStaffDesignationForPayroll(staffDesignationPostData,
                            (response) => {
                                const data1 = response.data;
                                if (data1.success == true) {
                                    showLoader(false)
                                    setAlertStatus({ message: data.message, type: "success" });
                                    setIsOpen(true);
                                    getStaffDesignationList();
                                    closeAndReset();
                                } else if (data1.success == false) {
                                    showLoader(false);
                                    setAlertStatus({ message: data1.message, type: "error" });
                                    setIsOpen(true);
                                }
                                else {
                                    showLoader(false);
                                    let errorCode = response.data.error[0].error_code;
                                    let errorDescription = response.data.error[0].error_description;
                                    setAlertStatus({ message: errorDescription, type: "error" });
                                    setIsOpen(true);
                                }
                            }
                            , (error) => {
                                showLoader(false)
                                setAlertStatus({ message: error, type: "error" });
                                setIsOpen(true);
                            }
                        )
                    } else {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStaffDesignationList();
                        closeAndReset();
                    }
                }
                else if (data.success == false) {
                    showLoader(false)
                    setAlertStatus({ message: data.message, type: "error" });
                    setIsOpen(true);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    showLoader(false)
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false)
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStaffDesignation', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddStaffDesignationOffCanvas(false);
        setEditStaffDesignationOffCanvas(false);
        setIsDeleteOpen(false);
        staffDesignationForm.resetForm();
        staffDesignationEditForm.resetForm();
        setDesignationTypeId('')
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Staff Designation">
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef}>
                    <CardHeader borderSize={1}>
                        <CardLabel icon='List' iconColor='info' className="col-lg-5">
                            <CardTitle tag='div' className='h5'> Staff Designation List</CardTitle>
                        </CardLabel>&nbsp;&nbsp;

                        <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                            <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                            <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                        </ButtonGroup>

                        <CardActions className="d-print-none">
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem>
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffDesignation', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("StaffDesignationList", staffDesignationData, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(staffDesignationData, columnVisibilityData, "StaffDesignationList")}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("StaffDesignationList", staffDesignationData, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Button color='primary' icon='Add' isLight onClick={() => setAddStaffDesignationOffCanvas(true)}>
                                Add New
                            </Button>
                        </CardActions>
                    </CardHeader>

                    <CardBody className='table-responsive' isScrollable>
                        {!dataStatus ?
                            <TableLoader /> :
                            <table className='table table-modern table-hover text-nowrap'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <th scope='col' className='d-print-none'>
                                            Action
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.staffDesignationId}><td width={20}>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.staffDesignationId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className="d-print-none"><Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} /></td></tr>))}</> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}</tbody>
                            </table>}
                    </CardBody>
                    <PaginationButtons
                        className="d-print-none"
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
            </Page>
            <OffCanvas
                setOpen={setAddStaffDesignationOffCanvas}
                isOpen={addStaffDesignationOffCanvas}
                titleId='addStaffDesignationOffCanvas'
                isBodyScroll
                placement='end'
                tag="form" noValidate onSubmit={staffDesignationForm.handleSubmit}>
                <OffCanvasHeader setOpen={setAddStaffDesignationOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id='addStaffDesignationOffCanvas'>Add Staff Designation</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='designationTypeId' label='Designation Type' >
                                <SearchableSelect
                                    ariaLabel='Designation Type'
                                    onChange={selectDesignation}
                                    value={designationTypeId}
                                    onBlur={staffDesignationForm.handleBlur}
                                    isValid={staffDesignationForm.isValid}
                                    isTouched={staffDesignationForm.touched.designationTypeId}
                                    invalidFeedback={staffDesignationForm.errors.designationTypeId}
                                    list={userTypeData.map((data: any) => (
                                        { value: data.userTypeId, label: data.userType }
                                    ))}
                                    placeholder="Designation Type"
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='designationName' label='Designation Name' >
                                <Input placeholder='Designation Name' onChange={staffDesignationForm.handleChange} value={staffDesignationForm.values.designationName}
                                    onBlur={staffDesignationForm.handleBlur}
                                    isValid={staffDesignationForm.isValid}
                                    isTouched={staffDesignationForm.touched.designationName}
                                    invalidFeedback={staffDesignationForm.errors.designationName} />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='description' label='Description' >
                                <Input placeholder='Description' onChange={staffDesignationForm.handleChange} value={staffDesignationForm.values.description}
                                    onBlur={staffDesignationForm.handleBlur}
                                    isValid={staffDesignationForm.isValid}
                                    isTouched={staffDesignationForm.touched.description}
                                    invalidFeedback={staffDesignationForm.errors.description} />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='row m-0'>
                    <div className='col-12 p-3'>
                        <Button
                            color='info' icon="Save"
                            className='w-100' type="submit" isDisable={!staffDesignationForm.isValid && !!staffDesignationForm.submitCount}>
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setEditStaffDesignationOffCanvas}
                isOpen={editStaffDesignationOffCanvas}
                titleId='editStaffDesignationOffCanvas'
                isBodyScroll
                placement='end'
                tag="form" noValidate onSubmit={staffDesignationEditForm.handleSubmit}>
                <OffCanvasHeader setOpen={setEditStaffDesignationOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="editStaffDesignationOffCanvas">Update Staff Designation</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='designationTypeId' label='Designation Type' >
                                <SearchableSelect
                                    ariaLabel='Designation Type'
                                    onChange={selectDesignation}
                                    value={designationTypeId}
                                    onBlur={staffDesignationEditForm.handleBlur}
                                    isValid={staffDesignationEditForm.isValid}
                                    isTouched={staffDesignationEditForm.touched.designationTypeId}
                                    invalidFeedback={staffDesignationEditForm.errors.designationTypeId}
                                    list={userTypeData.map((data: any) => (
                                        { value: data.userTypeId, label: data.userType }
                                    ))}
                                    placeholder="Designation Type"
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='designationName' label='Designation Name' >
                                <Input placeholder='Designation Name' onChange={staffDesignationEditForm.handleChange}
                                    value={staffDesignationEditForm.values.designationName}
                                    onBlur={staffDesignationEditForm.handleBlur}
                                    isValid={staffDesignationEditForm.isValid}
                                    isTouched={staffDesignationEditForm.touched.designationName}
                                    invalidFeedback={staffDesignationEditForm.errors.designationName}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='description' label='Description' >
                                <Input placeholder='Description' onChange={staffDesignationEditForm.handleChange}
                                    value={staffDesignationEditForm.values.description}
                                    onBlur={staffDesignationEditForm.handleBlur}
                                    isValid={staffDesignationEditForm.isValid}
                                    isTouched={staffDesignationEditForm.touched.description}
                                    invalidFeedback={staffDesignationEditForm.errors.description} />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>

                <div className='row'>
                    <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                        <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteStaffDesignationSubmit} />
                    </div>
                    <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                        <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                            Delete
                        </Button>
                    </div>
                    <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                        <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!staffDesignationEditForm.isValid && !!staffDesignationEditForm.submitCount}> Update</Button>
                    </div>
                </div>
            </OffCanvas>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
} export default StaffDesignation;