import React, { useContext, useEffect, useState } from 'react'
import { convertDateToEpoch, getUserType } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Button from '../../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardSubTitle } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import AuthContext from '../../../contexts/authContext';
import Input from '../../bootstrap/forms/Input';
import { useFormik } from 'formik';
import Timeline, { TimelineItem } from '../../extras/Timeline';
import Icon from '../../icon/Icon';
import { getActivityLog, getStaffListByUserTypeId } from '../../../services/staff.service';

const StaffActivityReport = () => {

    useEffect(() => {
        getUserTypeList()
    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [userTypeData, setUserTypeData] = useState<any>([])
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isLoader, setIsLoader] = useState(false)
    const [staffTypeId, setStaffTypeId] = useState<any>([])
    const [staffDetailsData, setStaffDetailsData] = useState<any>([])
    const [staffDetailsId, setStaffDetailsId] = useState<any>([])
    const [activityData, setActivityData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('')

    const staffDetailsForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            fromDate: '',
            toDate: '',
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewFeeSummaryList() {
        setDataSuccess(false)
        setIsLoader(true);
        let fromDate = staffDetailsForm.values.fromDate
        let toDate = staffDetailsForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getActivityLogList(
            //staffTypeId?.value,
            staffDetailsId?.value,
            fromDateInMilliSec,
            toDateInMilliSec,);
    }

    const selectUserType = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        setStaffDetailsId('')
        staffDetailsForm.resetForm()
        let staffTypeId = e
        setStaffTypeId(staffTypeId)
        if (staffTypeId?.value != undefined) {
            getStaffListByUserType(staffTypeId?.value)
        }

    }

    const selectStaff = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        staffDetailsForm.resetForm()
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {

                        setUserTypeData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setUserTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffListByUserType(staffTypeId: any) {
        getStaffListByUserTypeId(staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setStaffDetailsData([]);
                    //toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getActivityLogList(
        //userAccountId: any,
        studentOrStaffDetailsId: any,
        fromDateInMilliSec: any,
        toDateInMilliSec: any,

    ) {
        setIsLoader(true);
        getActivityLog(
            //userAccountId,
            studentOrStaffDetailsId,
            fromDateInMilliSec,
            toDateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.activityLog;
                    if (data != undefined) {
                        setIsLoader(false);
                        setIsOpenListCard(false)
                        setDataSuccess(true)
                        setActivityData(data);
                        console.log(data);
                    } else {
                        setIsLoader(false);
                        setDataSuccess(true)
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(false)
                    setDataSuccess(true)
                    setNoDataMsg(response.data.message)
                    setActivityData([]);
                    // toasts(response.data.message, 'Error');
                } else {
                    setIsLoader(false);
                    setDataSuccess(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                setDataSuccess(true)
                toasts(error, 'Error');
            },
        );
    }

    return (

        <PageWrapper title='Staff Activity Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list' className='d-print-none'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle>
                                    Staff Activity Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <FormGroup id='staffTypeId' label='User Type' isFloating>
                                        <SearchableSelect isFloating
                                            ariaLabel='Type'
                                            onChange={selectUserType}
                                            value={staffTypeId}
                                            list={userTypeData.map((data: any) => (
                                                { value: data.userTypeId, label: data.userType }
                                            ))}
                                        />
                                    </FormGroup>
                                </div>

                                {staffTypeId?.value != undefined ?
                                    <div className='col-md-3'>
                                        <FormGroup id='staffDetailsId' label='Staff' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='Type'
                                                onChange={selectStaff}
                                                value={staffDetailsId}
                                                list={staffDetailsData.map((data: any) => (
                                                    { value: data.staffDetailsId, label: data.staffName }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }

                                {staffDetailsId?.value != undefined ?
                                    <div className="col-2">
                                        <FormGroup id="fromDate" label="From Date" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='From Date'
                                                onChange={staffDetailsForm.handleChange}
                                                onBlur={staffDetailsForm.handleBlur}
                                                value={staffDetailsForm.values.fromDate}
                                                isValid={staffDetailsForm.isValid}
                                                isTouched={staffDetailsForm.touched.fromDate}
                                                invalidFeedback={staffDetailsForm.errors.fromDate}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }

                                {staffDetailsForm.values.fromDate != '' ?
                                    <div className="col-2 ">
                                        <FormGroup id="toDate" label="To Date" isFloating>
                                            <Input
                                                type='date'
                                                ariaLabel='To Date'
                                                onChange={staffDetailsForm.handleChange}
                                                onBlur={staffDetailsForm.handleBlur}
                                                value={staffDetailsForm.values.toDate}
                                                isValid={staffDetailsForm.isValid}
                                                isTouched={staffDetailsForm.touched.toDate}
                                                invalidFeedback={staffDetailsForm.errors.toDate}
                                            />
                                        </FormGroup>
                                    </div> : null
                                }
                                {staffDetailsForm.values.toDate != '' ?
                                    <div className='col-md-2'>
                                        <Button className='mt-2' icon='ArrowDownward' color='primary' onClick={viewFeeSummaryList}>View</Button>
                                    </div> : null
                                }

                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ? <>
                    <Card stretch>
                        <CardHeader>
                            <CardLabel icon='NotificationsActive' iconColor='warning'>
                                <CardTitle tag='div' className='h5'>
                                    Recent Activities
                                </CardTitle>
                                <CardSubTitle tag='div' className='h6'>
                                    from <span className='text-primary'>{activityData[0]?.fromDate}</span> to <span className='text-primary'>{activityData[0]?.toDate}</span>
                                </CardSubTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody isScrollable>
                            {activityData != '' ?
                                <Timeline>
                                    {activityData.map((item: any) => (
                                        <>
                                            {item.activities.map((i: any) => (
                                                <TimelineItem
                                                    key={i.activityLogsId}
                                                    label={i.activityTimeForView}
                                                    color='success'>
                                                    {i.activity}
                                                </TimelineItem>
                                            ))}

                                        </>
                                    ))}
                                </Timeline> :
                                <div className='row'>
                                    <div className="col-12" style={{ height: '5vh' }}></div>
                                    <div className='col-12 d-flex justify-content-center'>
                                        <Icon icon='DoNotDisturb' size={'10x'} />
                                    </div>
                                    <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                        {noDataMsg}
                                    </div>
                                </div>
                            }
                        </CardBody>
                    </Card> </> : null
                }
            </Page>
        </PageWrapper>
    )
}

export default StaffActivityReport