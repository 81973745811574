import React, { useEffect, useState } from 'react';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../bootstrap/Card';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Button from '../../bootstrap/Button';
import FeeType from './module-type-UIs/FeeType';
import FineType from './module-type-UIs/FineType';
import StudentSchoolSubject from './module-type-UIs/StudentSchoolSubject';
import AddLateAttendanceReason from './module-type-UIs/AddLateAttendanceReason';
import { getModuleType } from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';


const ModuleTypeUIs = () => {

	useEffect(() => {
		getModuleTypeDetails();
	}, [])

	// const moduleType = [
	// 	{ moduleTypeId: 1, moduleType: 'Fee Type' },
	// 	{ moduleTypeId: 2, moduleType: 'Fine Type' },
	// 	{ moduleTypeId: 3, moduleType: 'Student School Subject' },
	// 	{ moduleTypeId: 4, moduleType: 'Late Attendance Reason' },
	// ];

	const addModuleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			modules: '',
		},
		validate: (values) => {
			const errors: {
				modules?: string;
			} = {};
			if (!values.modules) {
				errors.modules = 'Required';
			}
		},
		onSubmit: () => { },
	});

	const navigate = useNavigate();
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [moduleTypeId, setModuleTypeId] = useState<any>('');
	const [moduleTypeData, setModuleTypeData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false);

	const selectModuleType = (e: any) => {
		setDataSuccess(false);
		let moduleTypeId = e;
		setModuleTypeId(moduleTypeId);
	};

	const view = () => {
		setDataSuccess(true);
		setIsOpenListCard(false);
	}

	function getModuleTypeDetails() {
		getModuleType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.moduleType;
					if (data != undefined) {
						setModuleTypeData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setModuleTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	return (
		<PageWrapper title='Module'>
			<Page>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list' tag='form' noValidate>
						<CardHeader borderSize={1}>
							<CardLabel icon='list' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Module
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup id='moduleTypeId' label='Module Type' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='Module Type'
											onChange={selectModuleType}
											value={moduleTypeId}
											list={moduleTypeData.map((data: any) => ({
												value: data.moduleTypeId,
												label: data.moduleType,
											}))}
										/>
									</FormGroup>
								</div>
								{moduleTypeId ?
									<div className='col-md-2'>
										<Button
											className='mt-2'
											icon='ArrowDownward'
											color='primary'
											onClick={view}>
											View
										</Button>
									</div> : null
								}
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>
				{dataSuccess ? <>
					{moduleTypeId?.value == 1 ?
						<FeeType /> : moduleTypeId?.value == 2 ?
							<FineType /> : moduleTypeId?.value == 3 ?
								<StudentSchoolSubject /> : moduleTypeId?.value == 4 ?
									<AddLateAttendanceReason /> : null
					}   </> : null
				}

			</Page>
		</PageWrapper>
	);
};

export default ModuleTypeUIs;
