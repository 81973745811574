import React, { useEffect, useState } from 'react';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { toasts } from '../../../services/toast.service';
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from '../../bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';
import classNames from 'classnames';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import { getFirstLetter } from '../../../helpers/helpers';
import Chart from '../../extras/Chart';
import { convertDateToEpoch, getCurrentDateAndTime } from '../../../services/common.service';
import Button from '../../bootstrap/Button';
import { Calendar as DatePicker } from 'react-date-range';
import Popovers from '../../bootstrap/Popovers';
import { getLabel } from '../../extras/calendarHelper';
import { View as TView} from 'react-big-calendar';
import Icon from '../../icon/Icon';
import { getStudentFeeTransactionDetails } from '../../../services/dashboard.service';

const StudentRecentFeeTransaction = () => {

	const [date, setDate] = useState(new Date());
	const [viewMode, setViewMode] = useState<TView>('month');

	const calendarDateLabel = getLabel(date, viewMode);

	useEffect(() => {

		let dateInMilliSec = convertDateToEpoch(getCurrentDateAndTime('date'));
		getStudentFeeTransaction(dateInMilliSec);
		getRecentTransactionList();
		getRecentTransactionList1();
		setTransactionDate(getCurrentDateAndTime('date'));

		const day = date.getDate().toString().padStart(2, '0');;
		setDay(day + ' ' + calendarDateLabel)

	}, []);

	const [studentFeeTransactionData, setStudentFeeTransactionData] = useState<any>([]);
	const [transactionData, setTransactionData] = useState<any>([]);

	const [noDataMsg, setNoDataMsg] = useState('');
	const { darkModeStatus } = useDarkMode();

	const colors = ['info', 'success', 'warning', 'primary', 'secondary'];

	function getStudentFeeTransaction(dateInMilliSec: any) {
		getStudentFeeTransactionDetails(
			dateInMilliSec,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.studentFeeTransactionDetails;
					if (data != undefined) {
						for (let i = 0; i < data.length; i++) {
							data[i].color = colors[i];
						}
						setStudentFeeTransactionData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStudentFeeTransactionData([]);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const [totalFeeCollected, setTotalFeeCollected] = useState<any>('');
	const [totalReceipts, setTotalReceipts] = useState<any>('');

	function getRecentTransactionList() {
		// getRecentTransactionSummaryReport(
		// 	(response) => {
		// 		if (response.data.success) {
		// 			let data = response.data.data.recentTransactionSummaryReport;
		// 			if (data != undefined) {
		// 				setTotalFeeCollected(data[0].totalFeesCollected);

		// 				let feeCollectionDate = data.map(
		// 					(item: any) => item.feeCollectionDateForView,
		// 				);

		// 				for (let i = 0; i < data.length; i++) {
		// 					let payment = data[i].paymentDetails;

		// 					let paymentDetails = payment.map((item: any) => item.feesCollected);

		// 					for (let j = 0; j < payment.length; j++) {
		// 						data[i].name = payment[j].paymentType;
		// 					}
		// 					data[i].data = paymentDetails;
		// 					data[i].categories = data[i].feeCollectionDateForView;
		// 				}

		// 				setTransactionData({
		// 					series: data,
		// 					options: {
		// 						chart: {
		// 							type: 'bar',
		// 							height: 100,
		// 							stacked: true,
		// 							sparkline: {
		// 								enabled: true,
		// 							},
		// 						},
		// 						tooltip: {
		// 							theme: 'dark',
		// 						},
		// 						plotOptions: {
		// 							bar: {
		// 								borderRadius: 7,
		// 								columnWidth: '25%',
		// 							},
		// 						},
		// 						xaxis: {
		// 							type: 'category',
		// 							categories: feeCollectionDate,
		// 						},
		// 						fill: {
		// 							opacity: 1,
		// 						},
		// 					},
		// 				});
		// 			} else {
		// 				toasts('Undefined Data', 'Error');
		// 			}
		// 		} else if (response.data.success === false) {
		// 			setTransactionData([]);
		// 		} else {
		// 			let errorCode = response.data.error[0].error_code;
		// 			let errorDescription = response.data.error[0].error_description;
		// 			toasts(errorDescription, 'Error');
		// 		}
		// 	},
		// 	(error) => {
		// 		toasts(error, 'Error');
		// 	},
		// );
	}

	function getRecentTransactionList1() {
		// getRecentTransactionSummaryReport(
		// 	(response) => {
		// 		if (response.data.success) {
		// 			let data = response.data.data.recentTransactionSummaryReport;
		// 			if (data != undefined) {
		// 				setTotalReceipts(data[0].noOfReceipts);

		// 				let receiptCount = data.map((item: any) => item.noOfReceipts);
		// 				let feeCollectionDate = data.map((item: any) => item.feeCollectionDateForView);

		// 				setReceiptData({
		// 					series: [{
		// 						name: 'Receipts ',
		// 						data: receiptCount,
		// 					}],
		// 					options: {
		// 						chart: {
		// 							type: 'area',
		// 							height: '165',
		// 							sparkline: {
		// 								enabled: true,
		// 							},
		// 						},
		// 						stroke: {
		// 							curve: 'smooth',
		// 						},
		// 						fill: {
		// 							type: 'gradient',
		// 							gradient: {
		// 								shadeIntensity: 1,
		// 								opacityFrom: 0.7,
		// 								opacityTo: 0,
		// 								stops: [0, 100],
		// 							},
		// 						},
		// 						xaxis: {
		// 							type: 'category',
		// 							categories: feeCollectionDate,
		// 						},
		// 						tooltip: {
		// 							theme: 'dark',
		// 						},
		// 						colors: [process.env.REACT_APP_DANGER_COLOR],
		// 					},
		// 				});
		// 			} else {
		// 				toasts('Undefined Data', 'Error');
		// 			}
		// 		} else if (response.data.success === false) {
		// 			setTransactionData([]);
		// 		} else {
		// 			let errorCode = response.data.error[0].error_code;
		// 			let errorDescription = response.data.error[0].error_description;
		// 			toasts(errorDescription, 'Error');
		// 		}
		// 	},
		// 	(error) => {
		// 		toasts(error, 'Error');
		// 	},
		// );
	}

	const [receiptData, setReceiptData] = useState<any>([]);

	const [transactionDate, setTransactionDate] = useState<any>('');
	const [day, setDay] = useState<any>('');

	function selectTransactionDate(e: any) {
		setDate(e)
		const calendarDateLabel = getLabel(e, 'month')
		const day = e.getDate().toString().padStart(2, '0');;
		setDay(day + ' ' + calendarDateLabel)
		const formattedDate = `${e.getFullYear()}-${String(e.getMonth() + 1).padStart(2, '0')}-${String(e.getDate()).padStart(2, '0')}`;

		let dateInMilliSec = convertDateToEpoch(formattedDate);
		getStudentFeeTransaction(dateInMilliSec);
		setTransactionDate(formattedDate);
	}


	return (
		<PageWrapper title='All Transaction'>
			<SubHeader>
				<SubHeaderLeft>
					<CardLabel icon='MonetizationOn' iconColor='info'>
						<CardTitle className='fs-5'>Recent Transfer</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
				<SubHeaderRight>
					<SubheaderSeparator />
					<Popovers
						desc={
							<DatePicker
								onChange={selectTransactionDate}
								date={date}
								color={process.env.REACT_APP_PRIMARY_COLOR}
							/>
						}
						placement='bottom-end'
						className='mw-100'
						trigger='click'>
						<Button color={darkModeStatus ? 'light' : 'dark'} isLight>
							{day}
						</Button>
					</Popovers>
				</SubHeaderRight>
			</SubHeader>
			<Page container='fluid'>
				<div className='row'>
					<div className='col-lg-8'>
						{studentFeeTransactionData != '' ? (
							<>
								{studentFeeTransactionData.map((i: any, index: any) => (
									<div className='col-lg-12'>
										<Card>
											<CardBody>
												<div
													className='row g-3 align-items-center '
													key={i.studentFeeCollectionId}>
													<div className='col-6 d-flex align-items-center'>
														<div className='flex-shrink-0'>
															<div
																className='ratio ratio-1x1'
																style={{ width: 72 }}>
																<div
																	className={classNames(
																		'rounded-2',
																		'd-flex align-items-center justify-content-center',
																		{
																			'bg-l10-info':
																				!darkModeStatus,
																			'bg-lo25-info':
																				darkModeStatus,
																		},
																	)}>
																	<span className='text-info fs-3	 fw-bold'>
																		{getFirstLetter(
																			i.paymentType,
																		)}
																	</span>
																</div>
															</div>
														</div>
														<div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
															<div>
																<div className='fw-bold fs-6 mb-0'>{i.studentName}
																	<span className='text-muted'> ( {i.admissionNo} )</span>
																</div>
																<div className='text-muted'>
																	<span className='text-info fw-bold'>{i.paymentType}</span>
																</div>
															</div>
														</div>
													</div>
													<div className='col fw-bold fs-4 mb-0 ms-5 text-success'>
														{i.receiptNo}
													</div>
													<div className='col-auto'>
														<div
															style={{ width: 100 }}
															className={classNames(
																`bg-l${darkModeStatus ? 'o25' : '10'
																}-${i.color} text-${i.color} fw-bold py-2 rounded-pill me-3 text-center`,
															)}>
															₹ {i.feesCollected}
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</div>
								))}
							</>
						) : (
							<div className='col-lg-12'>
								<Card stretch style={{ height: '85vh' }}>
									<CardBody>
										<div className='row'>
											<div className='col-12' style={{ height: '30vh' }}></div>
											<div className='col-12 d-flex justify-content-center'>
												<Icon icon='DoNotDisturb' size={'10x'} />
											</div>
											<div className='fs-4 fw-bold text-center text-uppercase text-danger'>
												{noDataMsg}
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						)}
					</div>
					<div className='col-lg-4'>
						<Card>
							<CardHeader>
								<CardLabel>
									<CardTitle tag='div' className='h5'>
										Total Earnings
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row align-items-end'>
									<div className='col-lg-6'>
										<div className='h4 mb-3'>Total Earnings</div>
										<span className='display-6 fw-bold text-success'>
											{totalFeeCollected}
										</span>
									</div>
									{transactionData != '' ? (
										<div className='col-lg-6'>
											<Chart
												series={transactionData.series}
												options={transactionData.options}
												type='bar'
												height={165}
											/>
										</div>
									) : null}
								</div>
							</CardBody>
						</Card>
						<Card>
							<CardHeader>
								<CardLabel>
									<CardTitle tag='div' className='h5'>
										Total Receipt
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row align-items-end'>
									<div className='col-lg-6'>
										<div className='h4 mb-3'>Total Receipts</div>
										<span className='display-6 fw-bold text-danger'>
											{totalReceipts}
										</span>
									</div>
									{receiptData != '' ? (
										<div className='col-lg-6'>
											<Chart
												series={receiptData.series}
												options={receiptData.options}
												type='area'
												height={165}
											/>
										</div>
									) : null}
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default StudentRecentFeeTransaction;
