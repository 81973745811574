import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getApiUrlForPayroll, getLicenseKey } from "./application.settings";

const api = service()

export const getStudentCollegeName = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentCollegeName/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentCollegeName - " + error.message)
            console.log('error caught in service : getStudentCollegeName')
        },
    );

export const addStudentCollegeName = (studentCollegePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentCollegeName`, studentCollegePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentCollegeName')
        }
    );

export const updateStudentCollegeName = (studentCollegePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentCollegeName`, studentCollegePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentCollegeName')
        }
    );

export const deleteStudentCollegeName = (studentCollegePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentCollegeName`, studentCollegePostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentCollegeName')
        }
    );


export const getStudentSchoolName = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentSchoolName/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentSchoolName - " + error.message)
            console.log('error caught in service : getStudentSchoolName')
        },
    );

export const addStudentSchoolName = (studentSchoolPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStudentSchoolName`, studentSchoolPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStudentSchoolName')
        }
    );

export const updateStudentSchoolName = (studentSchoolPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStudentSchoolName`, studentSchoolPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStudentSchoolName')
        }
    );

export const deleteStudentSchoolName = (studentSchoolPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStudentSchoolName`, studentSchoolPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStudentSchoolName')
        }
    );

export const getStaffDesignation = (userTypeId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffDesignation/${getLicenseKey}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffDesignation - " + error.message)
            console.log('error caught in service : getStaffDesignation')
        },
    );

export const addStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDesignation')
        }
    );

export const addStaffDesignationForPayroll = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `addStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : addStaffDesignation')
        }
    );

export const updateStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDesignation')
        }
    );

export const updateStaffDesignationForPayroll = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `updateStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : updateStaffDesignation')
        }
    );

export const deleteStaffDesignation = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDesignation')
        }
    );

export const deleteStaffDesignationForPayroll = (staffDesignationPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getApiUrlForPayroll() + `deleteStaffDesignation`, staffDesignationPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : deleteStaffDesignation')
        }
    );



export const getStudentSchoolSubject = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStudentSchoolSubject/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getStudentSchoolSubject - " + error.message)
            console.log('error caught in service : getStudentSchoolSubject')
        },
    );
