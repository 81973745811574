import React, { useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import HumansWebp from '../../../assets/img/scene2.webp';
import Humans from '../../../assets/img/scene2.webp';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Page from '../../../layout/Page/Page';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import {
    getCurrentDateAndTime,
    convertDateToEpoch,
    getGraduationType,
    getSectionDetails,
} from '../../../services/common.service';
import { toasts } from '../../../services/toast.service';
import Card, { CardLabel, CardTitle, CardBody, CardHeader, CardActions } from '../../bootstrap/Card';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import Button from '../../bootstrap/Button';
import Collapse from '../../bootstrap/Collapse';
import FormGroup from '../../bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import Input from '../../bootstrap/forms/Input';
import { getBatchByCourseId, getCourse, getDayOrderType, getDepartmentList, getSemester } from '../../../services/master.service';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Icon from '../../icon/Icon';
import { getAllocatedTimeTableList, getScheduleDetailsByDate } from '../../../services/timeTable.service';

const StaffSchedule = () => {

    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        if (userTypeId != 1) {
            getScheduleDetailsList();
        }
        getDepartment();
        getGraduationList();
        getSectionList();
    }, []);

    const { themeStatus, darkModeStatus } = useDarkMode();

    const [dayOrderTypeId, setDayOrderTypeId] = useState<any>('');
    const [timeTableMasterId, setTimeTableMasterId] = useState<any>('');

    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])

    const [scheduleData, setScheduleData] = useState<any>([]);
    const [dayOrderData, setDayOrderData] = useState<any>([]);
    const [timeTableAllocationData, setTimeTableAllocationData] = useState<any>([]);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);

    const [c, setC] = useState('')
    const [sem, setSem] = useState('')
    const [sec, setSec] = useState('')

    const staffScheduleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            date: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                date?: string;
            } = {};

            if (!values.date) {
                errors.date = 'Required';
            }
            return errors;
        },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
        getDayOrder();
    }

    const selectDayOrder = (e: any) => {
        setDayOrder('')
        setDataSuccess(false)
        setDayOrderTypeId('');
        let dayOrderTypeId = e;
        setDayOrderTypeId(dayOrderTypeId);
    };

    function viewForAdminLogin() {
        getAllocatedTimeTablePeriod(courseId?.value, batchMasterId?.value, semesterDetailsId?.value, sectionDetailsId?.value, dayOrderTypeId?.value);
    }

    function ViewSchedule() {
        setDayOrder('')
        setDayId('')
        setDataSuccess(false);
        setIsLoader(true);
        setScheduleData([]);
        getScheduleDetailsList();
    }

    const [dayOrder, setDayOrder] = useState<any>('')
    const [dayId, setDayId] = useState<any>('')

    function getScheduleDetailsList() {
        let scheduleDate = staffScheduleForm.values.date;
        let dateInMilliSec = convertDateToEpoch(scheduleDate);

        getScheduleDetailsByDate(
            userTypeId,
            userAccountId,
            dateInMilliSec,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scheduleDetailsByDate[0].period;
                    if (data != undefined) {
                        setIsLoader(false);
                        setScheduleData(data);
                        setC(data[0].courseName);
                        setSem(data[0].semesterNumber);
                        setSec(data[0].sectionName);
                        setDayId(response.data.data.scheduleDetailsByDate[0].dayId);
                        setDayOrder(response.data.data.scheduleDetailsByDate[0].dayOrderName);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
                        }
                        setIsOpenListCard(false)
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess(true);
                    // toasts(response.data.message, "Error")
                    setScheduleData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDayOrder() {
        getDayOrderType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dayOrderType;
                    if (data != undefined) {
                        setDayOrderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDayOrderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllocatedTimeTablePeriod(courseId: any, batchMasterId: any, semesterDetailsId: any, sectionDetailsId: any, dayOrderTypeId: any) {
        setIsLoader(true);
        getAllocatedTimeTableList(
            courseId, batchMasterId, semesterDetailsId,
            sectionDetailsId,
            dayOrderTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.allocatedTimeTable[0].dayOrder[0].period;
                    if (data != undefined) {
                        setIsLoader(false);
                        setScheduleData(data);
                        setC(response.data.data.allocatedTimeTable[0].courseName);
                        setSem(response.data.data.allocatedTimeTable[0].semesterNumber);
                        setSec(response.data.data.allocatedTimeTable[0].sectionName);
                        setDayId(response.data.data.allocatedTimeTable[0].dayOrder[0].dayId);
                        setDayOrder(response.data.data.allocatedTimeTable[0].dayOrder[0].day);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].periodCount = data[i].periodName[data[i].periodName.length - 1];
                        }
                        setIsOpenListCard(false)
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess(true);
                    // toasts(response.data.message, "Error")
                    setScheduleData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    return (
        <PageWrapper title='Schedule'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={staffScheduleForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='Schedule' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Schedule
                                </CardTitle>
                            </CardLabel>
                            <CardActions>
                                <CardTitle tag='div' className='h5 text-success'>{dayOrder}</CardTitle>
                            </CardActions>
                        </CardHeader>
                        <CardBody>
                            {userTypeId == 1 ? (
                                <>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <FormGroup id='departmentId' label='Department' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                    list={departmentData.map((data: any) => (
                                                        { value: data.departmentId, label: data.departmentName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>
                                        {departmentId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                    value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                        { value: data.graduationTypeId, label: data.graduationType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {graduationTypeId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='courseId' label='Course' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId}
                                                    list={courseData.map((data: any) => (
                                                        { value: data.courseId, label: data.courseName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {courseId?.value != undefined ? <div className='col-3'>
                                            <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                    list={batchMasterData.map((data: any) => (
                                                        { value: data.batchMasterId, label: data.batchName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {batchMasterId?.value != undefined ? <div className='col-3 mt-3'>
                                            <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                    list={semesterDetailsData.map((data: any) => (
                                                        { value: data.semesterDetailsId, label: data.semesterNumber }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {semesterDetailsId?.value != undefined ? <div className='col-3 mt-3'>
                                            <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                    list={sectionDetailsData.map((data: any) => (
                                                        { value: data.sectionDetailsId, label: data.sectionName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null}

                                        {sectionDetailsId?.value != undefined ? (
                                            <div className='col-3 mt-3'>
                                                <FormGroup
                                                    id='dayOrderTypeId'
                                                    label='Day Order'
                                                    isFloating>
                                                    <SearchableSelect
                                                        isFloating
                                                        ariaLabel='DayOrder'
                                                        onChange={selectDayOrder}
                                                        value={dayOrderTypeId}
                                                        list={dayOrderData.map((data: any) => ({
                                                            value: data.dayOrderTypeId,
                                                            label: data.dayOrderName,
                                                        }))}
                                                    />
                                                </FormGroup>
                                            </div>
                                        ) : null}

                                        {dayOrderTypeId ? (
                                            <div className='col-3 mt-3'>
                                                <Button
                                                    icon='ArrowDownward'
                                                    color='primary'
                                                    onClick={viewForAdminLogin}>
                                                    View
                                                </Button>
                                            </div>
                                        ) : null}

                                    </div>
                                </>
                            ) : (
                                <div className='row g-4'>
                                    <div className='col-md-2'>
                                        <FormGroup id='date' label='Date' isFloating>
                                            <Input
                                                onChange={staffScheduleForm.handleChange}
                                                value={staffScheduleForm.values.date}
                                                onBlur={staffScheduleForm.handleBlur}
                                                isValid={staffScheduleForm.isValid}
                                                isTouched={staffScheduleForm.touched.date}
                                                invalidFeedback={staffScheduleForm.errors.date}
                                                type='date'
                                            />
                                        </FormGroup>
                                    </div>

                                    <div className='col-md-2'>
                                        <Button
                                            className='mt-2'
                                            icon='ArrowDownward'
                                            color='primary'
                                            onClick={ViewSchedule}>
                                            View
                                        </Button>
                                    </div>
                                </div>
                            )}
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />
                {dataSuccess ? (
                    <>
                        {scheduleData != '' && dayId > 0 && dayId != 7 ? (
                            <>
                            {userTypeId == 1 ?
                                <table className="table table-modern table-hover text-center text-nowrap text-md-nowrap mg-b-0 mt-2">
                                    <thead>
                                        <tr style={{ textAlign: 'center' }} >
                                            <td colSpan={13}
                                                style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                                <h5>{dayOrder} - Time table for {c} - {sem} - Section - {(sec.substring(0, 1))}</h5>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>
                                :
                                <table className="table table-modern table-hover text-center text-nowrap text-md-nowrap mg-b-0 mt-2">
                                    <thead>
                                        <tr style={{ textAlign: 'center' }} >
                                            <td colSpan={13}
                                                style={{ fontFamily: 'Bookman Old Style', color: 'rgb(61, 57, 57)' }}>
                                                <h5>{dayOrder} - Schedule for you</h5>
                                            </td>
                                        </tr>
                                    </thead>
                                </table>}
                                <div className='col-12'>
                                    <div className='row'>
                                        {scheduleData.map((period: any) => (
                                            <div className='col-3' key={period.periodDetailsId}>
                                                <Card stretch shadow={'md'} className="border border-light">
                                                    <CardBody>
                                                        <div className='row g-3 align-items-center'>
                                                            <div className='col d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <div
                                                                        className='ratio ratio-1x1'
                                                                        style={{ width: 65 }}>
                                                                        <div
                                                                            className={classNames(
                                                                                'rounded-2',
                                                                                'd-flex align-items-center justify-content-center',
                                                                                {
                                                                                    'bg-l10-info':
                                                                                        !darkModeStatus,
                                                                                    'bg-lo25-info':
                                                                                        darkModeStatus,
                                                                                },
                                                                            )}>
                                                                            <span className='fw-bold'>
                                                                                <span className='ms-1 text-info fs-2'>
                                                                                    {period.periodCount}
                                                                                </span><br />
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex-grow-1 ms-3 d-flex justify-content-between align-items-center'>
                                                                    <div>
                                                                        <small className='fw-bold'>
                                                                            {period.paperName}
                                                                        </small>
                                                                        {userTypeId == 1 ?
                                                                            <div className=' text-info fs-6 mb-0'>
                                                                                {period.staffName}
                                                                            </div> :
                                                                            <>
                                                                            <div className=' text-primary fs-6 mb-0'>
                                                                                {period.courseName}
                                                                            </div>
                                                                            <div className=' text-success fs-6 mb-0'>
                                                                                {sem} - ({sec.substring(0,1)})
                                                                            </div>
                                                                            </>
                                                                        }
                                                                        <div className='text-info'>
                                                                            <small>
                                                                                <span className='text-black-50 fw-bold'>
                                                                                    {period.startTime}-{period.endTime}
                                                                                </span>
                                                                            </small>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        ))}{' '}
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='row'>
                                <div className='col-12 d-flex justify-content-center'>
                                    {scheduleData != '' && (dayId == 0 || dayId == 7) ?
                                        <div className='row'>
                                            <div className="col-12" style={{ height: '10vh' }}></div>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <Icon icon='CalendarToday' size={'10x'} />
                                            </div>
                                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                Holiday
                                            </div>
                                        </div> :
                                        <div className='row'>
                                            <div className='col-12 d-flex justify-content-center'>
                                                <img
                                                    srcSet={HumansWebp}
                                                    src={Humans}
                                                    alt='Humans'
                                                    style={{ height: '40vh' }}
                                                />
                                            </div>
                                            <div className='fs-4 fw-bold text-center text-uppercase text-danger'>
                                                Schedule Not Allocated
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        )}
                    </>
                ) : null}
            </Page>
        </PageWrapper>
    );
};

export default StaffSchedule;
